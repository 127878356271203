import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from "react-owl-carousel";
import cx from './upcomingEvents.module.scss'
import { optionsSlider1 } from 'src/constants/OwlCarouselConstant';
import EventCard from 'src/components/MarketPlace/EventCard/EventCard';

export default function UpcomingEvents() {
    return (
        <div className={`${cx.upcomingEvents}`}>
            <OwlCarousel className="owl-theme sliderRow" {...optionsSlider1}>
                <EventCard />
                <EventCard />
                <EventCard />
                <EventCard />
                <EventCard />
            </OwlCarousel>
        </div>
    )
}
