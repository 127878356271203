import { useNavigate } from 'react-router-dom';
import {
  Card,
  TDropdown,
  TDropdownOptions,
  ThreeDotsDropdown,
  Typography
} from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { TickMarkIcon } from 'src/components/common/common.icons';
import Button from 'src/components/button/Button';
import { colors } from 'src/constants';
import { formatDate } from 'src/utils';

import { TSubmittedApplicationCardProps } from './submittedApplicationCard.types';
import './submittedApplicationCard.scss';

const SubmittedApplicationCard = ({
  name,
  id,
  email,
  updatedAt,
  userId,
  User,
  isRated,
  applicationStageId,
  ProgramApplicationStage
}: TSubmittedApplicationCardProps) => {
  // Hooks
  const navigate = useNavigate();

  // Constants
  const isExternalUser: boolean = User?.isExternal;

  const handleViewApplication = () => {
    navigate(`/view-program-application?id=${id}`);
  };

  const dropdownItems: TDropdownOptions[] = [
    {
      label: 'View applicant’s profile',
      onClick: () => {
        navigate(`/view-profile?id=${User?.id}`);
      }
    }
  ];
  const dropdownOptionsProps: TDropdown = {
    id: `submitted-application-card-${userId}-${id}`,
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  return (
    <Card className="submitted-application-card">
      <div className="submitted-application-card__header">
        <div className="submitted-application-card__header__left">
          <ProfilePicture url={User?.profilePic} fallback={User?.name?.slice(0, 1)} />
          <div className="submitted-application-card__header__left__user-info">
            <Typography as="p" variant="body-2" fontWeight="semiBold">
              {name}
            </Typography>
            {/* <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="submitted-application-card__header__left__user-info__app-no"
            >
              {id}
            </Typography> */}
          </div>
        </div>
        <div className="submitted-application-card__header__stage">
          {isRated && <TickMarkIcon color={colors.success[800]} />}
          <Typography as="span" variant="caption" fontWeight="medium">
            {ProgramApplicationStage?.stageTitle}
          </Typography>
        </div>
      </div>
      <Typography as="p" variant="caption" fontWeight="regular">
        {email}
      </Typography>
      <Typography as="p" variant="caption" fontWeight="regular">
        Submitted date: {formatDate({ dateString: updatedAt })}
      </Typography>
      {isExternalUser && (
        <Typography
          as="p"
          variant="caption"
          fontWeight="semiBold"
          className="submitted-application-card__external-user"
        >
          External Applicant
        </Typography>
      )}
      <div className="submitted-application-card__footer">
        <Button variant="secondary" size="extraSmall" onClick={handleViewApplication}>
          View Details
        </Button>
        {!isExternalUser && <ThreeDotsDropdown dropdownOptions={dropdownOptionsProps} />}
      </div>
    </Card>
  );
};

export default SubmittedApplicationCard;
