import { ReactNode } from 'react';
import classNames from 'classnames';
import { Modal, Typography } from 'src/components/common';
import {
  CalendarIcon,
  ClockIcon,
  LeftAlignIcon,
  TextIcon,
  UploadIcon
} from 'src/components/common/common.icons';

import './addEditProgramDetailsFields.scss';
import { TAddEditProgramDetailsFieldsProps } from './addEditProgramDetailsFields.types';

import { TFieldType } from '../programDetails.types';

const AddEditProgramDetailsFields = ({
  isModalOpen,
  onModalClose,
  onSelection,
  title,
  id,
  currentType,
  customFields = []
}: TAddEditProgramDetailsFieldsProps) => {
  const fields: { fieldType: TFieldType; label: string; icon: ReactNode }[] = customFields.length > 0 ? customFields : [
    { fieldType: 'label', label: 'Add Label', icon: <TextIcon /> },
    { fieldType: 'dateSelection', label: 'Date Selection', icon: <CalendarIcon /> },
    { fieldType: 'textInput', label: 'Text input field', icon: <LeftAlignIcon /> },
    // { fieldType: 'textArea', label: 'Text Area', icon: <LeftAlignIcon /> },
    { fieldType: 'textEditor', label: 'Text Editor', icon: <TextIcon /> },
    { fieldType: 'timeSelection', label: 'Time selection', icon: <ClockIcon /> },
    { fieldType: 'fileUpload', label: 'File upload', icon: <UploadIcon /> }
  ];

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title={title}
      className="add-edit-program-details-fields"
    >
      <Typography as="p" variant="body-2" fontWeight="regular">
        Select the fields you want to add
      </Typography>
      <div className="add-edit-program-details-fields__content__fields">
        {fields.map((field, index) => {
          const { label, icon, fieldType } = field;
          return (
            <div
              key={index}
              className={classNames('add-edit-program-details-fields__content__fields__field', {
                'add-edit-program-details-fields__content__fields__field--is-active':
                  currentType === fieldType
              })}
              onClick={() => {
                onSelection({ fieldType, ...(id && { id }) });
              }}
            >
              {icon}
              <Typography as="span" variant="body-2" fontWeight="medium">
                {label}
              </Typography>
            </div>
          );
        })}
      </div>{' '}
    </Modal>
  );
};

export default AddEditProgramDetailsFields;
