import React, { useState } from 'react';
import cx from './task.module.scss';
import { Button, ProfilePicture, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import { MdAdd, MdRemove } from 'react-icons/md';

interface TOnMilestoneProps {
  handleBack: () => void;
}
export default function Task({ handleBack }: TOnMilestoneProps) {
  return (
    <div className={`${cx.onboard}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>Task</h5>
        <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <Row>
        <Col md={8}>
          <div className='insideCard'>
            <Row>
              <Col md={6}>
                <ul className={cx.milestonelist}>
                  <li>Milestone 1</li>
                  <li>
                    <ul className={cx.nestedList}>
                      <li className={cx.task}>Task 1</li>
                      <li className={cx.task}>Task 2</li>
                      <li className={cx.task}>Task 3</li>
                      <li className={cx.add}><MdAdd /> Add Task</li>
                    </ul>
                  </li>
                  <li>Milestone 2</li>
                  <li>
                    <ul className={cx.nestedList}>
                      <li className={cx.task}>Task 1</li>
                      <li className={cx.task}>Task 2</li>
                      <li className={cx.task}>Task 3</li>
                      <li className={cx.add}><MdAdd /> Add Task</li>
                    </ul>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <div className={cx.task}>
                <Row>
                  <Col md={4}>
                    <div className={`${cx.inputFields}`}>
                      <InputField
                        label="Task"
                        id="task"
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="Task"
                        type="text"
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${cx.inputFields}`}>
                      <InputField
                        label="Start Date"
                        id="startDate"
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="Start Date"
                        type="date"
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${cx.inputFields}`}>
                      <InputField
                        label="End Date"
                        id="endDate"
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="End Date"
                        type="date"
                      />
                    </div>
                  </Col>
                  {/* <Col md={3}>
                    <div className={cx.addTask}>
                      <span className={cx.addRemoveIcon}><MdAdd /></span>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}
