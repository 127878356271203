import React, { ReactNode, useEffect, useRef, useState } from 'react';
import cx from './createEvent.module.scss';

import { Button, Card, Loader, ProfilePicture, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import OptionsField from '../../applyToProgram/previewField/OptionsField';
import { FieldArray, Form, Formik, FormikErrors } from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorMessage from '../../../../common/formFields/errorMessage/ErrorMessage';
import createEventValidationSchema from './createEventValidationSchema';

import {
  TGetCompanyPresignedUrlActionRequest,
  TMember,
  TUploadCompanyProfilePictureResponse
} from 'src/services/apiEndpoint.types.ts';
import { useToggleOpenModal, useUrlParamValue } from 'src/hooks';
import { httpRequest } from 'src/services/commonApis';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { openPopup } from 'src/store/common/common.slice';
import {
  CalendarIcon,
  ClockIcon,
  LeftAlignIcon,
  PlusIcon,
  TextIcon,
  UploadIcon
} from 'src/components/common/common.icons';
import handleExtension from 'src/utils/handleDocumentNameExtension/handleDocumentNameExtension';
import { APIResponse } from 'src/services/base.api.service';
import { CompanyAPIService } from 'src/services/company.service';
import { TTeam } from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
import { getUuid } from 'src/utils';
import AddEditProgramDetailsFields from '../../createProgram/programDetails/addEditProgramDetailsFields/AddEditProgramDetailsFields';
import DynamicField from '../../createProgram/programDetails/dynamicField/DynamicField';
import { TCustomField, TFieldType } from '../../createProgram';
import { defaultErrors } from '../../createProgram/programDetails/programDetails.data';
import TextAreaEditor from 'src/components/common/formFields/textAreaEditor/TextAreaEditor';
import useUpdateErrors from '../../createProgram/programDetails/hooks/useUpdateErrors';
import createProgramDetailsSchema from '../../createProgram/programDetails/createProgramDetailsSchema';
import validateForm from '../../createProgram/programDetails/validateForm';
import { createEvents } from 'src/store/cohort/cohort.slice';
import { disablePastDate } from 'src/utils/DisablePastDate/disablePasteDate';

interface TOnboardProps {
  handleBack: () => void;
}
interface TInitialEvent {
  cohortId?: string;
  title: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  eventDescription: string;
  applicationForm?: any[];
  banner: {
    url: string;
    height: string | number;
    width: string | number;
  };
}
const initialValue = {
  title: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  eventDescription: '',
  banner: {
    url: '',
    height: '',
    width: ''
  }
};

export default function CreateEvent({ handleBack }: TOnboardProps) {
  const { paramValue } = useUrlParamValue();
  const toggleOpenModal = useToggleOpenModal();
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const companyId: string = paramValue({ paramName: 'companyId' }) as string;
  const resourceId: string = paramValue({ paramName: 'resourceId' }) as string;
  const [eventInitialValue, setEventInitialValue] = useState<TInitialEvent>(initialValue);
  const dispatch = useAppDispatch();
  const updateErrorsHook = useUpdateErrors();
  const { globalLoading }: { globalLoading: string } = useAppSelector((store: any) => store.cohort);
  const modalId: string = useAppSelector((store: any) => store.common.modalId);
  const addSurveyReportsId = 'addSurveyDetailsFields';
  const editSurveyDetailsFieldsId = 'editSurveyDetailsFields';
  const confirmSurveyFieldModalId = 'confirmSurveyFieldModalId';
  const isEditSurveyDetailsFieldsOpen = modalId === editSurveyDetailsFieldsId;
  const isAddSurveyDetailsFieldsOpen = modalId === addSurveyReportsId;
  const [customFields, setCustomFields] = useState<TCustomField[]>([]);
  const [showErrorsOnChange, setShowErrorsOnChange] = useState<boolean>(false);
  const startDateRef = useRef<HTMLInputElement[]>([]);
  const endDateRef = useRef<HTMLInputElement[]>([]);
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const [bannerLoading, setBannerLoading] = useState<boolean>(false);
  const dateTimeSelectionRef = useRef<HTMLInputElement[]>([]);
  const [eventsFieldsError, setEventFieldsError] = useState<Record<string, string>>(defaultErrors);
  const [editFieldModalData, setEditFieldModalData] = useState<{
    id: string;
    fieldType: TFieldType;
  }>({ id: '', fieldType: 'label' });
  const handleSubmit = async (values: TInitialEvent) => {
    setShowErrorsOnChange(true);

    const {
      title,
      startDate,
      endDate,
      startTime,
      endTime,
      eventDescription,
      banner,
      ...programDetailsFields
    } = values;
    const applicationForm: any[] = [];
    try {
      Object.keys(programDetailsFields).forEach((key) => {
        const field = programDetailsFields[key as keyof typeof programDetailsFields];
        applicationForm.push(field);
      });
    } catch {}

    const programDetailsValues: TInitialEvent = {
      title,
      startDate,
      endDate,
      startTime,
      endTime,
      eventDescription,
      banner,
      applicationForm
    };

    const customFieldValues: Record<string, string> = {};

    Object.keys(programDetailsFields).forEach((fieldKey) => {
      const field: any = programDetailsFields[fieldKey as keyof typeof programDetailsFields];
      const id: string = field?.id;

      customFieldValues[id] = field?.values || '';

      if (field?.type !== 'label') {
        customFieldValues[`${id}-label`] = field?.label || '';
      }
    });

    const schema = createProgramDetailsSchema({ fields: customFields });

    const errors = await validateForm({
      values: { title, startDate, startTime, endDate, endTime, ...customFieldValues },
      schema,
      updateErrors
    });

    const hasZeroErrors: boolean =
      errors?.length < 1 ||
      (errors.length === 1 && errors?.[0] === 'Please enter the number of stages');
    if (hasZeroErrors) {
      handleCreateEvent(programDetailsValues);
    }
  };
  function handleCreateEvent(values) {
    const {
      title,
      startDate,
      startTime,
      endDate,
      endTime,
      banner,
      eventDescription,
      applicationForm: applicationFormField
    } = values;

    const applicationForm = applicationFormField.map((field) => {
      const { id, label, type, values, files } = field;
      return { id, label, type, values, files };
    });

    let createEventPayload: any = {
      cohortId,
      incubatorId: companyId,
      title,
      startDate,
      endDate,
      startTime,
      eventDescription,
      endTime,
      applicationForm
    };

    if (banner?.url) {
      createEventPayload = {
        ...createEventPayload,
        banner
      };
    }
    dispatch(createEvents(createEventPayload));
  }
  const handleToggleOpenAddFieldsModal = () => {
    toggleOpenModal({ modalId: addSurveyReportsId });
  };
  const handleAddField = ({ fieldType }: { fieldType: TFieldType }) => {
    handleToggleOpenAddFieldsModal();

    const newId = getUuid();

    const newField: TCustomField = {
      id: newId,
      type: fieldType,
      label: '',
      placeholder: '',
      values: false,
      files: []
    };
    setCustomFields((prev) => prev.concat(newField));
  };
  const changeFieldType = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setCustomFields((prev) => {
      const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
      const selectedField = currentFields.find((field) => field.id === id);

      if (selectedField) {
        selectedField.type = fieldType;
      }

      return currentFields;
    });
    setEditFieldModalData({ id: '', fieldType: 'label' });
    handleToggleOpenEditFieldsModal();
  };
  const handleToggleOpenEditFieldsModal = () => {
    toggleOpenModal({ modalId: editSurveyDetailsFieldsId });
  };
  const openChangeFieldModal = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setEditFieldModalData({ fieldType, id: id || '' });
    handleToggleOpenEditFieldsModal();
  };
  const handleToggleOpenDeleteFieldModal = () => {
    toggleOpenModal({ modalId: confirmSurveyFieldModalId });
  };
  const updateErrors = ({ errors }: { errors: Record<string, string> }) => {
    setEventFieldsError(errors);
  };

  const updateErrorsOnChange = ({ values }: { values: TInitialEvent }) => {
    updateErrorsHook({ values, customFields, updateErrorsState: updateErrors });
  };

  const handleDeleteField = ({ id }: { id: string }) => {
    if (id) {
      setCustomFields((prev) => {
        const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
        const selectedFieldIndex: number = currentFields.findIndex((field) => field.id === id);

        if (selectedFieldIndex > -1) {
          currentFields.splice(selectedFieldIndex, 1);
        }

        return currentFields;
      });

      handleToggleOpenDeleteFieldModal();
    }
  };
  const openImageFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  const handleUpload = (e, setFieldValue) => {
    const file = e.target.files[0];
    setBannerLoading(true);
    if (file) {
      const fileName = handleExtension(file?.name);
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        try {
          const data = reader.result;
          const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
            id: cohortId,
            data,
            showPopup: false
          };
          const response = await httpRequest(
            `cohort/uploads?cohortId=${uploadProfilePicturePayload.id}&resourceName=${fileName}&docCategory=banner`,
            'GET',
            null,
            'json',
            'cohort'
          );
          if (response?.data?.signedUrl) {
            const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
              await new CompanyAPIService().uploadCompanyProfilePicture({
                presignedUrl: response?.data?.signedUrl,
                data,
                includeAuthorizationHeaders: false
              });
            if (res.status === 200) {
              const data = await httpRequest(
                `cohort/uploads?cohortId=${uploadProfilePicturePayload.id}&resourceName=${fileName}&docCategory=banner`,
                'GET',
                null,
                'json',
                'cohort'
              );
              if (data?.data?.key) {
                console.log(data, 'dataaaaaaaaaaaa');

                setBannerLoading(false);
                setFieldValue('banner', { url: data?.data?.key });
              } else {
                dispatch(
                  openPopup({
                    popupMessage: 'something went wrong',
                    popupType: 'error'
                  })
                );
              }
              setBannerLoading(false);
            } else {
              setBannerLoading(false);
              dispatch(
                openPopup({
                  popupMessage: res?.message || 'something went wrong',
                  popupType: 'error'
                })
              );
            }
          } else {
            setBannerLoading(false);
            dispatch(
              openPopup({
                popupMessage: 'something went wrong',
                popupType: 'error'
              })
            );
          }
        } catch (error) {
          setBannerLoading(false);
          dispatch(
            openPopup({
              popupMessage: error?.message || 'something went wrong',
              popupType: 'error'
            })
          );
        }
      };
    }
  };
  const fieldsArr: { fieldType: TFieldType; label: string; icon: ReactNode }[] = [
    { fieldType: 'label', label: 'Add Label', icon: <TextIcon /> },
    { fieldType: 'dateSelection', label: 'Date Selection', icon: <CalendarIcon /> },
    { fieldType: 'textInput', label: 'Text input field', icon: <LeftAlignIcon /> },
    // { fieldType: 'textArea', label: 'Text Area', icon: <LeftAlignIcon /> },
    { fieldType: 'textEditor', label: 'Text Editor', icon: <TextIcon /> },
    { fieldType: 'timeSelection', label: 'Time selection', icon: <ClockIcon /> }
  ];
  return (
    <>
      {isAddSurveyDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isAddSurveyDetailsFieldsOpen}
          onModalClose={handleToggleOpenAddFieldsModal}
          title="Add Fields"
          onSelection={handleAddField}
          customFields={fieldsArr}
        />
      )}
      {isEditSurveyDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isEditSurveyDetailsFieldsOpen}
          onModalClose={handleToggleOpenEditFieldsModal}
          title="Edit Fields"
          currentType={editFieldModalData.fieldType}
          id={editFieldModalData.id}
          onSelection={changeFieldType}
          customFields={fieldsArr}
        />
      )}
      <div className={`${cx.createEvent}`}>
        <div className={`${cx.titleHeader}`}>
          <h5 className={`${cx.title}`}>Create Event</h5>
          <Button
            size="small"
            variant="primary"
            className="program-actions__actions__next"
            style={{ width: 'auto' }}
            type="button"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <div className="insideCard">
          <Formik
            initialValues={eventInitialValue}
            onSubmit={handleSubmit}
            validationSchema={createEventValidationSchema}
            enableReinitialize
          >
            {({ setFieldValue, values, errors, touched }) => {
              console.log(values, 'valuesDataaaaaaaa');

              useEffect(() => {
                if (showErrorsOnChange) {
                  updateErrorsOnChange({ values });
                }
              }, [values]);
              return (
                <Form className="edit-cohort__form" noValidate>
                  <Row>
                    <div className={cx.container}>
                      <Col md={8}>
                        <Card className="program-details__form__row__field mb-3 mt-2">
                          <div className={`${cx.inputFields}`}>
                            <InputField
                              required
                              label="Name of Event"
                              id="title"
                              value={values?.title}
                              variant={componentSizeVariantsEnum.SMALL}
                              isTouched={touched.title}
                              placeholder="Name of Event"
                              type="text"
                            />
                          </div>
                        </Card>
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col md={6}>
                            <Card className="program-details__form__row__field">
                              <Row>
                                <Col md={6}>
                                  <InputField
                                    label="Start Date"
                                    id="startDate"
                                    variant={componentSizeVariantsEnum.SMALL}
                                    placeholder="Select date"
                                    min={disablePastDate()}
                                    type="date"
                                    inputRef={(el: HTMLInputElement) => {
                                      startDateRef.current[0] = el;
                                    }}
                                    endIconClickHandler={() => {
                                      if (startDateRef.current) {
                                        startDateRef.current[0].showPicker();
                                      }
                                    }}
                                    onChange={(e) => {
                                      setFieldValue('startDate', e.target.value);
                                    }}
                                    isTouched={touched.startDate}
                                    // endIcon={<CalendarIcon />}
                                    required
                                  />
                                </Col>
                                <Col md={6}>
                                  <InputField
                                    label={'Start Time'}
                                    id="startTime"
                                    elementtype="program-creation"
                                    variant={componentSizeVariantsEnum.SMALL}
                                    placeholder={'start time'}
                                    type={'time'}
                                    inputRef={(el: HTMLInputElement) => {
                                      dateTimeSelectionRef.current[0] = el;
                                    }}
                                    endIconClickHandler={() => {
                                      if (dateTimeSelectionRef.current) {
                                        dateTimeSelectionRef.current[0].showPicker();
                                      }
                                    }}
                                    isTouched={touched.startTime}
                                    required
                                  />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col md={6}>
                            <Card className="program-details__form__row__field">
                              <Row>
                                <Col md={6}>
                                  <InputField
                                    label="End Date"
                                    id="endDate"
                                    variant={componentSizeVariantsEnum.SMALL}
                                    placeholder="Select date"
                                    min={values.startDate || disablePastDate()}
                                    type="date"
                                    inputRef={(el: HTMLInputElement) => {
                                      endDateRef.current[0] = el;
                                    }}
                                    endIconClickHandler={() => {
                                      if (endDateRef.current) {
                                        endDateRef.current[0].showPicker();
                                      }
                                    }}
                                    onChange={(e) => {
                                      setFieldValue('endDate', e.target.value);
                                    }}
                                    isTouched={touched.endDate}
                                    // endIcon={<CalendarIcon />}
                                    required
                                  />
                                </Col>
                                <Col md={6}>
                                  <InputField
                                    label={'End Time'}
                                    id={'endTime'}
                                    elementtype="program-creation"
                                    variant={componentSizeVariantsEnum.SMALL}
                                    placeholder={'End time'}
                                    type={'time'}
                                    inputRef={(el: HTMLInputElement) => {
                                      dateTimeSelectionRef.current[0] = el;
                                    }}
                                    endIconClickHandler={() => {
                                      if (dateTimeSelectionRef.current) {
                                        dateTimeSelectionRef.current[0].showPicker();
                                      }
                                    }}
                                    isTouched={touched.endTime}
                                    required
                                  />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col md={12}>
                            <div className="program-details__form__row mt-3">
                              <Card className="program-details__form__row__field">
                                {!bannerLoading && (
                                  <>
                                    <InputField
                                      label="Banner"
                                      id="banner-label"
                                      hidden
                                      variant={componentSizeVariantsEnum.SMALL}
                                      placeholder="Banner"
                                      type="text"
                                    />

                                    <div className="dynamic-field__upload-file">
                                      <span
                                        className="dynamic-field__upload-file__drag-drop"
                                        onClick={openImageFileManager}
                                      >
                                        <UploadIcon />
                                        <input
                                          type="file"
                                          hidden
                                          value={''}
                                          ref={uploadImageRef}
                                          accept=".jpg,.jpeg,.png,"
                                          className="select-post-image"
                                          onChange={(e) => {
                                            handleUpload(e, setFieldValue);
                                          }}
                                        />
                                      </span>

                                      <Typography
                                        as="span"
                                        variant="body-2"
                                        fontWeight="medium"
                                        className="dynamic-field__upload-file__browse"
                                        onClick={openImageFileManager}
                                      >
                                        Browse on your device
                                      </Typography>
                                    </div>
                                  </>
                                )}
                                {bannerLoading && <Loader />}

                                {values?.banner?.url && !bannerLoading && (
                                  <Row>
                                    <Col md={12}>
                                      <div className="position-relative">
                                        <button
                                          style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            background: '#fff',
                                            height: '23px',
                                            fontSize: '14px',
                                            width: '20px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '50%',
                                            border: 'none',
                                            color: 'red'
                                          }}
                                          onClick={() => setFieldValue('banner', { url: '' })}
                                        >
                                          X
                                        </button>
                                        <img
                                          src={values?.banner?.url}
                                          alt="banner"
                                          style={{
                                            width: '600px',
                                            height: '150px',
                                            objectFit: 'cover',
                                            backgroundPosition: 'center',
                                            marginTop: '10px',
                                            borderRadius: '10px'
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </Card>
                            </div>
                          </Col>
                          <Col md={12}>
                            <Card className="program-details__form__row__field mt-3 mb-2">
                              {/* <h5 className='typography__caption typography__semiBold mb-2'>Event Description</h5> */}
                              <TextAreaEditor
                                style={{ minHeight: '230px' }}
                                id="eventDescription"
                                value={values.eventDescription}
                                onChange={(e) => setFieldValue('eventDescription', e.target.value)}
                                label="Event Description"
                                variant={componentSizeVariantsEnum.SMALL}
                                placeholder={'Event Description'}
                                type={'text'}
                              />
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                      {customFields?.length > 0 && (
                        <Col md={8} className="mt-2 d-flex flex-column gap-3">
                          <div className={`${cx.dynamicFieldsBox}`}>
                            {customFields.map((field: TCustomField, index) => {
                              return (
                                <DynamicField
                                  {...field}
                                  openChangeFieldModal={openChangeFieldModal}
                                  deleteField={handleDeleteField}
                                  setCustomFields={setCustomFields}
                                  key={index}
                                  customFields={customFields}
                                  errors={eventsFieldsError}
                                />
                              );
                            })}
                          </div>
                        </Col>
                      )}
                      {/* Add field */}
                      <Col md={8} className="mt-3">
                        <Button
                          size="small"
                          variant="primary"
                          startIcon={<PlusIcon />}
                          className="program-details__form__add-field"
                          onClick={handleToggleOpenAddFieldsModal}
                        >
                          Add Field
                        </Button>
                      </Col>
                    </div>
                  </Row>
                  <Row className="mt-3">
                    <Col md={2}>
                      <Button variant="primary" type="submit" size="small">
                        {globalLoading === 'event' ? <Loader /> : resourceId ? 'Update' : 'Submit'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}
