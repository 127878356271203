import React, { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { useGetFormikField } from 'src/hooks';

import './inputField.scss';

import InputElement from '../inputElement/InputElement';
import Label from '../label/Label';
import ErrorMessage from '../errorMessage/ErrorMessage';

type TInputFieldProps = {
  errorMessage?: string;
  label?: ReactNode;
  id: string;
  variant?: componentSizeVariantsEnum;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  startIconClickHandler?: () => void;
  endIconClickHandler?: () => void;
  maskField?: boolean;
  elementtype?: string;
  inputFieldClassName?: string;
  isTouched?: boolean;
  inputRef?: (el: HTMLInputElement) => void;
  required?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * Props for the InputField component.
 * @typedef {Object} TInputFieldProps
 * @param {string} [errorMessage] - The error message to be displayed.
 * @param {string} label - The label for the input field.
 * @param {string} [id] - The ID attribute for the input field.
 * @param {componentSizeVariantsEnum} [variant] - The variant of the input field.
 * @param {React.ReactNode} [startIcon] - The icon element to be displayed at the start.
 * @param {React.ReactNode;} [endIcon] - The icon element to be displayed at the end.
 * @param {string} [inputFieldClassName] - Additional class name(s) for the input field.
 * @param {() = void} [startIconClickHandler] - Click handler for the left icon.
 * @param {() = void} [endIconClickHandler] - Click handler for the right icon.
 * @param {boolean} [markField] - The icon element to be displayed at the end.
 * @param {boolean} [isTouched] - Whether the field is touched.
 * @param {InputHTMLAttributes<HTMLInputElement>} [HTMLInputElement] - HTML attributes of the input element.
 */
const InputField = ({
  errorMessage,
  label,
  id,
  variant = componentSizeVariantsEnum.MEDIUM,
  startIcon,
  endIcon,
  inputFieldClassName,
  className,
  isTouched,
  required = false,
  value,
  elementtype = 'other',
  onChange,
  ...rest
}: TInputFieldProps) => {
  // Creates unique id, if not available
  const inputId: string = id || nanoid();

  const { getFormikField } = useGetFormikField();

  const [field, fieldMetaData, helpers] = getFormikField({ id: id || '' });

  // The below code is written such that InputField works even when it is not in Formik
  // Component can still be used independently outside Formik
  const error: string = errorMessage || fieldMetaData?.error || '';
  const fieldTouched: boolean = isTouched || fieldMetaData?.touched;
  const fieldValue = elementtype === 'program-creation' ? value : field?.value || value;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      // When InputField is not wrapped in Formik
      onChange(e);
    } else if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers.setValue(e.target.value);
    }
  };

  return (
    <div
      className={classNames(
        className,
        'input-field-component',
        `input-field-component--${variant}`
      )}
    >
      {label && (
        <Label htmlFor={inputId} variant={variant} required={required}>
          {label}
        </Label>
      )}
      <InputElement
        {...rest}
        variant={variant}
        startIcon={startIcon}
        endIcon={endIcon}
        className={inputFieldClassName}
        id={inputId}
        onChange={handleChange}
        value={fieldValue}
      />
      {error && fieldTouched && <ErrorMessage message={error} />}
    </div>
  );
};

export default InputField;
