/* eslint-disable multiline-ternary */

import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import Typography from 'src/components/common/typography/Typography';

import './profilePicture.scss';
import { TProfilePictureProps } from './profilePicture.type';

/*
 ** Component to representative text incase the image is not available or failed loading
 */
const ProfilePicture: FC<TProfilePictureProps> = ({
  url = '',
  fallback,
  className,
  userType,
  ...rest
}: TProfilePictureProps) => {
  const [isImageValid, setIsImageValid] = useState<boolean>(true);

  useEffect(() => {
    if (url) {
      setIsImageValid(true);
    }
  }, [url]);

  return (
    <div
      className={classNames(
        'profile-picture-component',
        `profile-picture-component--${userType}`,
        className
      )}
      {...rest}
    >
      {isImageValid ? (
        <img key={url} src={url || ''} onError={() => setIsImageValid(false)} />
      ) : (
        <Typography
          as="h3"
          variant="body-1"
          fontWeight="semiBold"
          className="profile-picture-component__error"
        >
          {fallback}
        </Typography>
      )}
    </div>
  );
};

ProfilePicture.defaultProps = {
  url: ''
};

export default ProfilePicture;
