import { TMilestone } from './milestonetypes';

export const initialValues: { milestones: TMilestone[] } = {
    milestones: [
        {
            cohortId: '',
            userId: '',
            cohortMilestoneTitle: '',
            startDate: '',
            endDate: '',
            tasks: [{
                taskTitle: '',
                startDate: '',
                endDate: ''
            }]
        }
    ]
}
