import * as Yup from 'yup';

import { TCustomField } from './programDetails.types';

const createProgramDetailsSchema = ({ fields }: { fields: TCustomField[] }) => {
  const shape: { [key: string]: any } = {
    title: Yup.string().required('Please enter the program title'),
    // startDate: Yup.string().required('Please select the start data')
    endDate: Yup.string()
      .required('Please select the end data'),
      // .test('is-greater-than-start', 'End date cannot be prior to start date', function (value) {
        // const { startDate } = this.parent;
        // if (!startDate || !value) return true; // Skip validation if start or end is empty

        // const programStartDate: number = new Date(startDate).getTime();
        // const programEndDate: number = new Date(value).getTime();
        // return programEndDate >= programStartDate; // Return true if end date is greater than or equal to start date
      // }),
    stages: Yup.number()
      .typeError('Please enter the number of stages')
      .required('Please enter the number of stages')
      .min(1, 'The number of stages should be at least 1')
      .max(5, 'The number of stages should be at maximum of 5')
  };

  try {
    fields.forEach((field) => {
      const id: string = field?.id;

      const preSelectedField =
        id === 'aboutProgram' || id === 'whoShouldAttend' || id === 'enterApplicationProcess';

      if (preSelectedField) {
        const validator = Yup.string().required('Please enter details in this field');
        shape[id] = validator;
        return undefined;
      } else if (field?.type === 'label') {
        const validator = Yup.string().required('Please enter details in this field');
        shape[id] = validator;
      } else {
        const validator = Yup.string().required('Please enter details in this field');
        shape[id] = validator;
        shape[`${id}-label`] = validator;
      }
    });

    return Yup.object().shape(shape);
  } catch {
    return Yup.object().shape(shape);
  }
};

export default createProgramDetailsSchema;
