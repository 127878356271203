import React, { useEffect, useState } from 'react';
import cx from './onBoard.module.scss';

import { AiFillCamera } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import { upArrow } from '../../../../../assets/images/index';

import { Button, Loader, ProfilePicture, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import OptionsField from '../../applyToProgram/previewField/OptionsField';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Form, Formik, FormikErrors } from 'formik';
import ErrorMessage from '../../../../common/formFields/errorMessage/ErrorMessage';
import onBoardCohortValidationSchema from './onBoardCohortValidationSchema';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import {
  cohortTeamSearch,
  createCohortTeam,
  getCohortTeamSuccess,
  resetTeamSearch,
  searchedTeamList,
  updateCohortTeam,
  viewcohortTeam
} from 'src/store/cohort/cohort.slice';
import {
  TMember,
  TSearchUsersRequest,
  TUploadCompanyProfilePictureResponse
} from 'src/services/apiEndpoint.types.ts';
import { useDispatch } from 'react-redux';
import { useUrlParamValue } from 'src/hooks';
import { TSearchCohort } from 'src/store/cohort/cohort.type';
import { httpRequest } from 'src/services/commonApis';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { openPopup } from 'src/store/common/common.slice';
import {
  TCohortTeam,
  TCreateTeam
} from 'src/services/apiEndpoint.types.ts/cohort/cohortOnBoard.types';
import { CrossIcon, PlusIconCircle } from 'src/components/common/common.icons';
import handleExtension from 'src/utils/handleDocumentNameExtension/handleDocumentNameExtension';
import { APIResponse } from 'src/services/base.api.service';
import { CompanyAPIService } from 'src/services/company.service';
import { TTeam } from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
import { textEllipsis } from 'src/utils';

interface TOnboardProps {
  handleBack: () => void;
}
const initialValues: TCreateTeam = {
  cohortId: '',
  onboardingTo: '',
  cohortTeamsTitle: '',
  teamMemberIds: [],
  profilePic: '',
  about: ''
};
export default function OnBoard({ handleBack }: TOnboardProps) {
  const { paramValue } = useUrlParamValue();
  const companyId: string = paramValue({ paramName: 'companyId' }) as string;
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const resourceId: string = paramValue({ paramName: 'resourceId' }) as string;
  const [teamData, setTeamData] = useState<TCreateTeam>(initialValues);
  const {
    searchedTeam,
    cohortLoading,
    viewCohortTeam
  }: { searchedTeam: TMember[]; cohortLoading: boolean; viewCohortTeam: TTeam } = useAppSelector(
    (store: any) => store.cohort
  );
  const { loading }: { loading: boolean } = useAppSelector((store: any) => store.common);
  const [logoLoading, setLogoLoading] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<TMember[]>([]);
  const dispatch = useAppDispatch();
  const [searchKey, setSearchKey] = useState<string>('');

  const handleSubmit = async (values: TCreateTeam) => {
    const path = `/view-cohort?id=${cohortId}&companyId=${companyId}&tab=Dashboard`;
    let data = values;
    if (values.profilePic === '') {
      const { profilePic, ...rest } = values;
      data = rest;
    }
    if (resourceId) {
      const payload = { ...data, cohortId, teamId: resourceId, path, incubatorId: companyId };
      dispatch(updateCohortTeam(payload));
    } else {
      const payload = { ...data, cohortId, path, incubatorId: companyId };
      dispatch(createCohortTeam(payload));
    }
  };
  const fetchUsers = async ({ resetUsers = false }: { resetUsers: boolean }) => {
    dispatch(resetTeamSearch());
    if (searchKey) {
      const data: TSearchCohort = {
        key: searchKey,
        page: 1,
        pageSize: 50,
        companyId
      };
      dispatch(cohortTeamSearch(data));
    } else {
      dispatch(searchedTeamList([]));
    }
  };
  async function handleLogo(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => Promise<void | FormikErrors<TCreateTeam>>
  ) {
    if (e.target.files) {
      const files = e.target.files;
      if (files?.length > 0) {
        for (const file of files) {
          const fileName = handleExtension(file?.name);
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = async () => {
            try {
              setLogoLoading(true);
              const data = reader.result;
              const uploadProfilePicturePayload: any = {
                id: cohortId,
                data,
                showPopup: false
              };
              const response = await httpRequest(
                `cohort/uploads?cohortId=${uploadProfilePicturePayload.id}&resourceName=${fileName}&docCategory=profilePic`,
                'GET',
                null,
                'json',
                'cohort'
              );
              if (response?.data?.signedUrl) {
                const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
                  await new CompanyAPIService().uploadCompanyProfilePicture({
                    presignedUrl: response?.data?.signedUrl,
                    data,
                    includeAuthorizationHeaders: false
                  });
                if (res.status === 200) {
                  const data = await httpRequest(
                    `cohort/uploads?cohortId=${uploadProfilePicturePayload.id}&resourceName=${fileName}&docCategory=profilePic`,
                    'GET',
                    null,
                    'json',
                    'cohort'
                  );
                  if (response?.data?.signedUrl) {
                    setFieldValue('profilePic', data?.data?.key);
                  }
                }
                console.log(res, 'Upload Company Profile Picture Response');
              } else {
                console.error('No signed URL returned in response');
              }
            } catch (error) {
              console.error('Error in file upload process', error);
            } finally {
              setLogoLoading(false);
            }
          };
        }
      }
    }
    setLogoLoading(false);
  }
  useEffect(() => {
    if (resourceId && cohortId) {
      const payload = { cohortId, teamId: resourceId, incubatorId: companyId };
      dispatch(viewcohortTeam(payload));
    }
    return () => {
      dispatch(getCohortTeamSuccess(null));
    };
  }, [resourceId, cohortId]);

  useEffect(() => {
    if (viewCohortTeam && resourceId) {
      const { teamMemberMappings, ...restTeam } = viewCohortTeam;
      setTeamData({
        ...restTeam,
        profilePic: viewCohortTeam.profilePic || '',
        about: viewCohortTeam?.about || '',
        teamMemberIds: viewCohortTeam?.teamMemberMappings?.map((team) => ({
          id: team?.teamMemberId,
          role: team.teamMember?.role
        }))
      });
      const existTeam = viewCohortTeam?.teamMemberMappings?.map(
        (team: { teamMember: any; teamMemberId: string }) => ({
          ...team?.teamMember,
          id: team?.teamMemberId,
          role: team.teamMember?.role
        })
      );
      setSelectedTeam(existTeam);
    }
  }, [viewCohortTeam]);
  useDebounce({
    func: () => fetchUsers({ resetUsers: true }),
    delay: 500,
    dependency: searchKey
  });
  return (
    <div className={`${cx.onboard}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>On-Board</h5>
        <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <div className="insideCard">
        <Formik
          initialValues={teamData}
          onSubmit={handleSubmit}
          validationSchema={onBoardCohortValidationSchema}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, touched }) => {
            console.log(errors, touched, 'data');

            return (
              <Form className="edit-cohort__form" noValidate>
                <Row>
                  <div className={cx.container}>
                    <Col md={3}>
                      <div className={`${cx.inputFields}`}>
                        <OptionsField
                          options={['Team', 'Start up']}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('onboardingTo', e.target.value)
                          }
                          value={values?.onboardingTo}
                          type="singleChoice"
                          label=""
                          id="onboardingTo"
                        />
                      </div>
                    </Col>
                    <Col md={9}></Col>
                    <Col md={4}>
                      <div className={`${cx.logoBox}`}>
                        <div className={cx.box}>
                          <button
                            className={`${cx.upload_img_remove}`}
                            onClick={() => setFieldValue('profilePic', '')}
                          >
                            {values.profilePic ? <RxCross2 /> : ''}
                          </button>
                          {logoLoading ? (
                            <Loader />
                          ) : values.profilePic ? (
                            <img
                              style={{
                                height: '130px',
                                width: '138px',
                                borderRadius: '6px',
                                objectFit: 'cover',
                                boxShadow:
                                  'rgba(50, 50, 93, 0.25) 0px 2px 4px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                border: '1px solid gainsboro'
                              }}
                              src={values.profilePic || ''}
                              alt="logo"
                            />
                          ) : (
                            <AiFillCamera className={cx.boxImage} />
                          )}

                          {
                            <label className={cx.bottomLogo} htmlFor="team-image-upload">
                              <input
                                type="file"
                                id="team-image-upload"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleLogo(e, setFieldValue)
                                }
                              />
                              <img src={upArrow} alt="logo" style={{ cursor: 'pointer' }} />
                            </label>
                          }
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${cx.inputFields}`}>
                        <InputField
                          required
                          label="Name"
                          id="cohortTeamsTitle"
                          value={values?.cohortTeamsTitle}
                          variant={componentSizeVariantsEnum.SMALL}
                          placeholder="Name"
                          type="text"
                        />
                      </div>
                    </Col>
                    <Col md={6}></Col>
                    <Col md={6}>
                      <div className={`${cx.inputFields}`}>
                        <InputField
                          required
                          label="Search and Add  Team Members"
                          id="team-search"
                          variant={componentSizeVariantsEnum.SMALL}
                          placeholder="Search and Add Team Members"
                          type="search"
                          value={searchKey}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchKey(e.target.value)
                          }
                        />
                      </div>
                      {errors?.teamMemberIds && touched?.teamMemberIds && (
                        <ErrorMessage message={'please add atleast one team member'} />
                      )}
                    </Col>
                    <Col md={6}></Col>
                    {searchKey && (
                      <div className="insideCard">
                        <Col md={12}>
                          <div className={`${cx.teamContainer}`}>
                            <Row>
                              {!cohortLoading &&
                                searchedTeam?.map((team: TMember) => {
                                  const nameWithEllipsis = textEllipsis({
                                    charLimit: 12,
                                    text: team?.name
                                  });
                                  return (
                                    <Col md={3} key={team?.id} id="teamMemberIds">
                                      <div
                                        className={`${cx.teamCard} ${values.teamMemberIds.some((item: TCohortTeam) => team.id === item.id) ? cx.active : ''}`}
                                        onClick={() => {
                                          if (
                                            values.teamMemberIds.some(
                                              (item: TCohortTeam) => team.id === item.id
                                            )
                                          ) {
                                            const filterTeam =
                                              values.teamMemberIds?.length > 0
                                                ? JSON.parse(
                                                  JSON.stringify(
                                                    values.teamMemberIds?.filter(
                                                      (item: TCohortTeam) => team.id !== item.id
                                                    )
                                                  )
                                                )
                                                : [];
                                            const filterTeamArr = selectedTeam.filter(
                                              (item: TMember) => item.id !== team.id
                                            );
                                            setSelectedTeam(filterTeamArr);
                                            setFieldValue('teamMemberIds', filterTeam);
                                          } else {
                                            setFieldValue('teamMemberIds', [
                                              ...values.teamMemberIds,
                                              { id: team.id, role: team?.role }
                                            ]);
                                            setSelectedTeam([...selectedTeam, team]);
                                          }
                                        }}
                                      >
                                        <div className={cx.profile}>
                                          <ProfilePicture
                                            url={team?.profilePic}
                                            fallback={team.name?.[0]?.toLocaleUpperCase()}
                                          />
                                          <div className={cx.teamDetails}>
                                            <Typography
                                              as="p"
                                              variant="body-2"
                                              fontWeight="semiBold"
                                              className="key-member-card__header__user-info__user__name"
                                              title={team.name}
                                            >
                                              {nameWithEllipsis.text}
                                            </Typography>
                                            <Typography
                                              as="p"
                                              variant="caption"
                                              fontWeight="regular"
                                              className="key-member-card__header__user-info__user__role"
                                            >
                                              {team.role}
                                            </Typography>
                                          </div>
                                        </div>
                                        {/* <div className={cx.addRemoveButton}>
                                    <span onClick={() => setFieldValue('teamMemberIds', [...values.teamMemberIds, team.id])}><MdAdd /></span>
                                    <span onClick={() => {
                                      const filterTeam = values.teamMemberIds?.length > 0 ? JSON.parse(JSON.stringify(values.teamMemberIds?.filter((item: string) => team.id !== item))) : []
                                      setFieldValue('teamMemberIds', filterTeam)
                                    }}><MdRemove /></span>
                                  </div> */}
                                      </div>
                                    </Col>
                                  );
                                })}
                              {searchedTeam?.length === 0 && !cohortLoading && (
                                <h5 className={`${cx.emptyMessage}`}>No team member found</h5>
                              )}
                              {cohortLoading && <Loader />}
                            </Row>
                          </div>
                        </Col>
                      </div>
                    )}
                    {selectedTeam?.length > 0 && (
                      <Col md={12}>
                        <div className={`${cx.teamContainer}`}>
                          <Row>
                            <Col md={12}>
                              <div className={cx.logolabel}>
                                <label>Selected Team Members</label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {selectedTeam?.map((team: TMember) => {
                              const nameWithEllipsis = textEllipsis({
                                charLimit: 12,
                                text: team?.name
                              });
                              return (
                                <Col md={3} key={team?.id}>
                                  <div
                                    className={`${cx.teamCard} ${values.teamMemberIds.some((item: TCohortTeam) => team.id === item.id) ? cx.active : ''}`}
                                  >
                                    <div
                                      className={cx.removeIcon}
                                      onClick={() => {
                                        if (
                                          values.teamMemberIds.some(
                                            (item: TCohortTeam) => team.id === item.id
                                          )
                                        ) {
                                          const filterTeam =
                                            values.teamMemberIds?.length > 0
                                              ? JSON.parse(
                                                JSON.stringify(
                                                  values.teamMemberIds?.filter(
                                                    (item: TCohortTeam) => team.id !== item.id
                                                  )
                                                )
                                              )
                                              : [];
                                          const filterTeamArr = selectedTeam.filter(
                                            (item: TMember) => item.id !== team.id
                                          );
                                          setSelectedTeam(filterTeamArr);
                                          setFieldValue('teamMemberIds', filterTeam);
                                        } else {
                                          setFieldValue('teamMemberIds', [
                                            ...values.teamMemberIds,
                                            { id: team.id, role: team?.role }
                                          ]);
                                          setSelectedTeam([...selectedTeam, team]);
                                        }
                                      }}
                                    >
                                      <CrossIcon />
                                    </div>
                                    <div className={cx.profile}>
                                      <ProfilePicture
                                        url={team?.profilePic}
                                        fallback={team.name?.[0]?.toLocaleUpperCase()}
                                      />
                                      <div className={cx.teamDetails}>
                                        <Typography
                                          as="p"
                                          variant="body-2"
                                          fontWeight="semiBold"
                                          className="key-member-card__header__user-info__user__name"
                                          title={team.name}
                                        >
                                          {nameWithEllipsis.text}
                                        </Typography>
                                        <Typography
                                          as="p"
                                          variant="caption"
                                          fontWeight="regular"
                                          className="key-member-card__header__user-info__user__role"
                                        >
                                          {team.role}
                                        </Typography>
                                      </div>
                                    </div>
                                    {/* <div className={cx.addRemoveButton}>
                                    <span onClick={() => setFieldValue('teamMemberIds', [...values.teamMemberIds, team.id])}><MdAdd /></span>
                                    <span onClick={() => {
                                      const filterTeam = values.teamMemberIds?.length > 0 ? JSON.parse(JSON.stringify(values.teamMemberIds?.filter((item: string) => team.id !== item))) : []
                                      setFieldValue('teamMemberIds', filterTeam)
                                    }}><MdRemove /></span>
                                  </div> */}
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </Col>
                    )}
                  </div>
                </Row>
                <Row>
                  <Col md={3}>
                    <Button variant="primary" type="submit" size="small">
                      {loading ? <Loader /> : resourceId ? 'Update' : 'Submit'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
