import React from 'react'
import { Card, Typography } from 'src/components/common'
import { PlusIconCircle } from 'src/components/common/common.icons'
import './createCard.scss'
interface TCreateCardProps {
    title: string,
    onClick?: () => void
}
export default function CreateCard({ title, onClick }: TCreateCardProps) {
    return (
        <Card className="create-card" onClick={() => onClick && onClick()}>
            <PlusIconCircle />
            <Typography variant="subHeading-2" fontWeight="semiBold">
                {title}
            </Typography>
        </Card>
    )
}
