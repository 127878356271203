import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';
import { Card, Typography } from 'src/components/common';

import './labelValue.scss';

export type TLabelValue = {
  label: string;
  value: ReactNode | string;
} & HTMLAttributes<HTMLDivElement>;

const LabelValue = ({ label, value, className, ...rest }: TLabelValue) => {
  return (
    <Card key={label} className={classNames('label-value-component', className)} {...rest}>
      <Typography
        as="p"
        variant="body-2"
        fontWeight="semiBold"
        className="label-value-component__label"
      >
        {label}
      </Typography>
      {label === 'About Company'
        ? (<Typography
          as="div"
          className="label-value-component__value"
          dangerouslySetInnerHTML={{ __html: value as string }}
          style={{ wordBreak: 'keep-all' }}
        />
          )
        : (
        <Typography
          as={
            label.indexOf('LinkedIn') > -1
              ? 'div'
              : 'p'
          }
          variant="caption"
          fontWeight="regular"
          className="label-value-component__value"
        >
          {value}
        </Typography>
          )}
    </Card>
  );
};

export default LabelValue;
