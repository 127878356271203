import { useMemo } from 'react';
import Card from 'src/components/common/card/Card';
import Typography from 'src/components/common/typography/Typography';
import { facebookLogo, instagramLogo, linkedinLogo, twitterLogo } from 'src/assets/images';
import { parseLinkedinUsername, textFormatter } from 'src/utils';

import './contactInfo.scss';

export type TContactInfo = {
  address: string | null;
  dob: string | null;
  email: string;
  gender: string;
  linkedin: string | null;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
  phoneNumber: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
};

const ContactInfo = ({
  address = '',
  email,
  gender,
  linkedin,
  facebook,
  twitter,
  instagram,
  phoneNumber,
  country = '',
  state = '',
  city = '',
  zipCode = ''
}: TContactInfo) => {
  const logos = { facebookLogo, instagramLogo, linkedinLogo, twitterLogo }
  const userAddress: string = useMemo(() => {
    try {
      const addressArray = [address, city, state, country, zipCode];

      let addressString = '';

      addressArray.forEach((field, index) => {
        if (index + 1 >= addressArray?.length) {
          // last field
          addressString += field ? `${field}` : '';
        } else if (field) {
          addressString += field.toString().trim() ? `${field}, ` : '';
        }
      });

      return addressString;
    } catch {
      return '-';
    }
  }, [JSON.stringify(address), city, state, country, zipCode]);

  const linkedInUserName = parseLinkedinUsername({ url: linkedin || '' });

  const openLinkedinProfile = (account: string) => {
    if (account) {
      // Check if account already contains the protocol (http or https)
      const validUrl = account.startsWith('http://') || account.startsWith('https://')
        ? account
        : `https://${account}`;

      window.open(validUrl, '_blank');
    }
  };

  const socialAccountArr = [{ type: 'linkedin', url: linkedin }, { type: 'instagram', url: instagram }, { type: 'twitter', url: twitter }, { type: 'facebook', url: facebook }]?.filter((item: { type: string, url: string | null }) => item?.url)
  
  const ContactInfoFields = [
    { label: 'Phone Number', value: phoneNumber === '0' ? '-' : phoneNumber },
    { label: 'Email Address', value: textFormatter({ value: email }) },
    { label: 'Gender', value: textFormatter({ value: gender }) },

    {
      label: 'Find Me on ',
      value: (
        <div className="my-profile-contact-info__fields__field__value__linkedin">
          {socialAccountArr?.map((account: { type: string, url: string | null }) => {
            const logo = `${account?.type}Logo`
            return (
              <Typography as="p" variant="body-2" fontWeight="medium" onClick={() => openLinkedinProfile(account?.url)} key={account?.type}>
                <img src={logos[logo]} alt={account?.type}/>
              </Typography>
            )
          })}

          {/* <Typography as="p" variant="body-2" fontWeight="medium" onClick={openLinkedinProfile}>
            <img src={instagramLogo} />
          </Typography>

          <Typography as="p" variant="body-2" fontWeight="medium" onClick={openLinkedinProfile}>
            <img src={facebookLogo} />
          </Typography>

          <Typography as="p" variant="body-2" fontWeight="medium" onClick={openLinkedinProfile}>
            <img src={twitterLogo} />
          </Typography> */}
        </div>
      )
    },
    {
      label: 'Address',
      value: textFormatter({ value: userAddress || '-' })
    }
  ];

  return (
    <div className="my-profile-contact-info">
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        className="my-profile-contact-info__heading"
      >
        Contact Info
      </Typography>
      <div className="my-profile-contact-info__fields">
        {ContactInfoFields.map(({ label, value }) => (
          <Card key={label} className="my-profile-contact-info__fields__field">
            <Typography
              as="p"
              variant="body-2"
              fontWeight="semiBold"
              className="my-profile-contact-info__fields__field__label"
            >
              {label}
            </Typography>
            <Typography
              as={label.indexOf('LinkedIn') > -1 ? 'div' : 'p'}
              variant="caption"
              fontWeight="regular"
              className="my-profile-contact-info__fields__field__value"
            >
              {value}
            </Typography>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ContactInfo;
