import { useMemo } from 'react';

import './dynamicField.scss';
import { TDynamicFieldProps } from './dynamicField.types';
import TextField from './TextFieldDefault';
import TextFieldNew from './TextFieldNew';
import TextEditor from './TextEditor';
import TextEditorNew from './TextEditorNew';
import AddLabel from './AddLabel';
import DateTimeSelection from './DateTimeSelection';
import UploadField from './UploadField';

const DynamicField = ({
  id,
  label,
  placeholder,
  type,
  files=[],
  openChangeFieldModal,
  deleteField,
  values,
  customFields = [],
  setCustomFields,
  errors
}: TDynamicFieldProps) => {
  const fieldComponent = useMemo(() => {
    const fieldProps = {
      id,
      label,
      placeholder,
      type,
      files,
      openChangeFieldModal,
      deleteField,
      values,
      customFields,
      setCustomFields,
      errors
    }
    if (type === 'textInput' && label) {
      return <TextField {...fieldProps} />;
    }
    if (type === 'textInput' && !label) {
      return <TextFieldNew {...fieldProps} />;
    }
    if (type === 'textEditor' && label) {
      return <TextEditor {...fieldProps} />;
    }
    if (type === 'textEditor' && !label) {
      return <TextEditorNew {...fieldProps} />;
    }
    if (type === 'label') {
      return <AddLabel {...fieldProps} />;
    }
    if (type === 'dateSelection') {
      return (
        <DateTimeSelection
          {...fieldProps}
          label="Date selection field"
          placeholder="Select date"
          inputType="date"
        />
      );
    }
    if (type === 'timeSelection') {
      return (
        <DateTimeSelection
          {...fieldProps}
          label="Time selection field"
          placeholder="Select time"
          inputType="time"
        />
      );
    }
    if (type === 'fileUpload') {
      return <UploadField {...fieldProps} />;
    }
  }, [id, type, errors]);

  return fieldComponent;
};

export default DynamicField;
