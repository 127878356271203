import axios from 'axios';

import {
  TApplicantsListRequest,
  TApplicationDemoRequest,
  TAppliedProgramsListRequest,
  TArchiveProgramRequest,
  TCloseProgramRequest,
  TCreateApplicationRequest,
  TCreateProgramRequest,
  TEditProgramMembersRequest,
  TGetApplicationRequest,
  TGetProgramRequest,
  TProgramMembersListRequest,
  TProgramListRequest,
  TUpdateProgramRequest,
  TPromoteApplicationRequest,
  TGetProgramMembersRequest,
  TUploadProgramFileRequest,
  TUploadApplicationFileRequest,
  TUploadFileToS3Request,
  TGetProgramDocumentRequest,
  TRespondToKeyMemberSelectionRequest
} from './apiEndpoint.types.ts';
import { APIResponse, BaseAPIService } from './base.api.service';

export class ProgramAPIService extends BaseAPIService {
  private createProgramPath: string = '/program/create';
  private updateProgramPath: string = '/program/update';
  private programListPath: string = '/program/list';
  private getProgramPath: string = '/program/get';
  private createApplicationPath: string = '/program/application/create';
  private closeProgramPath: string = '/program/close';
  private archiveProgramPath: string = '/program/archive';
  private applicantsListPath: string = '/program/application/list';
  private getApplicationPath: string = '/program/application/get';
  private editProgramMembersPath: string = 'program/members/edit';
  private createApplicationDemoPath: string = 'program/application/demo/create';
  private promoteApplicationPath: string = 'program/application/promote';
  private appliedProgramsListPath: string = 'program/list/applied';
  private programsMemberListPath: string = 'program/members/list';
  private keyMembersListPath: string = 'company/key-members/list';
  private respondToKeyMemberSelectionPath: string = 'company/key-members/respond-to-request';
  private uploadProgramFilePath: string = 'program/document/create';
  private uploadApplicationFilePath: string = 'program/application/document/create';
  private getDocumentPath: string = 'program/documents/get';

  constructor() {
    // Program api service has a different endpoint from base service.
    super();
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_PROGRAM_ENDPOINT
    });
    this.configureInterceptors();
  }

  public async createProgram<TCreateProgramResponse>(
    createProgramRequest: TCreateProgramRequest
  ): Promise<APIResponse<TCreateProgramResponse>> {
    const createProgramResponse: APIResponse<TCreateProgramResponse> = await this.post(
      this.createProgramPath,
      createProgramRequest,
      true
    );
    return createProgramResponse;
  }
  
  public async updateProgram<TUpdateProgramResponse>(
    updateProgramRequest: TUpdateProgramRequest
  ): Promise<APIResponse<TUpdateProgramResponse>> {
    const updateProgramResponse: APIResponse<TUpdateProgramResponse> = await this.patch(
      this.updateProgramPath,
      updateProgramRequest,
      true
    );
    return updateProgramResponse;
  }
  // public async createProgram<TCreateProgramResponse>(
  //   createProgramRequest: TCreateProgramRequest
  // ): Promise<APIResponse<TCreateProgramResponse>> {
  //   const createProgramResponse: APIResponse<TCreateProgramResponse> = await this.post(
  //     this.createProgramPath,
  //     createProgramRequest,
  //     true
  //   );
  //   return createProgramResponse;
  // }

  public async programList<TProgramListResponse>(
    programListRequest: TProgramListRequest
  ): Promise<APIResponse<TProgramListResponse>> {
    const programListResponse: APIResponse<TProgramListResponse> = await this.get(
      this.programListPath,
      true,
      programListRequest
    );
    return programListResponse;
  }

  public async getProgram<TGetProgramResponse>(
    getProgramRequest: TGetProgramRequest
  ): Promise<APIResponse<TGetProgramResponse>> {
    const getProgramResponse: APIResponse<TGetProgramResponse> = await this.get(
      this.getProgramPath,
      true,
      getProgramRequest
    );
    return getProgramResponse;
  }

  public async createApplication<TCreateApplicationResponse>(
    createApplicationRequest: TCreateApplicationRequest
  ): Promise<APIResponse<TCreateApplicationResponse>> {
    const createApplicationResponse: APIResponse<TCreateApplicationResponse> = await this.post(
      this.createApplicationPath,
      createApplicationRequest,
      true
    );
    return createApplicationResponse;
  }

  public async closeProgram<TCloseProgramResponse>(
    closeProgramRequest: TCloseProgramRequest
  ): Promise<APIResponse<TCloseProgramResponse>> {
    const closeProgramResponse: APIResponse<TCloseProgramResponse> = await this.post(
      this.closeProgramPath,
      closeProgramRequest,
      true
    );
    return closeProgramResponse;
  }

  public async archiveProgram<TArchiveProgramResponse>(
    archiveProgramRequest: TArchiveProgramRequest
  ): Promise<APIResponse<TArchiveProgramResponse>> {
    const archiveProgramResponse: APIResponse<TArchiveProgramResponse> = await this.post(
      this.archiveProgramPath,
      archiveProgramRequest,
      true
    );
    return archiveProgramResponse;
  }

  public async applicantsList<TApplicantsListResponse>(
    applicantsListRequest: TApplicantsListRequest
  ): Promise<APIResponse<TApplicantsListResponse>> {
    const applicantsListResponse: APIResponse<TApplicantsListResponse> = await this.get(
      this.applicantsListPath,
      true,
      applicantsListRequest
    );
    return applicantsListResponse;
  }

  public async getApplication<TGetApplicationResponse>(
    getApplicationRequest: TGetApplicationRequest
  ): Promise<APIResponse<TGetApplicationResponse>> {
    const getApplicationResponse: APIResponse<TGetApplicationResponse> = await this.get(
      this.getApplicationPath,
      true,
      getApplicationRequest
    );
    return getApplicationResponse;
  }

  public async editProgramMembers<TEditProgramMembersResponse>(
    editProgramMembersRequest: TEditProgramMembersRequest
  ): Promise<APIResponse<TEditProgramMembersResponse>> {
    const editProgramMembersResponse: APIResponse<TEditProgramMembersResponse> = await this.post(
      this.editProgramMembersPath,
      editProgramMembersRequest,
      true
    );
    return editProgramMembersResponse;
  }

  public async createApplicationDemo<TApplicationDemoResponse>(
    createApplicationDemoRequest: TApplicationDemoRequest
  ): Promise<APIResponse<TApplicationDemoResponse>> {
    const createApplicationDemoResponse: APIResponse<TApplicationDemoResponse> = await this.post(
      this.createApplicationDemoPath,
      createApplicationDemoRequest,
      true
    );
    return createApplicationDemoResponse;
  }

  public async promoteApplication<TPromoteApplicationResponse>(
    promoteApplicationRequest: TPromoteApplicationRequest
  ): Promise<APIResponse<TPromoteApplicationResponse>> {
    const promoteApplicationResponse: APIResponse<TPromoteApplicationResponse> = await this.post(
      this.promoteApplicationPath,
      promoteApplicationRequest,
      true
    );
    return promoteApplicationResponse;
  }

  public async appliedProgramsList<TAppliedProgramsListResponse>(
    appliedProgramsListRequest: TAppliedProgramsListRequest
  ): Promise<APIResponse<TAppliedProgramsListResponse>> {
    const appliedProgramsListResponse: APIResponse<TAppliedProgramsListResponse> = await this.get(
      this.appliedProgramsListPath,
      true,
      appliedProgramsListRequest
    );
    return appliedProgramsListResponse;
  }

  public async programMembersList<TProgramMembersListResponse>(
    programMembersListRequest: TProgramMembersListRequest
  ): Promise<APIResponse<TProgramMembersListResponse>> {
    const programMembersListResponse: APIResponse<TProgramMembersListResponse> = await this.get(
      this.programsMemberListPath,
      true,
      programMembersListRequest
    );
    return programMembersListResponse;
  }

  public async keyMembersList<TGetProgramKeyMemberResponse>(
    keyMembersListRequest: TGetProgramMembersRequest
  ): Promise<APIResponse<TGetProgramKeyMemberResponse>> {
    const keyMembersListResponse: APIResponse<TGetProgramKeyMemberResponse> = await this.get(
      this.keyMembersListPath,
      true,
      keyMembersListRequest
    );
    return keyMembersListResponse;
  }

  public async uploadProgramFile<TUploadProgramFileResponse>(
    uploadProgramFileRequest: TUploadProgramFileRequest
  ): Promise<APIResponse<TUploadProgramFileResponse>> {
    const uploadProgramFileResponse: APIResponse<TUploadProgramFileResponse> = await this.post(
      this.uploadProgramFilePath,
      uploadProgramFileRequest,
      true
    );
    return uploadProgramFileResponse;
  }

  public async uploadApplicationFile<TUploadApplicationFileResponse>(
    uploadApplicationFileRequest: TUploadApplicationFileRequest
  ): Promise<APIResponse<TUploadApplicationFileResponse>> {
    const uploadApplicationFileResponse: APIResponse<TUploadApplicationFileResponse> =
      await this.post(this.uploadApplicationFilePath, uploadApplicationFileRequest, true);
    return uploadApplicationFileResponse;
  }

  public async uploadFileToS3<TUploadFileToS3Response>(
    uploadFileToS3Request: TUploadFileToS3Request
  ): Promise<APIResponse<TUploadFileToS3Response>> {
    const { data, includeAuthorizationHeaders = true } = uploadFileToS3Request;
    const uploadFileToS3Response: APIResponse<TUploadFileToS3Response> = await this.put(
      uploadFileToS3Request.s3Key,
      data,
      includeAuthorizationHeaders
    );
    return uploadFileToS3Response;
  }

  public async getDocument<TGetProgramDocumentResponse>(
    getDocumentRequest: TGetProgramDocumentRequest
  ): Promise<APIResponse<TGetProgramDocumentResponse>> {
    const documentResponse: APIResponse<TGetProgramDocumentResponse> = await this.get(
      this.getDocumentPath,
      true,
      getDocumentRequest
    );
    return documentResponse;
  }

  public async respondToKeyMemberSelection<TRespondToKeyMemberSelectionResponse>(
    respondToKeyMemberSelectionRequest: TRespondToKeyMemberSelectionRequest
  ): Promise<APIResponse<TRespondToKeyMemberSelectionResponse>> {
    const respondToKeyMemberSelectionResponse: APIResponse<TRespondToKeyMemberSelectionResponse> =
      await this.post(
        this.respondToKeyMemberSelectionPath,
        respondToKeyMemberSelectionRequest,
        true
      );
    return respondToKeyMemberSelectionResponse;
  }
}
