import { ChangeEvent, useRef, useState } from 'react';
import { ActionIcon, Card, Typography } from 'src/components/common';
import { CalendarIcon, EditIcon, TrashIcon } from 'src/components/common/common.icons';
import { componentSizeVariantsEnum } from 'src/constants';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { useGetFormikField, useToggleOpenModal } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';

import './dynamicField.scss';
import { TDateTimeSelectionProps } from './dynamicField.types';

import { TCustomField, TFieldType } from '../programDetails.types';
import AddEditProgramDetailsFields from '../addEditProgramDetailsFields/AddEditProgramDetailsFields';

const editProgramDetailsDateTimeFieldsId = 'editProgramDetailsDateTimeFields';

const DateTimeSelection = ({
  id,
  label,
  placeholder,
  inputType,
  type,
  deleteField,
  setCustomFields,
  errors
}: TDateTimeSelectionProps) => {
  // Hooks
  const { getFormikField } = useGetFormikField();
  const toggleOpenModal = useToggleOpenModal();
  const modalId: string = useAppSelector((store) => store.common.modalId);

  // State
  const [editFieldModalData, setEditFieldModalData] = useState<{
    id: string;
    fieldType: TFieldType;
  }>({ id: '', fieldType: 'label' });
  const dateTimeSelectionRef = useRef<HTMLInputElement[]>([]);

  // Constants
  const isEditProgramDetailsFieldsOpen = modalId === editProgramDetailsDateTimeFieldsId;

  const [field, , helpers] = getFormikField({ id: id || '' });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label: field?.value?.label,
        type,
        values: e.target.value,
        files: []
      });
    }
  };

  const handleLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label: e.target.value,
        type,
        values: field?.value?.values,
        files: []
      });
    }
  };

  const handleDeleteField = () => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue(undefined, false);
    }

    deleteField({ id });
  };

  const changeFieldType = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setCustomFields((prev) => {
      const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
      const selectedField = currentFields.find((field) => field.id === id);
      if (selectedField) {
        selectedField.type = fieldType;
      }

      return currentFields;
    });

    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label: '',
        type: fieldType,
        values: '',
        files: []
      });
    }

    setEditFieldModalData({ id: '', fieldType: 'label' });
    handleToggleOpenEditFieldsModal();
  };

  const handleToggleOpenEditFieldsModal = () => {
    toggleOpenModal({ modalId: editProgramDetailsDateTimeFieldsId });
  };

  const openChangeFieldModal = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setEditFieldModalData({ fieldType, id: id || '' });
    handleToggleOpenEditFieldsModal();
  };

  const labelValue: string = field?.value?.label;
  console.log(field?.value, 'field?.value');
  
  return (
    <Card key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Typography as="span" variant="caption" fontWeight="semiBold">
          {label}
        </Typography>
        <div className="dynamic-field__header__actions">
          <ActionIcon
            onClick={() => {
              openChangeFieldModal({ id, fieldType: type });
            }}
          >
            <EditIcon />
          </ActionIcon>
          <ActionIcon onClick={handleDeleteField}>
            <TrashIcon />
          </ActionIcon>
        </div>
      </div>

      <InputField
        label="Field title"
        id={`${id}-label`}
        variant={componentSizeVariantsEnum.SMALL}
        placeholder="Enter field title"
        type="text"
        onChange={handleLabelChange}
        value={labelValue}
        errorMessage={errors?.[`${id}-label`]}
        isTouched={true}
      />
      <InputField
        label={label}
        id={id}
        elementtype='program-creation'
        variant={componentSizeVariantsEnum.SMALL}
        placeholder={placeholder}
        type={inputType}
        inputRef={(el: HTMLInputElement) => {
          dateTimeSelectionRef.current[0] = el;
        }}
        endIconClickHandler={() => {
          if (dateTimeSelectionRef.current) {
            dateTimeSelectionRef.current[0].showPicker();
          }
        }}
        onChange={handleChange}
        value={field?.value?.values}
        endIcon={<CalendarIcon />}
        errorMessage={errors?.[id]}
        isTouched={true}
        required
      />
      {isEditProgramDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isEditProgramDetailsFieldsOpen}
          onModalClose={handleToggleOpenEditFieldsModal}
          title="Edit Fields"
          currentType={editFieldModalData.fieldType}
          id={editFieldModalData.id}
          onSelection={changeFieldType}
        />
      )}
    </Card>
  );
};

export default DateTimeSelection;
