import { useMemo } from 'react';
import {
  ActionIcon,
  ConfirmModal,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Typography
} from 'src/components/common';
import Button from 'src/components/button/Button';
import { ArchiveIcon, ShareIcon } from 'src/components/common/common.icons';
import {
  useCopyToClipboard,
  useGetIdentity,
  useToggleOpenDropdown,
  useToggleOpenModal
} from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TCloseProgramRequest, TArchiveProgramRequest } from 'src/services/apiEndpoint.types.ts';
import { closeProgram, archiveProgram } from 'src/store/program/program.slice';
import CreateEditPostModal from 'src/components/posts/createEditPostModal/CreateEditPostModal';
import { colors } from 'src/constants';

import './programActions.scss';
import { TProgramActionsProps } from './programActions.types';

const shareProgramDropdownId = 'shareProgramDropdown';

const ProgramActions = ({
  status,
  programId,
  handleGetProgram,
  companyId
}: TProgramActionsProps) => {
  // Hooks
  const toggleOpenDropdown = useToggleOpenDropdown();
  const { modalId, loading }: { modalId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );
  const { copyToClipboard } = useCopyToClipboard();
  const toggleOpenModal = useToggleOpenModal();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const { loggedInUserId, loggedInCompanyId, loggedInUserType } = getIdentities();

  const closeProgramModalId: string = `closeProgramModal-${programId}`;
  const archiveProgramModalId: string = `archiveProgramModal-${programId}`;
  const shareProgramModalId: string = `shareProgram-${programId}`;
  const isShareProgramModalOpen = modalId === shareProgramModalId;
  const viewerId = loggedInUserType === 'User' ? loggedInUserId : loggedInCompanyId;

  // Constants
  const isCloseProgramModalOpen = modalId === closeProgramModalId;
  const isArchiveProgramModalOpen = modalId === archiveProgramModalId;
  const isViewProgramCreator = loggedInCompanyId === companyId;

  const toggleOpenCloseProgramModal = () => {
    toggleOpenModal({ modalId: closeProgramModalId });
  };

  const toggleOpenArchiveProgramModal = () => {
    toggleOpenModal({ modalId: archiveProgramModalId });
  };

  const toggleOpenShareProgramModal = () => {
    toggleOpenModal({ modalId: shareProgramModalId });
  };

  const handleCloseProgram = () => {
    const payload: TCloseProgramRequest = {
      programId: programId
    };

    dispatch(closeProgram(payload)).then(() => {
      handleGetProgram();
    });
  };

  const showShareProgramButton = useMemo(() => {
    if (!isViewProgramCreator) {
      return false;
    }
    if (status === 'Closed') {
      return false;
    }
    if (status === 'Archived') {
      return false;
    }
    return true;
  }, [status, isViewProgramCreator]);

  const showCloseProgramButton = useMemo(() => {
    if (!isViewProgramCreator) {
      return false;
    }
    if (status === 'Closed') {
      return false;
    }
    if (status === 'Archived') {
      return false;
    }
    return true;
  }, [status, isViewProgramCreator]);

  const showArchiveProgramButton = useMemo(() => {
    if (!isViewProgramCreator) {
      return false;
    }
    if (status === 'Archived') {
      return false;
    }
    return true;
  }, [status, isViewProgramCreator]);

  const handleArchiveProgram = () => {
    const payload: TArchiveProgramRequest = {
      programId: programId
    };

    dispatch(archiveProgram(payload)).then(() => {
      handleGetProgram();
    });
  };

  const handleOpenShareDropdown = () => {
    toggleOpenDropdown({ dropdownId: shareProgramDropdownId });
  };

  const shareProgramDropdownOptions: TDropdownOptions[] = [
    {
      label: 'Share as announcement post',
      onClick: toggleOpenShareProgramModal
    },

    {
      label: 'Copy application form link',
      onClick: () => {
        // copyToClipboard({ text: `${window.location.host}/apply-to-program?id=${programId}` });
        copyToClipboard({ text: `${window.location.host}/apply-to-program-external?id=${programId}` });
      }
    }
  ];

  const shareProgramDropdownProps: TDropdown = {
    id: shareProgramDropdownId,
    size: 'large',
    alignment: 'left',
    items: shareProgramDropdownOptions
  };

  return (
    <div className="program-actions">
      <div className="program-actions__label">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Program Details
        </Typography>
        {status === 'Closed' && (
          <Typography
            as="span"
            variant="body-2"
            fontWeight="regular"
            style={{ color: colors.neutral[1000] }}
          >
            Program closed!
          </Typography>
        )}
      </div>
      {/*
       ** Program action buttons
       */}
      <div className="program-actions__actions">
        {/*
         ** Share program
         */}
        {showShareProgramButton && (
          <div
            className="program-actions__actions__share"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenShareDropdown();
            }}
          >
            <div className="program-actions__actions__share__icon">
              <ShareIcon />
            </div>
            <SubMenuModal>
              <Dropdown {...shareProgramDropdownProps} />
            </SubMenuModal>
          </div>
        )}

        {/*
         ** Close program
         */}
        {showCloseProgramButton && (
          <Button
            size="small"
            variant="primary"
            className="program-actions__actions__next"
            type="button"
            onClick={toggleOpenCloseProgramModal}
          >
            Close Program
          </Button>
        )}
        {/*
         ** Archive program
         */}
        {/* {showArchiveProgramButton && (
          <ActionIcon
            className="program-actions__actions__archive"
            onClick={toggleOpenArchiveProgramModal}
          >
            <ArchiveIcon />
          </ActionIcon>
        )} */}
      </div>
      {isCloseProgramModalOpen && (
        <ConfirmModal
          title="Are you sure you want to close this program?"
          openConfirmModal={isCloseProgramModalOpen}
          onClose={toggleOpenCloseProgramModal}
          onSubmit={handleCloseProgram}
          submitText="Confirm"
          type="error"
          loading={loading}
        />
      )}
      {isArchiveProgramModalOpen && (
        <ConfirmModal
          title=" Are you sure you want to archive this program?"
          openConfirmModal={isArchiveProgramModalOpen}
          onClose={toggleOpenArchiveProgramModal}
          onSubmit={handleArchiveProgram}
          submitText="Archive"
          type="error"
          loading={loading}
        />
      )}{' '}
      {isShareProgramModalOpen && (
        <CreateEditPostModal
          title="Create Post"
          companyProfile={true}
          isModalOpen={isShareProgramModalOpen}
          onModalClose={toggleOpenShareProgramModal}
          viewerId={viewerId}
          viewerType={loggedInUserType}
          type="announcement"
          shareProgramMode={true}
          // postRedirectUrl={`${window.location.host}/apply-to-program?id=${programId}`}
          postRedirectUrl={`${window.location.host}/apply-to-program-external?id=${programId}`}
        />
      )}
    </div>
  );
};

export default ProgramActions;
