import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TDropdownOptions, Typography } from 'src/components/common';
import { assignModalId } from 'src/store/common/common.slice';
import { useToggleOpenModal } from 'src/hooks';

import CreateNewIdeaCard from './createNewIdeaCard/CreateNewIdeaCard';
import './myIdeas.scss';

import SearchIdeas from '../searchFilterIdeas/SearchFilterIdeas';
import IdeaCard from '../ideaCard/IdeaCard';
import { TIdeaHubSearchParams, TMyIdea } from '../ideaHub.types';
import ShareToCollaborate from '../ideaDetails/shareToCollaborate/ShareToCollaborate';
import ShareToEvaluate from '../ideaDetails/shareToEvaluate/ShareToEvaluate';

export type TMyIdeaCard = {
  id: string;
  status: 'Draft' | 'Finalized';
  title: string;
  description: string;
  updatedAt: string;
  collaborators: { name: string; profilePic: string }[];
};

export type TMyIdeasProps = {
  updateParams: ({ param, value }: { param: TIdeaHubSearchParams; value: string }) => void;
  increaseFetchIdeasCounter: () => void;
};

const MyIdeas: FC<TMyIdeasProps> = ({ updateParams, increaseFetchIdeasCounter }: TMyIdeasProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toggleOpenModal = useToggleOpenModal();
  const myIdeas: TMyIdea[] = useAppSelector((store) => store.ideaHub.myIdeas);
  const { modalId } = useAppSelector((store) => store.common);
  const profile = useAppSelector((store) => store.profile.profile);

  const handleCloseModal = () => {
    dispatch(assignModalId(''));
  };

  return (
    <div className="idea-hub-my-ideas">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="idea-hub-my-ideas__heading"
      >
        My Ideas
      </Typography>
      <SearchIdeas
        updateParams={updateParams}
        increaseFetchIdeasCounter={increaseFetchIdeasCounter}
      />
      <div className="idea-hub-my-ideas__ideas">
        <CreateNewIdeaCard />
        {myIdeas.map((myIdea, index) => {
          const { idea, collaborators, collaboratorCount } = myIdea;
          const { id, name, email, profilePic, role } = profile;
          console.log(collaborators, 'collaborators');
          
          const shareToCollaborateModalId = `shareToCollaborateModal-${idea.id}`;
          const openShareToCollaborateModal = modalId === shareToCollaborateModalId;
          const shareToEvaluateModalId = `shareToEvaluateModal-${idea.id}`;
          const openShareToEvaluateModal = modalId === shareToEvaluateModalId;

          const ideaFilterDropdownItems: TDropdownOptions[] = [
            {
              label: 'View idea',
              onClick: () => {
                navigate(`/view-idea?id=${idea?.id}&tab=ideaDetails`);
              }
            },

            {
              label: 'Share to collaborate',
              onClick: () => {
                toggleOpenModal({ modalId: shareToCollaborateModalId });
              }
            },
            {
              label: 'Share to evaluate',
              onClick: () => {
                toggleOpenModal({ modalId: shareToEvaluateModalId });
              }
            },
            {
              label: 'Export idea',
              onClick: () => { }
            }
          ];
          // const collaboratorsFormatted = [...collaborators, { User: idea?.owner }].map((collaborator) => {
          //   return {
          //     id: collaborator?.User?.id,
          //     name: collaborator?.User?.name,
          //     profilePic: collaborator?.User?.profilePic
          //   };
          // });
          const filterCollaborators = collaborators?.filter((collaborator:any) => collaborator?.status !== 'Pending')
          const collaboratorsFormatted = filterCollaborators.map((collaborator) => {
            return {
              id: collaborator?.User?.id,
              name: collaborator?.User?.name,
              profilePic: collaborator?.User?.profilePic
            };
          });

          return (
            <>
              <IdeaCard
                key={index}
                ideaFilterDropdownItems={ideaFilterDropdownItems}
                {...idea}
                owner={{ id, name, email, profilePic, role }}
                collaborators={collaboratorsFormatted}
                collaboratorCount={collaboratorCount}
              />
              {openShareToCollaborateModal && (
                <ShareToCollaborate
                  ideaId={idea?.id}
                  isModalOpen={openShareToCollaborateModal}
                  handleCloseModal={handleCloseModal}
                />
              )}
              {openShareToEvaluateModal && (
                <ShareToEvaluate
                  ideaId={idea?.id}
                  isModalOpen={openShareToEvaluateModal}
                  handleCloseModal={handleCloseModal}
                />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default MyIdeas;
