import React, { useEffect, useRef, useState } from 'react'
import { InfiniteScroll, Tabs, TTabItem } from 'src/components/common'
import Header from 'src/components/header/Header';
import cx from './marketplace.module.scss';
import MarketPlaceSection from './MarketPlaceSection';
import { useNavigate } from 'react-router-dom';
import ToggleBox from 'src/components/common/ToggleBox/ToggleBox';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { suggestedListModalStateChange } from 'src/store/company/company.slice';
import HoverTab from 'src/components/common/hoverTab/hoverTab';
import SuggestedListModal from 'src/components/common/suggestedListModal/suggestedListModal';
import { Dropdown } from 'react-bootstrap';

const tabItems: TTabItem[] = [
    {
        key: 'category',
        text: 'Web',
        disabled: false,
        item: [{ text: 'Python' }, { text: 'Angular' }]
    },
    {
        key: 'category2',
        text: 'App',
        disabled: false,
        item: [{ text: 'Java' }]
    },
    {
        key: 'category3',
        text: 'IOS',
        disabled: false,
        item: [{ text: 'Angular' }, { text: 'Android' }]
    },
    {
        key: 'category4',
        text: 'Python',
        disabled: false,
        item: [{ text: 'Java' }]
    },
    {
        key: 'category4',
        text: 'Python',
        disabled: false,
        item: [{ text: 'Java' }]
    },
    {
        key: 'category4',
        text: 'Python',
        disabled: false,
        item: [{ text: 'Java' }]
    },
    {
        key: 'category4',
        text: 'Python',
        disabled: false,
        item: [{ text: 'Java' }]
    },
    {
        key: 'category4',
        text: 'Python',
        disabled: false,
        item: [{ text: 'Java' }]
    }
];
export default function MarketPlace() {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const [currentTab, setCurrentTab] = useState<string>(tabItems[0].key)
    return (
        <InfiniteScroll
            className="view-program"
            onScrollEnd={() => { }}
            loading={false}
            moreItemsLeft={false}
        >
            <Header />
            <div className={'view-program__body'}>
                <div>
                    <HoverTab items={tabItems} activeItem={currentTab} setCurrentTab={setCurrentTab} />
                </div>
                <MarketPlaceSection />
            </div>
        </InfiniteScroll>
    )
}
