import * as Yup from 'yup';

const onBoardCohortValidationSchema = Yup.object().shape({
    cohortId: Yup.string().optional(),
    onboardingTo: Yup.string().required('This field is required'),
    cohortTeamsTitle: Yup.string().required('This field is required'),
    profilePic: Yup.string().optional(),
    teamMemberIds: Yup.array().of(
        Yup.object().shape({
            id: Yup.string().uuid().required('This field is required'),
            role: Yup.string().optional()
        })
    ).required('Team member IDs are required')
});

export default onBoardCohortValidationSchema;
