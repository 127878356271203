import { ChangeEvent, useRef, useState } from 'react';
import classNames from 'classnames';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { textFormatter } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId, openPopup, resetDropdownId } from 'src/store/common/common.slice';

import './manageProfilePicture.scss';

import SubMenuModal from '../../subMenuModal/SubMenuModal';
import { EditIcon } from '../../common.icons';
import DropdownOptionCard from '../../dropdownOptionCard/DropdownOptionCard';
import Loader from '../../loader/Loader';

export type TManageProfilePicture = {
  profilePic: string;
  name: string;
  isSelfUser: boolean;
  uploadFunc: ({ data }: { data: string | ArrayBuffer | null }) => void;
  deleteFunc: () => void;
};

/* eslint-disable no-unused-vars */
enum ImageFileTypes {
  BMP = 'bmp',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png'
}

const manageProfilePictureDropdownId = 'manageProfilePictureDropdown';

const ManageProfilePicture = ({
  profilePic,
  name,
  isSelfUser,
  uploadFunc,
  deleteFunc
}: TManageProfilePicture) => {
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const { dropdownId } = useAppSelector((store) => store.common);
  const [loading, setLoading] = useState<boolean>(false)

  const openPictureDropdown = () => {
    if (dropdownId) {
      dispatch(resetDropdownId());
    } else {
      dispatch(assignSubMenuModalId(manageProfilePictureDropdownId));
    }
  };

  const openFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const handleUploadProfilePicture = async (e: ChangeEvent<HTMLInputElement>) => {
    const file: File = (e.target.files as FileList)[0];
    if (file) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      const { name } = file;
      const fileType = name.split('.').pop() as unknown as 'bmp' | 'jpg' | 'jpeg' | 'png';

      const selectedImageDetail = {
        type: fileType
      };

      const isImageFileType = Object.keys(ImageFileTypes).includes(
        selectedImageDetail.type.toUpperCase()
      );

      if (isImageFileType) {
        // upload image
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
          const data = reader.result;

          uploadFunc({ data });
        };
      } else if (!isImageFileType) {
        dispatch(
          openPopup({
            popupMessage: 'Image should be of type BMP, JPG, JPEG, or PNG',
            popupType: 'error'
          })
        );
      }
    }
    // Resetting the value of target
    const element = e.target as HTMLInputElement;
    element.value = '';
  };

  return (
    <div className="manage-profile-picture">
      {!loading && <ProfilePicture url={profilePic} fallback={textFormatter({ value: name[0] })} />}
      {loading && <Loader />}
      {isSelfUser && (
        <div
          className="manage-profile-picture__edit"
          onClick={(e) => {
            e.stopPropagation();
            openPictureDropdown();
          }}
        >
          <EditIcon />
        </div>
      )}
      {!loading && <SubMenuModal>
        <div
          data-modal="profile-card"
          className={classNames('manage-profile-picture__options', {
            'manage-profile-picture__options--is-open':
              dropdownId === manageProfilePictureDropdownId
          })}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(assignSubMenuModalId(manageProfilePictureDropdownId));
          }}
        >
          <DropdownOptionCard size="large" onClick={openFileManager}>
            Upload
          </DropdownOptionCard>
          <DropdownOptionCard size="large" onClick={deleteFunc}>
            Delete
          </DropdownOptionCard>
        </div>
      </SubMenuModal>}
      <input
        type="file"
        hidden
        data-testid="inputUploadImage"
        ref={uploadImageRef}
        accept=".bmp,.jpg,.jpeg,.png"
        onChange={handleUploadProfilePicture}
      />
    </div>
  );
};

export default ManageProfilePicture;
