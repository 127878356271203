import React from 'react'
import ServiceSection from './ServiceSection/ServiceSection';
import cx from './marketplace.module.scss';
import TopCompanies from './TopCompanies/TopCompanies';
import UpcomingEvents from './UpcomingEvents/UpcomingEvents';
import TopProduct from './TopProduct/TopProduct';
import { useNavigate } from 'react-router-dom';

export default function MarketPlaceSection() {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/all')
  }
  return (
    <div className={`${cx.sections}`}>
      <div className={`${cx.section}`}>
        <div className={`${cx.headBox}`}>
          <h5 className={`${cx.sectionHeading}`}>Top Companies</h5>
          <span onClick={handleNavigate}>See All</span>
        </div>
        <TopCompanies />
      </div>
      <div className={`${cx.section}`}>
        <div className={`${cx.headBox}`}>
          <h5 className={`${cx.sectionHeading}`}>Services</h5>
          <span onClick={handleNavigate}>See All</span>
        </div>
        <ServiceSection />
      </div>
      <div className={`${cx.section}`}>
        <div className={`${cx.headBox}`}>
          <h5 className={`${cx.sectionHeading}`}>Top Product</h5>
          <span onClick={handleNavigate}>See All</span>
        </div>
        <TopProduct />
      </div>
      <div className={`${cx.section}`}>
        <div className={`${cx.headBox}`}>
          <h5 className={`${cx.sectionHeading}`}>Upcoming Events</h5>
          <span onClick={handleNavigate}>See All</span>
        </div>
        <UpcomingEvents />
      </div>
    </div>
  )
}
