import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import { changeCreateCohortModalState, openPopup } from 'src/store/common/common.slice';
import { useGetPlaces } from 'src/hooks';

import './crateCohort.scss';
import createCohortValidationSchema from './createCohortValidationSchema';
import { useNavigate } from 'react-router-dom';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { Col, Row } from 'react-bootstrap';
import { httpRequest } from 'src/services/commonApis';
import { cohortList, createCohort, getCohort, updateCohort } from 'src/store/cohort/cohort.slice';
import { disablePastDate } from 'src/utils/DisablePastDate/disablePasteDate';
import { TCohort } from 'src/services/apiEndpoint.types.ts/cohort/cohort.types';

export type TCreateCohortValues = {
    incubatorId: string,
    cohortTitle: string,
    startDate: string,
    endDate: string,
    cohortId?: string,
    programObjective?: string,
    templates?: {
        investmentTracking?: boolean,
        kpiTracking?: boolean
    }
};

const initialValues: TCreateCohortValues = {
    incubatorId: '',
    cohortTitle: '',
    startDate: '',
    endDate: '',
    programObjective: '',
    templates: {
        investmentTracking: false,
        kpiTracking: false
    }
};

const CreateCohortModal = ({ companyId }: { companyId: string }) => {
    const isModalOpen: { state: boolean, id?: string } = useAppSelector((store: any) => store.common.isCohortOpen);
    console.log(!isModalOpen.id, 'isModalOpen.id');
    const { cohorts, globalLoading }: { cohorts: TCohort[], globalLoading: string } = useAppSelector((store: any) => store.cohort);
    const [initialCohort, setInitialCohort] = useState<TCreateCohortValues | TCohort>(initialValues)
    // Hooks
    const dispatch = useAppDispatch();

    const handleSubmit = async (values: TCreateCohortValues) => {
        const {
            cohortTitle,
            startDate,
            endDate,
            programObjective,
            templates
        } = values;

        const payload: TCreateCohortValues = {
            incubatorId: companyId,
            cohortTitle,
            startDate,
            endDate,
            templates
        };

        if (isModalOpen.id) {
            dispatch(updateCohort({ ...payload, cohortId: isModalOpen.id })).then((response) => {
                if (response?.status) {
                    closeModal()
                }
            })
        } else {
            dispatch(createCohort(payload)).then((response) => {
                if (response?.status) {
                    closeModal()
                }
            })
        }
    };
    // useEffect(() => {
    //     if (isModalOpen?.id) {
    //         dispatch(getCohort({ cohortId: isModalOpen?.id, incubatorId: companyId }))
    //     }
    // }, [])
    useEffect(() => {
        if (isModalOpen.id) {
            const cohort = cohorts?.find((cohort: TCohort) => cohort.id === isModalOpen.id)
            setInitialCohort({ ...cohort, programObjective: cohort?.programObjective || '' })
        } else {
            setInitialCohort(initialValues)
        }
    }, [isModalOpen.id])

    const closeModal = () => {
        dispatch(changeCreateCohortModalState({ state: false, id: '' }));
    };

    return (
        <Modal
            isModalOpen={isModalOpen.state}
            onModalClose={closeModal}
            title="Create Cohort"
            className="edit-cohort-modal"
        >
            <div className="edit-cohort">
                {/* Form starts */}
                <Formik
                    initialValues={initialCohort}
                    onSubmit={handleSubmit}
                    validationSchema={createCohortValidationSchema}
                    enableReinitialize
                >
                    {({ setFieldValue, values, errors, touched }) => {
                        return (
                            <Form className="edit-cohort__form" noValidate>
                                <div className='edit-cohort__form__container-cohort'>
                                    <InputField
                                        required
                                        label="Cohort Title"
                                        id="cohortTitle"
                                        value={values?.cohortTitle}
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder="Cohort Title"
                                        type="text"
                                    />
                                    <InputField
                                        required
                                        label="Start Date"
                                        min={disablePastDate()}
                                        id="startDate"
                                        value={values?.startDate}
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder=""
                                        type="date"
                                    />

                                    <InputField
                                        required
                                        min={values.startDate || disablePastDate()}
                                        label="End Date"
                                        value={values?.endDate}
                                        id="endDate"
                                        variant={componentSizeVariantsEnum.SMALL}
                                        placeholder=""
                                        type="date"
                                    />
                                    <h5 className='typography__caption typography__semiBold'>Program Objective</h5>
                                    <TextArea id='programObjective' placeholder='Program Objective'
                                        value={values?.programObjective} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue('programObjective', e.target.value)}
                                        textAreaStyle={{ width: '100%' }}
                                    />
                                    <h5 className='typography__caption typography__semiBold'>Add templates</h5>
                                    <Row style={{ marginBottom: '16px' }}>
                                        <Col md={3} xs={6} sm={6}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={values?.templates?.investmentTracking}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('templates', { ...values?.templates, investmentTracking: e.target.checked })}
                                                />
                                                <h5 className='typography__caption typography__semiBold'>Investment Tracking</h5>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={6} sm={6}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    disabled
                                                    checked={values?.templates?.kpiTracking}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('templates', { ...values?.templates, kpiTracking: e.target.checked })}
                                                />
                                                <h5 className='typography__caption typography__semiBold'>KPI Tracking</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="edit-cohort__form__buttons">
                                    <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                                        Close
                                    </Button>

                                    <Button variant="primary" type="submit" size="small" disabled={globalLoading === 'cohort'}>
                                        {globalLoading === 'cohort' ? <Loader /> : `${isModalOpen?.id ? 'Update' : 'Create'} Cohort`}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik >
            </div >
        </Modal >
    );
};

export default CreateCohortModal;
