export const optionsSlider1 = {
    loop: false,
    items: 4,
    dots: false,
    nav: true,
    autoplay: false,
    // autoWidth: true,
    responsive: {
        0: {
            items: 1
        },
        480: {
            items: 1
        },
        768: {
            items: 3
        },
        992: {
            items: 4
        }
    }
}
