import React from 'react'
import { Loader, Typography } from 'src/components/common'
import FileCard from '../../fileCard/FileCard';
import { formatDate } from 'src/utils';
import convertTo12HourFormat from 'src/utils/convertTo12Hours/convertTo12Hours';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TGetProgramDocumentRequest } from 'src/services/apiEndpoint.types.ts';
import { getProgramDocument } from 'src/store/program/program.slice';
import cx from './commonDetails.module.scss'
interface TCommonDetailProps {
    detailFields: { label: string; value: string }[]
    applicationDetails?: any
}
export default function CommonDetail({ detailFields, applicationDetails = [] }: TCommonDetailProps) {
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector((state) => state.common)
    const getDocument = ({ key }: { key: string }) => {
        const payload: TGetProgramDocumentRequest = { s3Key: key };
        dispatch(getProgramDocument(payload));
    };
    if (loading) {
        return <Loader />
    }

    return (
        <div className={cx.detatils}>
            <div className="view-program-program-details position-relative">
                <div className='view-program-program-details flex' >
                    {detailFields.map(({ label, value }, index) => {
                        if (label === 'Banner') {
                            return (
                                <div key={index} className="view-program-program-details__field">
                                    <Typography
                                        className="view-program-program-details__field__label"
                                        as="p"
                                        variant="body-2"
                                        fontWeight="semiBold"
                                        style={{ margin: '0' }}
                                    >
                                        {label}
                                    </Typography>
                                    <div style={{ borderRadius: '0px', overflow: 'hidden' }}>
                                        <img src={value} alt="banner" style={{ height: '150px', width: '600px', backgroundPosition: 'center', objectFit: 'cover' }} />
                                    </div>
                                </div>
                            )
                        }
                        if (label === 'Description' || label === 'Objective') {
                            return (
                                <div key={index} className="view-program-program-details__field">
                                    <Typography
                                        className="view-program-program-details__field__label"
                                        as="p"
                                        variant="body-2"
                                        fontWeight="semiBold"
                                        style={{ margin: '0' }}
                                    >
                                        {label}
                                    </Typography>
                                    <Typography
                                        className="view-program-program-details__field__value"
                                        as="p"
                                        variant="caption"
                                        fontWeight="regular"
                                        dangerouslySetInnerHTML={{ __html: value }}
                                    />
                                </div>
                            )
                        }
                        return (
                            <div key={index} className="view-program-program-details__field">
                                {label && (
                                    <Typography
                                        className="view-program-program-details__field__label"
                                        as="p"
                                        variant="body-2"
                                        fontWeight="semiBold"
                                        style={{ margin: '0' }}
                                    >
                                        {label}
                                    </Typography>
                                )}
                                <Typography
                                    className="view-program-program-details__field__value"
                                    as="p"
                                    variant="caption"
                                    fontWeight="regular"
                                    style={{ margin: '0' }}
                                >
                                    {value}
                                </Typography>
                            </div>
                        )
                    })}
                    {applicationDetails?.map(({ label, values, type, files }, index) => (
                        <div key={index} className="view-program-program-details__field">
                            <Typography
                                className="view-program-program-details__field__label"
                                as="p"
                                variant="body-2"
                                fontWeight="semiBold"
                                style={{ margin: '0' }}
                            >
                                {label}
                            </Typography>
                            {type === 'fileUpload' && files && files?.length > 0 && (
                                <div className="view-program-program-details__field__files">
                                    {files.map((file) => {
                                        return (
                                            <FileCard
                                                fileName={file?.fileName}
                                                fileSize={file?.fileSize}
                                                key={file?.key}
                                                type='preview'
                                                isDownload={true}
                                                onClick={() => {
                                                    getDocument({ key: file?.key });
                                                }} removeFile={undefined} />
                                        );
                                    })}
                                </div>
                            )}
                            {type !== 'fileUpload' && (
                                <Typography
                                    className="view-program-program-details__field__value"
                                    as="p"
                                    variant="caption"
                                    fontWeight="regular"
                                    style={{ margin: '0' }}
                                >
                                    {type === 'dateSelection' ? (
                                        formatDate({ dateString: values })
                                    ) : type === 'timeSelection' ? (
                                        convertTo12HourFormat(values)
                                    ) : (
                                        <div
                                            className="view-program-program-details__field__value_editor"
                                            dangerouslySetInnerHTML={{ __html: values }}

                                        />
                                    )}
                                </Typography>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
