import React, { useEffect, useState } from 'react'
import { PlusIconCircle } from 'src/components/common/common.icons';
import cx from './resources.module.scss';
import { Col, Row } from 'react-bootstrap';
import UploadedResourceBox from './UploadedResourceBox/UploadedResourceBox';
import { useUrlParamValue } from 'src/hooks';
import { httpRequest } from 'src/services/commonApis';
import { APIResponse } from 'src/services/base.api.service';
import { TUploadCompanyProfilePictureResponse } from 'src/services/apiEndpoint.types.ts';
import { CompanyAPIService } from 'src/services/company.service';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch, useSelector } from 'react-redux';
import { cohortResources } from 'src/store/cohort/cohort.slice';
import { AppDispatch } from 'src/store/store';
import { Button, Loader } from 'src/components/common';
import handleExtension from 'src/utils/handleDocumentNameExtension/handleDocumentNameExtension';

interface TResourceProp {
  handleAction: (screen: string) => void
}
export default function Resources({ handleAction }: TResourceProp) {
  const { paramValue } = useUrlParamValue();
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const { cohortResouces } = useSelector((state: any) => state.cohort)
  const { loading } = useSelector((state: any) => state?.common)
  const dispatch = useDispatch<AppDispatch>();
  const [uploadLoading, setUploadingLoading] = useState<boolean>(false)
  async function handleResourceUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const files = e.target.files
      if (files?.length > 0) {
        for (const file of files) {
          const fileName = handleExtension(file?.name)
          const fileType = file?.type;
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = async () => {
            try {
              setUploadingLoading(true)
              const data = reader.result;
              const uploadProfilePicturePayload: any = {
                id: cohortId,
                data,
                showPopup: false
              };
              const response = await httpRequest(`cohort/uploads?cohortId=${uploadProfilePicturePayload.id}&resourceName=${fileName}&docCategory=resources`, 'GET', null, 'json', 'program');
              if (response?.data?.signedUrl) {
                const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
                  await new CompanyAPIService().uploadCompanyProfilePicture({
                    presignedUrl: response?.data?.signedUrl,
                    data,
                    includeAuthorizationHeaders: false
                  });
                if (res.status === 200) {
                  dispatch(cohortResources({ cohortId }))
                  dispatch(
                    openPopup({
                      popupMessage: 'resource uploaded successfully',
                      popupType: 'success'
                    })
                  );
                } else {
                  dispatch(
                    openPopup({
                      popupMessage: 'resource is not uploaded',
                      popupType: 'error'
                    })
                  );
                }
                console.log(res, 'Upload Company Profile Picture Response');
              } else {
                console.error('No signed URL returned in response');
              }
            } catch (error) {
              console.error('Error in file upload process', error);
            } finally {
              setUploadingLoading(false)
            }
          }
        }
      }
    }
    setUploadingLoading(false)
  }

  useEffect(() => {
    if (cohortId) {
      dispatch(cohortResources({ cohortId }))
    }
  }, [cohortId])
  if (loading) {
    return <Loader />
  }
  return (
    <div className={`${cx.resources}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>Resources</h5>
        <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={() => handleAction('relationship')}
        >
          Back
        </Button>
      </div>
      <div className={cx.resourceContainer}>
        <div className='insideCard'>
          <Row>
            {/* <Col md={3}>
              <div className={cx.imageUploadBox}>
                <div className={cx.uploadContent}>
                  {<label htmlFor='upload' className={cx.uploadLabel}>
                    <input className={cx.uploadInput} multiple onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleResourceUpload(e)} type='file' id='upload' accept='' />
                    {uploadLoading ? <Loader /> : <PlusIconCircle />}
                    <h5 className={cx.uploadTitle}>Upload Resources</h5>
                  </label>}
                </div>
              </div>
            </Col> */}
            {cohortResouces?.map((resourceData: any, i: number) => {
              return (
                <UploadedResourceBox key={i} resourceData={resourceData} />
              )
            })}
          </Row>
        </div>
      </div>
    </div >
  )
}
