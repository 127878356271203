import Typography from 'src/components/common/typography/Typography';
import { Role } from 'src/constants/user.constants';

import './about.scss';
import AboutMe, { TAboutMe } from './aboutMe/AboutMe';
import ContactInfo, { TContactInfo } from './contactInfo/ContactInfo';
import AdditionalInformation, {
  TAdditionalInformation
} from './additionalInformation/AdditionalInformation';

export type TAbout = { role: string } & TContactInfo & TAboutMe & TAdditionalInformation;

const About = ({
  about,
  address,
  dob,
  email,
  gender,
  linkedin,
  facebook,
  twitter,
  instagram,
  phoneNumber,
  role,
  userExpertise,
  userIndustries,
  country,
  state,
  city,
  zipCode
}: TAbout) => {
  const showAdditionalInformation =
    role === Role.INVESTOR || role === Role.MENTOR || role === Role.MENTOR_INVESTOR;

  return (
    <div className="my-profile-about">
      <Typography
        as="h5"
        variant="subHeading-2"
        fontWeight="semiBold"
        className="my-profile-about__heading"
      >
        About
      </Typography>{' '}
      {/*
       ** About Me
       */}
      <AboutMe about={about} />
      {/*
       ** Contact Info
       */}
      <ContactInfo
        address={address}
        dob={dob}
        email={email}
        gender={gender}
        linkedin={linkedin}
        facebook={facebook}
        twitter={twitter}
        instagram={instagram}
        phoneNumber={phoneNumber}
        country={country}
        state={state}
        city={city}
        zipCode={zipCode}
      />
      {/*
       ** Additional info
       */}
      {showAdditionalInformation && (
        <AdditionalInformation userExpertise={userExpertise} userIndustries={userIndustries} />
      )}
    </div>
  );
};

export default About;
