import React, { useEffect, useState } from 'react';
import { BackNavigation, Loader, Typography } from 'src/components/common';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import {
  ApplicationFormStep,
  TApplicationFormStepSectionItem,
  TopNavigation
} from '../applyToProgramExternal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import {
  createSurveyResponse,
  getPublicEvent,
  getPublicEventSuccess,
  getPublicServey,
  getPublicSurveySuccess,
  getUploadMedia
} from 'src/store/cohort/cohort.slice';
import CommonDetail from '../CohortsCompoents/CommonDetail/CommonDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TUploadApplicationFileAction } from 'src/services/apiEndpoint.types.ts';
import { getUploadApplicationFileExternalUser } from 'src/store/externalProgram/externalProgram.slice';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import ApplicationSuccessModal, {
  applicationSuccessModalId
} from '../applyToProgramExternal/applicationSuccessModal/ApplicationSuccessModal';
import { InputField } from 'src/components/common/formFields';
import { Col, Row } from 'react-bootstrap';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import handleExtension from 'src/utils/handleDocumentNameExtension/handleDocumentNameExtension';
import { formatDate } from 'src/utils';
import convertTo12HourFormat from 'src/utils/convertTo12Hours/convertTo12Hours';
const initialData = {
  name: '',
  email: '',
  errors: { name: '', email: '' }
};
export default function PublicSurveyReportForm() {
  const { paramValue } = useUrlParamValue();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const { publicServey, globalLoading, publicEvent } = useAppSelector((state) => state.cohort);
  const id: string = paramValue({ paramName: 'id' }) as string;
  const type: string = paramValue({ paramName: 'type' }) as string;
  const modalId: string = useAppSelector((store: any) => store.common.modalId);
  const isApplicationSuccessModalOpen = modalId === applicationSuccessModalId;
  const [userData, setUserData] = useState(initialData);
  const { loggedInUserId } = getIdentities();
  const details =
    publicEvent || publicServey
      ? [
          {
            label: type === 'event' ? 'Event Name' : `${publicServey?.type} Name`,
            value: type === 'event' ? publicEvent.title : publicServey?.title
          }
        ]
      : [];
  if (publicServey?.objective) {
    details.push({
      label: 'Objective',
      value: publicServey?.objective
    });
  }
  if (type === 'event') {
    const eventItemsArr = [
      {
        label: 'Start Date',
        value: `${formatDate({ dateString: publicEvent?.startDate })} ${convertTo12HourFormat(publicEvent?.startTime)}`
      },
      {
        label: 'End Date',
        value: `${formatDate({ dateString: publicEvent?.endDate })} ${convertTo12HourFormat(publicEvent?.endTime)}`
      }
    ];
    if (publicEvent?.banner) {
      eventItemsArr.push({
        label: 'Banner',
        value: publicEvent?.banner?.url
      });
    }
    if (publicEvent?.eventDescription) {
      eventItemsArr.push({
        label: 'Description',
        value: publicEvent?.eventDescription
      });
    }
    details.push(...eventItemsArr);
  }

  useEffect(() => {
    if (id) {
      if (type === 'event') {
        dispatch(getPublicEvent({ id }));
      } else {
        dispatch(getPublicServey({ id }));
      }
    }

    return () => {
      dispatch(getPublicEventSuccess(null));
      dispatch(getPublicSurveySuccess(null));
    };
  }, [id]);
  const sectionItems = publicServey?.applicationForm?.sectionItems || [];
  const handleNext = () => {};

  const handleApplyToProgram = ({
    sectionIndex,
    sectionTitle,
    sectionResponses,
    selectedFiles
  }: {
    sectionIndex: number;
    sectionTitle: string;
    sectionResponses: TApplicationFormStepSectionItem[];
    selectedFiles: any;
  }) => {
    if (userData.name === '') {
      setUserData({ ...userData, errors: { ...userData.errors, name: 'Name is required' } });
      return
    }
    const currentSections = JSON.parse(JSON.stringify(sectionResponses));

    currentSections[sectionIndex] = { sectionTitle, sectionItems: sectionResponses };

    const combinedFiles = { ...selectedFiles };
    let payload: any = {
      serveyId: id,
      cohortId: publicServey.cohortId,
      response: { sections: currentSections }
    };
    if (loggedInUserId) {
      payload = { ...payload, userId: loggedInUserId };
    }
    if (userData.name) {
      payload = { ...payload, name: userData.name };
    }
    dispatch(createSurveyResponse(payload)).then((response: any) => {
      if (response?.status === 200) {
        setUserData(initialData);
        Object.keys(combinedFiles).forEach((key) => {
          try {
            const files = combinedFiles[key];

            files.forEach((file: any) => {
              const fileBytes: any = file.data;
              if (fileBytes.byteLength > 0) {
                const payload: TUploadApplicationFileAction = {
                  cohortId: publicServey?.cohortId,
                  surveyId: id,
                  resourceName: handleExtension(file?.fileName),
                  id: file?.key,
                  data: file?.data || ''
                };

                dispatch(getUploadMedia(payload));
              }
            });
          } catch (e) {
            console.log(e);
          }
        });

        dispatch(assignModalId(applicationSuccessModalId));
      }
    });
  };
  const updateApplicantSectionResponse = ({
    sectionIndex,
    sectionTitle,
    sectionResponses,
    selectedFiles
  }: {
    sectionIndex: number;
    sectionTitle: string;
    sectionResponses: TApplicationFormStepSectionItem[];
    selectedFiles: any;
  }) => {
    console.log(sectionResponses, selectedFiles, 'selectedFiles');
  };
  const goToApplicationHome = () => {};
  const handleCloseApplicationSuccessModal = () => {
    dispatch(resetModalId());
  };

  if (globalLoading === 'response') {
    return <Loader />;
  }
  return (
    <>
      {isApplicationSuccessModalOpen && (
        <ApplicationSuccessModal
          isModalOpen={isApplicationSuccessModalOpen}
          handleCloseModal={handleCloseApplicationSuccessModal}
          ApplicationDraft={false}
          type="survey"
        />
      )}
      <div className="apply-to-program-external">
        <TopNavigation />
        <div className="apply-to-program-external__body">
          <div className="apply-to-program-external__body__step">
            <div className="apply-to-program-external__body__step__component">
              <div className="apply-to-program-external__body__step__component__header">
                <Typography
                  variant="subHeading-2"
                  fontWeight="semiBold"
                  style={{ color: colors.neutral[1100] }}
                >
                  {`${type === 'event' ? 'Event' : publicServey?.type || ''} Details`}
                </Typography>
              </div>
              <CommonDetail
                detailFields={details}
                applicationDetails={publicEvent?.applicationForm || []}
              />
            </div>
          </div>
          {type !== 'event' && (
            <div className="apply-to-program-external__body__step__component">
              <div className="apply-to-program-external__body__step__component__header">
                <Typography
                  variant="subHeading-2"
                  fontWeight="semiBold"
                  style={{ color: colors.neutral[1100] }}
                >
                  {`${publicServey?.type || ''} Form`}
                </Typography>
              </div>
              <Row>
                <Col md={6}>
                  <InputField
                    label="Name"
                    id="name"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Name"
                    type="text"
                    value={userData.name}
                    onChange={(e) => {
                      setUserData({ ...userData, name: e.target.value });
                    }}
                    required
                  />
                  {userData.errors.name !== '' && userData.name === '' && (
                    <ErrorMessage message={userData.errors.name} />
                  )}
                </Col>
              </Row>
              {sectionItems?.length > 0 && (
                <div>
                  <ApplicationFormStep
                    sectionIndex={0}
                    key={`${publicServey?.applicationForm?.sectionTitle}`}
                    sectionTitle={`${publicServey?.applicationForm?.sectionTitle}`}
                    sectionItems={sectionItems}
                    handleNext={handleNext}
                    finalStep={true}
                    updateApplicantSectionResponse={updateApplicantSectionResponse}
                    handleApplyToProgram={handleApplyToProgram}
                    goToApplicationHome={goToApplicationHome}
                    type="survey"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
