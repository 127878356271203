import { Card, Typography } from 'src/components/common';
import { FC } from 'react';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';

import './personCard.scss';
import { pendingLogo } from 'src/assets/images';
import { useNavigate } from 'react-router-dom';
import { useGetIdentity } from 'src/hooks';

export type TPeopleCard = {
  person: {
    id: string;
    name: string;
    email: string;
    profilePic: string;
    role: string;
    isOwner?: boolean;
    IdeaCollaboratorMapping?: { collaborationType?: string, status?: string }
  };
  showEmail?: boolean;
};

const PersonCard: FC<TPeopleCard> = ({ person }) => {
  const navigate = useNavigate()
  const { getIdentities } = useGetIdentity();
  const { loggedInUserId } = getIdentities();
  let isCoFounder = false;
  const isOwner = person?.isOwner;
  const statusPending = person?.IdeaCollaboratorMapping?.status ? person?.IdeaCollaboratorMapping?.status === 'Pending' : false
  // New requirement to show Co-founder or Owner in first row, followed by name

  try {
    isCoFounder =
      (
        person as unknown as {
          IdeaCollaboratorMapping: { collaborationType: string };
        }
      )?.IdeaCollaboratorMapping?.collaborationType === 'CoFounder';
  } catch { }
  function handleNavigate() {
    if (person?.id) {
      const path: string = loggedInUserId === person?.id ? '/my-profile' : `/view-profile?id=${person?.id}`
      navigate(path)
    }
  }
  return (
    <Card className="person-card">
      <ProfilePicture
        url={person.profilePic || ''}
        fallback={person?.name[0]}
        className="person-card__profile-pic"
        onClick={handleNavigate} style={{ cursor: 'pointer' }}
      />
      <div className="person-card__person-details">
        <div className="person-card__person-details__row">
          <Typography
            onClick={handleNavigate} style={{ cursor: 'pointer' }}
            as="span"
            variant="body-2"
            fontWeight="semiBold"
            className="person-card__person-details__row__name"
          >
            {person?.name}
          </Typography>

          {(isCoFounder || isOwner) && (
            <>
              <hr></hr>
              <Typography
                as="span"
                variant="caption"
                fontWeight="regular"
                className="person-card__person-details__row__role"
              >
                {isCoFounder ? 'Co-Founder' : 'Owner'}
              </Typography>
            </>
          )}
        </div>

        <Typography
          as="p"
          variant="caption"
          fontWeight="regular"
          className="person-card__person-details__email"
        >
          {person?.role}
        </Typography>
      </div>
      {statusPending && <img src={pendingLogo} title='pending' className='pendingStatusCard' alt="pending-logo" />}
    </Card>
  );
};

PersonCard.defaultProps = {
  showEmail: true
};

export default PersonCard;
