import { Loader, Modal, ProfilePicture, Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { changeCreateCohortModalState, openPopup } from 'src/store/common/common.slice';
import cx from './showAssined.module.scss';
import { useDispatch } from 'react-redux';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { changeCohortModalState, cohortAssignedMentor, handleUssignCohortMentor } from 'src/store/cohort/cohort.slice';
import { TAssignMentorList, TTeam } from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
import { dotMenu } from 'src/assets/images';
import { MdRemove } from 'react-icons/md';
import { useUrlParamValue } from 'src/hooks';
import { useEffect, useState } from 'react';
import { TrashIcon } from 'src/components/common/common.icons';
import TeamCard from './TeamCard';

const ShowAssignTeam = () => {
    const { paramValue } = useUrlParamValue();
    const modalData: { state: boolean, mentor: TAssignMentorList } = useAppSelector((store: any) => store.cohort.isTeamModalOpen);
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [assignedTeamdMentorData, setAssignedTeamdMentorData] = useState<null | TAssignMentorList>(null)
    const cohortId: string = paramValue({ paramName: 'id' }) as string;

    const closeModal = () => {
        dispatch(changeCohortModalState({ state: false, mentor: null }));
    };
    const teams: TTeam[] = assignedTeamdMentorData?.teams || []
    useEffect(() => {
        if (modalData.mentor) {
            setAssignedTeamdMentorData(modalData.mentor)
        }
    }, [modalData.mentor])
    return (
        <Modal
            isModalOpen={modalData?.state}
            onModalClose={closeModal}
            title="Assigned Teams"
            className={cx.assignTeamModal}
        >
            <div className={cx.assignTeam}>
                <Row>
                    {loading && <Loader />}
                    {!loading && teams?.length > 0 && teams?.map((team: TTeam, t: number) => {
                        return (
                            <TeamCard
                                key={t}
                                mentorId={modalData?.mentor?.mentor?.id}
                                team={team}
                                assignedTeamdMentorData={assignedTeamdMentorData}
                                setAssignedTeamdMentorData={setAssignedTeamdMentorData}
                                setLoading={setLoading}
                                loading={loading}
                            />
                        )
                    })}
                </Row>
            </div >
        </Modal >
    );
};

export default ShowAssignTeam;
