import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { APIResponse } from 'src/services/base.api.service';
import { ProfileAPIService } from 'src/services/profile.api.service';
import { StorageUtils } from 'src/utils';
import {
  TGetProfileRequest,
  TGetProfileResponse,
  TEditProfileRequest,
  TEditProfileResponse,
  TGetPresignedUrlRequest,
  TGetPresignedUrlResponse,
  TUploadProfilePictureRequest,
  TUploadProfilePictureResponse,
  TDeleteProfilePictureRequest,
  TDeleteProfilePictureResponse,
  TUpdateExperienceRequest,
  TUpdateExperienceResponse,
  TUpdateEducationRequest,
  TUpdateEducationResponse,
  TUpdatedExpertiseRequest,
  TUpdatedExpertiseResponse,
  TUpdatedIndustriesRequest,
  TUpdatedIndustriesResponse,
  TDeleteExperienceRequest,
  TDeleteExperienceResponse,
  TDeleteEducationRequest,
  TDeleteEducationResponse
} from 'src/services/apiEndpoint.types.ts';
import errorMessages from 'src/constants/error.messages.constants';

import {
  closePopup,
  hidePageLoader,
  openPopup,
  startLoading,
  stopLoading
} from '../common/common.slice';
import { AppDispatch } from '../store';
import { userAuthorizationFailure, userAuthorizationSuccess } from '../auth/auth.slice';

const profileInitialState = {
  id: '',
  email: '',
  name: '',
  role: '',
  gender: '',
  dob: '',
  address: '',
  country: '',
  state: '',
  city: '',
  zipCode: '',
  phoneNumber: '',
  linkedin: '',
  instagram: '',
  twitter: '',
  facebook: '',
  introduction: '',
  about: '',
  profession: '',
  experience: [],
  education: [],
  background: '',
  createdAt: '',
  updatedAt: '',
  deletedAt: '',
  profilePic: '',
  userIndustries: [],
  userExpertise: [],
  onboardingComplete: false
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profile: profileInitialState,
    viewUserProfile: profileInitialState
  },
  reducers: {
    getProfileSuccess: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    },
    getViewUserProfileSuccess: (state, action: PayloadAction<any>) => {
      state.viewUserProfile = action.payload;
    },
    /*
     ** Reset fields
     */
    resetFields: (state) => {
      state.profile.role = '';
      state.profile.email = '';
    },
    /*
     ** Reset fields
     */
    resetViewProfile: (state) => {
      state.viewUserProfile = profileInitialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { getProfileSuccess, getViewUserProfileSuccess, resetViewProfile, resetFields } =
  profileSlice.actions;

export default profileSlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;
  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] || errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const getProfile = (payload: TGetProfileRequest) => async (dispatch: AppDispatch) => {
  try {
    const { status, data }: APIResponse<TGetProfileResponse> =
      await new ProfileAPIService().getProfile(payload);
    if (status === 200) {
      dispatch(getProfileSuccess(data?.data));
      dispatch(userAuthorizationSuccess());
      dispatch(hidePageLoader());
    }
  } catch (err) {
    if (err?.code === 'ERR_NETWORK') {
      try {
        const { status, data }: APIResponse<TGetProfileResponse> =
          await new ProfileAPIService().getProfile(payload);
        if (status === 200) {
          dispatch(getProfileSuccess(data?.data));
          dispatch(userAuthorizationSuccess());
          dispatch(hidePageLoader());
        }
      } catch (err) { dispatch(userAuthorizationFailure()) }
    }
  } finally {
    dispatch(hidePageLoader());
  }
};

export const editProfile = (payload: TEditProfileRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status }: APIResponse<TEditProfileResponse> = await new ProfileAPIService().editProfile(
      payload
    );
    if (status === 200) {
      dispatch(
        openPopup({
          popupMessage: 'You have successfully updated your profile!',
          popupType: 'success'
        })
      );
      dispatch(getProfile({ id: payload.id }));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const uploadProfilePicture =
  (payload: TUploadProfilePictureRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status }: APIResponse<TUploadProfilePictureResponse> =
        await new ProfileAPIService().uploadProfilePicture({
          presignedUrl: payload.presignedUrl,
          data: payload.data,
          includeAuthorizationHeaders: false
        });
      if (status === 200) {
        const id = StorageUtils.get('userId') as unknown as number;
        dispatch(closePopup());
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated your profile picture!',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const getPresignedUrl =
  (payload: TGetPresignedUrlRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status, data }: APIResponse<TGetPresignedUrlResponse> =
        await new ProfileAPIService().getPresignedUrl({ email: payload.email });
      if (status === 200 && data?.data && payload?.data) {
        dispatch(uploadProfilePicture({ presignedUrl: data.data, data: payload.data }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const deleteProfilePicture =
  (payload: TDeleteProfilePictureRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status }: APIResponse<TDeleteProfilePictureResponse> =
        await new ProfileAPIService().deleteProfilePicture(payload);
      if (status === 200) {
        const id = StorageUtils.get('userId') as unknown as number;
        dispatch(
          openPopup({
            popupMessage: 'You have successfully deleted your profile picture!',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const addExperience =
  (payload: TUpdateExperienceRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TUploadProfilePictureResponse> =
        await new ProfileAPIService().updateExperience(payload);
      if (status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully added your experience!',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id: payload.id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateExperience =
  (payload: TUpdateExperienceRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TUpdateExperienceResponse> =
        await new ProfileAPIService().updateExperience(payload);
      if (status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated your experience!',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id: payload.id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const addEducation = (payload: TUpdateEducationRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status }: APIResponse<TUpdateEducationResponse> =
      await new ProfileAPIService().updateEducation(payload);
    if (status === 200) {
      dispatch(
        openPopup({
          popupMessage: 'You have successfully added your education!',
          popupType: 'success'
        })
      );
      dispatch(getProfile({ id: payload.id }));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const updateEducation =
  (payload: TUpdateEducationRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TUpdateEducationResponse> =
        await new ProfileAPIService().updateEducation(payload);
      if (status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated your education!',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id: payload.id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateExpertise =
  (payload: TUpdatedExpertiseRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TUpdatedExpertiseResponse> =
        await new ProfileAPIService().updateExpertise(payload);
      if (status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated your expertise!',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id: payload.id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateIndustries =
  (payload: TUpdatedIndustriesRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TUpdatedIndustriesResponse> =
        await new ProfileAPIService().updateIndustries(payload);
      if (status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated your industries!',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id: payload.id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteExperience =
  (payload: TDeleteExperienceRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TDeleteExperienceResponse> =
        await new ProfileAPIService().deleteExperience(payload);
      if (status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully deleted this work experience entry.',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id: payload.id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteEducation =
  (payload: TDeleteEducationRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TDeleteEducationResponse> =
        await new ProfileAPIService().deleteEducation(payload);
      if (status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully deleted this education entry.',
            popupType: 'success'
          })
        );
        dispatch(getProfile({ id: payload.id }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getViewUserProfile =
  (payload: TGetProfileRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetProfileResponse> =
        await new ProfileAPIService().getProfile(payload);
      if (status === 200) {
        dispatch(getViewUserProfileSuccess(data?.data));
        dispatch(hidePageLoader());
      }
    } catch (err) {
    } finally {
      dispatch(hidePageLoader());
      dispatch(stopLoading());
    }
  };
