import * as Yup from 'yup';

import { TApplicationFormStepSectionItem } from '../../applyToProgram/applicationFormStep/applicationFormStep.types';

const createYupSchema = ({ sectionItems }: { sectionItems: TApplicationFormStepSectionItem[] }) => {
  const shape: { [key: string]: any } = {};

  sectionItems.forEach((item) => {
    let validator: Yup.StringSchema | Yup.NumberSchema;

    const {
      id,
      type,
      required,
      validations: { maxCharacters }
    } = item;

    validator = Yup.string();

    if (type === 'label') {
      return;
    }

    const validateString = () => {
      if (required) {
        validator = validator.required('Please enter details in this field');
      }
      if (maxCharacters) {
        validator = validator.max(
          maxCharacters,
          `This field cannot be more than ${maxCharacters} characters long.`
        );
      }
      shape[id] = validator;
    };

    switch (type) {
      case 'multipleChoice': {
        if (required) {
          validator = validator.required('Please enter details in this field');

          shape[id] = validator;
        }

        break;
      }
      case 'dateSelection': {
        validateString();
        break;
      }
      case 'dropdown': {
        validateString();
        break;
      }
      case 'singleChoice': {
        validateString();
        break;
      }
      case 'textInput': {
        validateString();
        break;
      }
      case 'phoneNumber': {
        validateString();
        break;
      }
      case 'textEditor': {
        validateString();
        break;
      }
      case 'tableColumns': {
        validateString();
        break;
      }
      case 'timeSelection': {
        validateString();
        break;
      }
      case 'fileUpload': {
        if (required) {
          const validator = Yup.array().required('Please enter details in this field');
          shape[id] = validator;

          return;
        } else {
          return;
        }
      }
      default: {
        break;
      }
    }

    shape[id] = validator;
  });

  return Yup.object().shape(shape);
};

export default createYupSchema;
