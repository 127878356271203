import { Fragment, useState } from 'react';
import { Card, Separator } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { PlusIcon, TrashIcon } from 'src/components/common/common.icons';
import { useToggleOpenModal } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';
import Label from 'src/components/common/formFields/label/Label';

import { TCustomApplicationFormSectionProps } from './customApplicationFormSection.types';
import './customApplicationFormSection.scss';

import AddEditCustomApplicationFields from '../addEditCustomApplicationFields/AddEditCustomApplicationFields';
import { TFieldType } from '../../programDetails/programDetails.types';
import DynamicField from '../dynamicField/DynamicField';
import { TCustomApplicationSection } from '../customApplicationForm.types';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
export const changeApplicationFormFieldTypeId = 'changeApplicationFormFieldType';

const CustomApplicationFormSection = ({
  id: sectionId,
  sectionTitle,
  handleAddField,
  handleUpdateField,
  sectionItems,
  selectSectionForDeletion,
  handleChangeFieldType,
  setApplicationFormSections,
  selectedFieldForDeletion,
  updateSectionTitle,
  type = 'program',
  fields = [],
  errors
}: TCustomApplicationFormSectionProps) => {
  // Hooks
  const modalId: string = useAppSelector((store) => store.common.modalId);
  const toggleOpenModal = useToggleOpenModal();

  // State
  const [editFieldModalData, setEditFieldModalData] = useState<{
    id: string;
    fieldType: TFieldType;
  }>({ id: '', fieldType: 'label' });

  // Constants
  const addApplicationFormFieldId = `addApplicationFormField-${sectionId}`;
  const isAddApplicationFormFieldModalOpen = modalId === addApplicationFormFieldId;
  const isChangeApplicationFormFieldTypeModalOpen = modalId === changeApplicationFormFieldTypeId;

  const handleToggleOpenAddSectionModal = () => {
    toggleOpenModal({ modalId: addApplicationFormFieldId });
  };

  const handleToggleOpenChangeSectionFieldTypeModal = () => {
    toggleOpenModal({ modalId: changeApplicationFormFieldTypeId });
  };

  const handleSectionFieldSelection = ({
    fieldType,
    sectionId
  }: {
    fieldType: TFieldType;
    sectionId: string;
  }) => {
    handleAddField({ sectionId, fieldType });
    handleToggleOpenAddSectionModal();
  };

  const selectFieldForFieldTypeChange = ({
    id,
    fieldType
  }: {
    id: string;
    fieldType: TFieldType;
  }) => {
    setEditFieldModalData({ id, fieldType });
    handleToggleOpenChangeSectionFieldTypeModal();
  };
  const handleOnDragEndFields = (result) => {
    if (!result.destination) return;

    setApplicationFormSections((prevSections) => {
      const newSections = [...prevSections];
      const sectionIndex = newSections.findIndex((sec) => sec.id === sectionId);
      console.log(sectionIndex, 'sectionIndex')
      if (sectionIndex === -1) return prevSections;

      const fields = Array.from(newSections[sectionIndex].sectionItems);
      const [reorderedField] = fields.splice(result.source.index, 1);
      fields.splice(result.destination.index, 0, reorderedField);

      newSections[sectionIndex].sectionItems = fields;
      console.log(newSections, 'newSections')
      return newSections;
    });
  };

  return (
    <Card className="custom-application-form-section">
      {type !== 'cohort' && <>
        <div className="custom-application-form-section__heading">
          <Label required>Section title</Label>
          <Button
            size="extraSmall"
            variant="tertiary"
            startIcon={<TrashIcon />}
            className="custom-application-form-section__heading__delete"
            onClick={() => {
              selectSectionForDeletion({ sectionId });
            }}
          >
            Delete Section
          </Button>
        </div>
        <InputField
          id={sectionId}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Section title"
          type="text"
          className="custom-application-form-section__field"
          required
          value={sectionTitle}
          onChange={(e) => {
            updateSectionTitle({ sectionId, title: e.target.value });
          }}
          errorMessage={errors?.[`sectionTitle-${sectionId}`]}
          isTouched={true}
        />
      </>}
      <DragDropContext onDragEnd={handleOnDragEndFields}>
        <Droppable droppableId="sections">
          {(provided: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>

              {sectionItems.map((field: TCustomApplicationSection, index) => {
                return (

                  <Draggable key={field.id} draggableId={String(field.id)} index={index}>
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Fragment key={index}>
                          <DynamicField
                            {...field}
                            sectionId={sectionId}
                            handleUpdateField={handleUpdateField}
                            openChangeFieldModal={selectFieldForFieldTypeChange}
                            openDeleteFieldModal={selectedFieldForDeletion}
                            errors={errors}
                          />
                          <Separator />
                        </Fragment>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* Fields start */}
      {/* {sectionItems.map((field: TCustomApplicationSection, index) => {
        return (
          <Fragment key={index}>
            <DynamicField
              {...field}
              sectionId={sectionId}
              handleUpdateField={handleUpdateField}
              openChangeFieldModal={selectFieldForFieldTypeChange}
              openDeleteFieldModal={selectedFieldForDeletion}
              errors={errors}
            />
            <Separator />
          </Fragment>
        );
      })} */}
      {/* Fields end */}
      {/* Add field */}
      <Button
        size="small"
        variant="secondary"
        startIcon={<PlusIcon />}
        className="custom-application-form-section__add-field"
        onClick={handleToggleOpenAddSectionModal}
      >
        Fields
      </Button>{' '}
      {isAddApplicationFormFieldModalOpen && (
        <AddEditCustomApplicationFields
          isModalOpen={isAddApplicationFormFieldModalOpen}
          onModalClose={handleToggleOpenAddSectionModal}
          title="Add Fields"
          onSelection={handleSectionFieldSelection}
          sectionId={sectionId}
          mode="addField"
          customFields={fields}
        />
      )}
      {isChangeApplicationFormFieldTypeModalOpen && (
        <AddEditCustomApplicationFields
          isModalOpen={isChangeApplicationFormFieldTypeModalOpen}
          onModalClose={handleToggleOpenChangeSectionFieldTypeModal}
          title="Edit Fields"
          currentType={editFieldModalData.fieldType}
          id={editFieldModalData.id}
          sectionId={sectionId}
          onSelection={handleChangeFieldType}
          mode="updateField"
          customFields={fields}
        />
      )}
    </Card>
  );
};

export default CustomApplicationFormSection;
