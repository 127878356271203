import { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import EmojiPicker from 'emoji-picker-react';
import { useGetIdentity, useUploadMedia } from 'src/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import Button from 'src/components/button/Button';
import { ActionIcon } from 'src/components/common';
import { EmojiIcon, PlusIcon } from 'src/components/common/common.icons';
import { colors } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  TEditDirectMessageRequest,
  TGetChatFilePresignedUrlActionRequest,
  TSendDirectMessageRequest
} from 'src/services/apiEndpoint.types.ts';
import {
  clearChatFiles,
  editDirectMessage,
  getChatFilePresignedUrl,
  sendDirectMessage
} from 'src/store/chat/chat.slice';
import { openPopup } from 'src/store/common/common.slice';

import './chatMessagesFooter.scss';
import { TChatMessageInitialValues, TChatMessagesFooterProps } from './chatMessagesFooter.types';

import FileCard from '../../fileCard/FileCard';

const ChatMessagesFooter = ({
  name,
  selectedUserId,
  conversationId,
  editMessageSettings,
  updateMessageSettings,
  directMessageContext
}: TChatMessagesFooterProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { filesToUpload } = useAppSelector((store) => store.chat);
  const { getIdentities } = useGetIdentity();
  const { handleUploadMedia } = useUploadMedia();

  // State
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const uploadFileRef = useRef<HTMLInputElement>(null);
  const [messageId, setMessageId] = useState<string>(uuidv4());

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();
  const isEditMessageMode: boolean = editMessageSettings?.editMode;

  const initialValues: TChatMessageInitialValues = isEditMessageMode
    ? { message: editMessageSettings.message }
    : { message: '' };

  const turnEditModeOff = () => {
    updateMessageSettings({ editMode: false, messageId: '', message: '' });
  };

  const openImageFileManager = async () => {
    if (uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };

  const onMediaLoad = (
    data: string | ArrayBuffer | null,
    fileType: string,
    mediaType: string,
    name: string,
    size: number
  ) => {
    const fileId: string = uuidv4();

    const payload: TGetChatFilePresignedUrlActionRequest = {
      conversationId,
      messageId,
      id: fileId,
      fileName: name,
      fileData: data
    };
    dispatch(getChatFilePresignedUrl(payload));
  };

  const openEmptyMessagePopup = () => {
    dispatch(
      openPopup({
        popupMessage: 'You message is empty, Please type something before sending',
        popupType: 'error'
      })
    );
  };

  const handleSubmit = (
    values: TChatMessageInitialValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    const { message } = values;

    if (selectedUserId && conversationId) {
      if (isEditMessageMode) {
        const payload: TEditDirectMessageRequest = {
          eventName:
            directMessageContext === 'directMessage'
              ? 'edit-conversation-message'
              : 'edit-idea-conversation-message',
          messageId: editMessageSettings?.messageId,
          message
        };
        if (message?.trim()) {
          dispatch(editDirectMessage(payload));
          turnEditModeOff();
          resetForm();
        } else {
          openEmptyMessagePopup();
        }
      } else if (!isEditMessageMode) {
        const payload: TSendDirectMessageRequest = {
          userId: authenticatedId,
          userType: loggedInUserType,
          conversationId,
          eventName:
            directMessageContext === 'directMessage' ? 'conversation' : 'idea-conversation',
          message,
          messageId,
          files: filesToUpload
        };

        if (filesToUpload?.length > 0 || message?.trim()) {
          dispatch(sendDirectMessage(payload));
          resetForm();
          setMessageId(uuidv4());
          dispatch(clearChatFiles());
        } else {
          openEmptyMessagePopup();
        }
      }
    }
  };

  return (
    <div className="dm-chat-messages-footer">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ setFieldValue, values }) => {
          return (
            <Form className="dm-chat-messages-footer__form">
              <div className="dm-chat-messages-footer__form__row">
                <InputField
                  id="message"
                  placeholder={`Message to ${name}`}
                  value={values?.message}
                />
                <div className="dm-chat-messages-footer__form__row__actions">
                  {editMessageSettings.editMode && (
                    <Button
                      size="small"
                      variant="secondary"
                      type="button"
                      className="dm-chat-messages-footer__form__row__actions__cancel"
                      onClick={turnEditModeOff}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button size="small" variant="primary" type="submit">
                    {editMessageSettings.editMode ? 'Save' : 'Send'}
                  </Button>
                </div>
              </div>
              <div className="dm-chat-messages-footer__form__row__actions">
                <input
                  type="file"
                  hidden
                  data-testid="inputUploadImage"
                  ref={uploadFileRef}
                  accept=".bmp,.jpg,.jpeg,.png,.pdf,.mp4,.webm"
                  className="select-post-image"
                  multiple
                  onChange={(e) => {
                    handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
                  }}
                />
                {!editMessageSettings?.editMode && (
                  <ActionIcon onClick={openImageFileManager}>
                    <PlusIcon color={colors?.neutral?.[700]} />
                  </ActionIcon>
                )}
                <ActionIcon onClick={() => setIsEmojiPickerOpen((prev) => !prev)}>
                  <EmojiIcon />
                </ActionIcon>
              </div>{' '}
              <EmojiPicker
                open={isEmojiPickerOpen}
                onEmojiClick={(e) => {
                  setFieldValue('message', values.message + e.emoji);
                  setIsEmojiPickerOpen(false);
                }}
              />
              {filesToUpload?.length > 0 && !editMessageSettings?.editMode && (
                <div className="dm-chat-messages-footer__form__row__files">
                  {filesToUpload.map(({ id, fileName }) => (
                    <FileCard
                      key={id}
                      id={id}
                      fileName={fileName}
                      showDownloadButton={false}
                      showDeleteButton={true}
                    />
                  ))}
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChatMessagesFooter;
