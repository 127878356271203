export default function handleExtension(fileName:string) {
    if (!fileName) {
        return ''
    }
    const fileExtension = fileName.substring(fileName.lastIndexOf('.')); // Get the file extension, e.g., ".jpg"
    const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.')); // Get the file name without the extension
    // Add the timestamp before the file extension

    const timestamp = new Date().getTime();
    return `${fileNameWithoutExtension}-${timestamp}${fileExtension}`;
}
