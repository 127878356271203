import { Form, Formik } from 'formik';
import { useAppSelector } from 'src/store/hooks';
import Button from 'src/components/button/Button';
import { Card, Typography } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { cancelCreateProgramModalId } from 'src/pages/createProgram/CreateProgram';
import { useToggleOpenModal } from 'src/hooks';

import './review.scss';
import SectionReview from './sectionReview/SectionReview';
import { TReviewProps } from './review.types';

import { TApplicationFormSection } from '../customApplicationForm/customApplicationForm.types';

const Review = ({ handleSave, applicationFormSections, createProgramStep, type = 'create-application', handleDraft }: TReviewProps) => {
  // Hooks
  const loading: boolean = useAppSelector((store) => store.common.loading);
  const toggleOpenModal = useToggleOpenModal();

  const initialValues = {
    fullName: '',
    email: ''
  };
  const handleSaveClick = () => {
    localStorage.removeItem('applicationFormSections');
    localStorage.removeItem('programDetailsFormValues');
    // Call the parent handleSave if needed
    if (handleSave) {
      handleSave();
    }
  };

  // Section management
  const handleToggleOpenCancelCreateProgramModal = () => {
    localStorage.removeItem('applicationFormSections');
    localStorage.removeItem('programDetailsFormValues');
    toggleOpenModal({ modalId: cancelCreateProgramModalId });
  };

  return (
    <div className="custom-application-form">
      <Formik initialValues={initialValues} onSubmit={() => { }}>
        {() => {
          return (
            <Form className="custom-application-form__form">
              {' '}
              <div className="custom-application-form__form__header">
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  {type === 'viewApplication' ? 'Application Preview' : ""}
                </Typography>
                {type !== 'viewApplication' && <div className="custom-application-form__form__header__navigation">
                  <Button size="extraSmall" variant="primary" onClick={handleDraft} style={{ width: 'auto' }}>
                    Save as Draft & Exit
                  </Button>
                  <Button
                    size="extraSmall"
                    variant="secondary"
                    onClick={handleToggleOpenCancelCreateProgramModal}
                  >
                    Discard & Exit
                  </Button>
                  <Button
                    size="extraSmall"
                    variant="primary"
                    onClick={handleSaveClick}
                    // onClick={handleSave}
                    loading={loading}
                  >
                    Publish Program
                  </Button>
                </div>}
              </div>
              <Card className="custom-application-form__form__row">
                <Typography
                  as="p"
                  variant="body-1"
                  fontWeight="semiBold"
                  className="custom-application-form__form__row__heading"
                >
                  Personal Details
                </Typography>
                <div className="custom-application-form__form__row__fields">
                  <InputField
                    label="Full name"
                    id="fullName"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Full name"
                    type="text"
                    disabled
                    className="custom-application-form__form__row__fields__field"
                  />
                  <InputField
                    label="Email address"
                    id="email"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Email address"
                    type="text"
                    disabled
                    className="custom-application-form__form__row__fields__field"
                  />
                  <InputField
                    label="Mobile"
                    id="mobile"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="mobile"
                    type="text"
                    disabled
                    className="custom-application-form__form__row__fields__field"
                  />
                </div>
              </Card>
              {/* Sections start */}
              {applicationFormSections.map((section: TApplicationFormSection, index: number) => {
                return <SectionReview key={index} {...section} createProgramStep={createProgramStep} />;
              })}
              {/* Sections end */}
            </Form>
          );
        }}
      </Formik>{' '}
    </div>
  );
};

export default Review;
