import * as XLSX from 'xlsx';
import { httpRequest } from 'src/services/commonApis';

export const exportToExcel = async (applicants: any) => {
  if (!applicants || applicants.length === 0) {
    console.error('No applicants data available for export');
    return;
  }

  interface TFileMetadata {
    fileName: string;
    fileSize: number; // in bytes
    key: string; // path to the file
  }

  const stripHtmlTags = (html: string): string => {
    return html.replace(/<\/?[^>]+(>|$)/g, ' ').trim();
  };

  const data = [];
  const maxFileCount: { [key: string]: number } = {};

  // First pass: find the maximum number of files per section item label
  for (const applicant of applicants) {
    for (const section of applicant.response.sections) {
      for (const item of section.sectionItems) {
        if (item?.type === 'fileUpload' && Array.isArray(item.files)) {
          const key = `${item.label}.${section.sectionTitle}`;
          maxFileCount[key] = Math.max(maxFileCount[key] || 0, item.files.length);
        }
      }
    }
  }

  // Second pass: process the applicants and fill data
  for (const applicant of applicants) {
    const userData = {
      ID: applicant.User.id,
      Email: applicant.User.email,
      Name: applicant.User.name
    };

    const dynamicData: any = {};
    for (let k = 0; k < applicant.response.sections.length; k++) {
      const section = applicant.response.sections[k];
      dynamicData[`SectionTitle.${k + 1}`] = section.sectionTitle;

      for (let j = 0; j < section.sectionItems.length; j++) {
        const item = section.sectionItems[j];
        let itemResponse = item.response;

        if (item?.type === 'textEditor') {
          itemResponse = stripHtmlTags(item.response);
        }

        const key = `${item.label}.${k + 1}.${j + 1}`;
        if (item?.type === 'fileUpload' && Array.isArray(item.files)) {
          const fileKeys = item.files.map((file: TFileMetadata) => file.key);
          const response = await httpRequest('program/docs/get', 'POST', { s3KeyArray: fileKeys }, 'json', 'program');

          // if (response && Array.isArray(response.data)) {
          //   response.data.forEach((fileUrl: { presignedUrl: string }, fileIndex: number) => {
          //     const fileName = item.files[fileIndex]?.fileName || `File ${fileIndex + 1}`;
          //     dynamicData[`${item.label}.${k + 1}.${j + 1}.File${fileIndex + 1}`] =
          //       `HYPERLINK("${fileUrl.presignedUrl}", "${fileName}")`;
          //   });
          // }

          if (response && Array.isArray(response.data)) {
            response.data.forEach((fileUrl: { presignedUrl: string }, fileIndex: number) => {
              const fileName = item.files[fileIndex]?.fileName || `File ${fileIndex + 1}`;
              dynamicData[`${item.label}.${k + 1}.${j + 1}.File${fileIndex + 1}`] =
                fileUrl.presignedUrl;
            });
          }

          // Fill any missing file columns with placeholders (if the current row has fewer files than the max)
          // const maxFilesForLabel = maxFileCount[`${item.label}.${section.sectionTitle}`] || 0;
          // for (let fileIndex = item.files.length; fileIndex < maxFilesForLabel; fileIndex++) {
          //   dynamicData[`${item.label}.${k + 1}.${j + 1}.File${fileIndex + 1}`] = 'No file';
          // }
        } else {
          dynamicData[key] = itemResponse;
        }
      }
    }

    // Add mentor information and evaluation data
    for (let evalIndex = 0; evalIndex < applicant.ProgramApplicationStageEvaluations.length; evalIndex++) {
      const evaluation = applicant.ProgramApplicationStageEvaluations[evalIndex];
      const stageNumber = applicant?.ProgramApplicationStage?.stageNumber;

      // Add mentor information
      dynamicData[`MentorEmail.${evalIndex + 1}`] = evaluation.mentorEmail;
      dynamicData[`MentorName.${evalIndex + 1}`] = evaluation.mentorName;
      dynamicData[`OverallComment.${evalIndex + 1}`] = evaluation.overallComment;
      dynamicData[`Stage_Number${evalIndex + 1}`] = stageNumber;

      // Add stages input (multiple ratings per evaluation)
      for (let stageIndex = 0; stageIndex < evaluation.stagesInput.length; stageIndex++) {
        const stage = evaluation.stagesInput[stageIndex];
        dynamicData[`Stage_Criteria${stageIndex + 1}${evalIndex + 1}`] = stage.criteriaName;
        dynamicData[`Stage_Rating${stageIndex + 1}${evalIndex + 1}`] = stage.rating;
        dynamicData[`Stage_MaxRating${stageIndex + 1}${evalIndex + 1}`] = stage.maxRating;
        dynamicData[`Stage_Comments${stageIndex + 1}${evalIndex + 1}`] = stage.comments;
      }
    }

    data.push({ ...userData, ...dynamicData });
  }

  const worksheet = XLSX.utils.json_to_sheet(data);

  // Adjust cell types to avoid formatting issues
  const defineCellType = (worksheet: XLSX.WorkSheet) => {
    for (let rowIndex = 1; rowIndex <= data.length; rowIndex++) {
      const rowData = data[rowIndex - 1];
      const keys = Object.keys(rowData);

      keys.forEach((key, columnIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex });
        const cellValue = rowData[key];

        if (worksheet[cellAddress]) {
          const cell = worksheet[cellAddress];

          // Handle hyperlinks
          // if (typeof cellValue === 'string' && cellValue.startsWith('HYPERLINK')) {
          //   cell.t = 's'; // Set as string
          //   cell.f = cellValue; // Treat it as a formula for hyperlink
          // } else {
            cell.t = typeof cellValue === 'number' ? 'n' : 's'; // Set type: number or string
          // }
        }
      });
    }
  };

  defineCellType(worksheet);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Applicants');
  XLSX.writeFile(workbook, 'applicants.xlsx');
};
