import React, { useEffect } from 'react';
import cx from './investmentTracking.module.scss';
import { Button, Loader, ProfilePicture, Typography } from 'src/components/common';
import { Col, Row } from 'react-bootstrap';
import { InputField } from 'src/components/common/formFields';
import 'bootstrap/dist/css/bootstrap.min.css';
import { componentSizeVariantsEnum } from 'src/constants';
import { MdAdd } from 'react-icons/md';
import { useUrlParamValue } from 'src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeInvestmentModalState,
  cohortInvestment,
  cohortTeamList
} from 'src/store/cohort/cohort.slice';
import { AppDispatch } from 'src/store/store';
import { TTeam } from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
import UpdateInvestement from './updateInvestement';
import { textEllipsis } from 'src/utils';
import formatINR from 'src/utils/formatINR';

export default function InvestmentTracking({ handleClick, handleBack }: any) {
  const { paramValue } = useUrlParamValue();
  const dispatch = useDispatch<AppDispatch>();
  const { isInvestmentModal, cohortTeam } = useSelector((state: any) => state?.cohort);
  const { loading } = useSelector((state: any) => state?.common);
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const incubatorId: string = paramValue({ paramName: 'companyId' }) as string;
  useEffect(() => {
    if (cohortId) {
      dispatch(cohortInvestment({ cohortId }));
      dispatch(cohortTeamList({ cohortId, incubatorId }));
    }
  }, [cohortId]);
  function handleTracking(team: TTeam) {
    dispatch(changeInvestmentModalState({ state: true, team }));
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {isInvestmentModal.state && <UpdateInvestement />}
      <div className={`${cx.investementTracking}`}>
        <Row>
          <Col md={12}>
            <div className="insideCard transparent">
              {cohortTeam.length > 0 ? (
                <div className={cx.itemContainer}>
                  <div className={cx.itemLabel}>
                    <Row>
                      <Col md={3}>
                        <label className={`${cx.label}`}>Teams</label>
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col>
                            <label className={`${cx.label}`}>Total Fund</label>
                          </Col>
                          <Col>
                            <label className={`${cx.label}`}>Initial Fund</label>
                          </Col>
                          <Col>
                            <label className={`${cx.label}`}>Additional Fund</label>
                          </Col>
                          <Col md={4}>
                            <label className={`${cx.label}`}>Expense</label>
                          </Col>

                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {cohortTeam?.map((team: TTeam) => {
                    const nameWithEllipsis = textEllipsis({ charLimit: 12, text: team?.cohortTeamsTitle });
                    const expense = team?.investmentTracking?.expenses?.[0]?.amount || '0';
                    const initialFund = team?.investmentTracking?.fundsAlloted || '0'
                    const totalAdditionalAllotement =
                      team.investmentTracking?.additionalsAllotment?.reduce(
                        (acc, additionalFund) => acc + additionalFund.amount,
                        0
                      ) || 0;
                    const totalFund = formatINR(+initialFund + +totalAdditionalAllotement)
                    return (
                      <Row key={team.id} className="mb-2">
                        <Col md={3}>
                          <div className={`${cx.teamCard} ${team.status === 'Deactivated' ? cx.deactivate : ''}`}>
                            <ProfilePicture
                              url={team.profilePic}
                              fallback={team?.cohortTeamsTitle?.[0]?.toLocaleUpperCase()}
                            />
                            <div>
                              <Typography as="p" variant="body-2" fontWeight="semiBold" title={team?.cohortTeamsTitle}>
                                {nameWithEllipsis.text}
                              </Typography>
                              <Typography as="p" variant="caption" fontWeight="regular">
                                {team?.onboardingTo}
                              </Typography>
                            </div>
                          </div>
                        </Col>
                        <Col md={9}>
                          <Row>
                            <Col>
                              <div className={`${cx.inputFields}`}>
                                <InputField
                                  label=""
                                  style={{ width: '100%' }}
                                  id="fund alloted"
                                  value={totalFund}
                                  variant={componentSizeVariantsEnum.SMALL}
                                  placeholder="0"
                                  type="text"
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className={`${cx.inputFields}`}>
                                <InputField
                                  label=""
                                  style={{ width: '100%' }}
                                  id="fund alloted"
                                  value={formatINR(+initialFund)}
                                  variant={componentSizeVariantsEnum.SMALL}
                                  placeholder="0"
                                  type="text"
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className={`${cx.inputFields}`}>
                                <InputField
                                  label=""
                                  style={{ width: '100%' }}
                                  id="totalAdditionalAllotement"
                                  variant={componentSizeVariantsEnum.SMALL}
                                  placeholder="0"
                                  type="text"
                                  value={formatINR(+totalAdditionalAllotement)}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className={`${cx.inputFields}`}>
                                <InputField
                                  label=""
                                  id="Expense"
                                  style={{ width: '100%' }}
                                  value={formatINR(+expense)}
                                  variant={componentSizeVariantsEnum.SMALL}
                                  placeholder="0"
                                  type="text"
                                  readOnly
                                  disabled
                                />
                                <Button
                                  size="small"
                                  variant="primary"
                                  className="program-actions__actions__next"
                                  style={{ width: '70px', marginTop: '2px' }}
                                  type="button"
                                  onClick={() => handleTracking(team)}
                                >
                                  History
                                </Button>
                              </div>
                            </Col>

                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              ) : (
                <div className={`${cx.initialTitle}`}>
                  <p>No team Added yet</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
