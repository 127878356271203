import { ReactNode } from 'react';
import classNames from 'classnames';
import { Modal, Typography } from 'src/components/common';
import {
  CalendarIcon,
  CheckboxIcon,
  ClockIcon,
  NoteIcon,
  RadioIcon,
  SingleChevronIcon,
  TextIcon,
  UploadIcon
} from 'src/components/common/common.icons';
import { colors } from 'src/constants';

import { TAddEditCustomApplicationFieldsProps } from './addEditCustomApplicationFields.types';

import { TFieldType } from '../../programDetails/programDetails.types';
import '../../programDetails/addEditProgramDetailsFields/addEditProgramDetailsFields.scss';

const AddEditCustomApplicationFields = ({
  isModalOpen,
  onModalClose,
  onSelection,
  title,
  id,
  currentType,
  sectionId,
  customFields = []
}: TAddEditCustomApplicationFieldsProps) => {
  const fields: { fieldType: TFieldType; label: string; icon: ReactNode }[] = customFields.length > 0 ? customFields : [
    { fieldType: 'label', label: 'Add Label', icon: <TextIcon /> },
    {
      fieldType: 'dropdown',
      label: 'Dropdown',
      icon: <SingleChevronIcon color={colors.neutral[1100]} direction="down" />
    },
    { fieldType: 'address', label: 'Address', icon: <NoteIcon /> },
    { fieldType: 'textInput', label: 'Text input field', icon: <NoteIcon /> },
    { fieldType: 'textEditor', label: 'Text Editor', icon: <NoteIcon /> },
    { fieldType: 'phoneNumber', label: 'Phone', icon: <CalendarIcon /> },

    { fieldType: 'fileUpload', label: 'File upload', icon: <UploadIcon /> },
    { fieldType: 'textArea', label: 'Text Area field', icon: <NoteIcon /> },
    { fieldType: 'singleChoice', label: 'Single choices options', icon: <RadioIcon /> },
    { fieldType: 'dateSelection', label: 'Date Selection', icon: <CalendarIcon /> },
    { fieldType: 'multipleChoice', label: 'Multiple choices options', icon: <CheckboxIcon /> },
    { fieldType: 'tableColumns', label: 'Table', icon: <CheckboxIcon /> },
    { fieldType: 'timeSelection', label: 'Time selection', icon: <ClockIcon /> }
  ];

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title={title}
      className="add-edit-program-details-fields"
    >
      <Typography as="p" variant="body-2" fontWeight="regular">
        Select the fields you want to add
      </Typography>
      <div className="add-edit-program-details-fields__content__fields">
        {fields.map((field, index) => {
          const { label, icon, fieldType } = field;
          return (
            <div
              key={index}
              className={classNames('add-edit-program-details-fields__content__fields__field', {
                'add-edit-program-details-fields__content__fields__field--is-active':
                  currentType === fieldType
              })}
              onClick={() => {
                onSelection({ fieldType, sectionId, fieldId: id || '' });
              }}
            >
              {icon}
              <Typography as="span" variant="body-2" fontWeight="medium">
                {label}
              </Typography>
            </div>
          );
        })}
      </div>{' '}
    </Modal>
  );
};

export default AddEditCustomApplicationFields;
