import { useEffect } from 'react';
import { TApplicant, TGetApplicationRequest } from 'src/services/apiEndpoint.types.ts';
import Header from 'src/components/header/Header';
import Breadcrumbs, { TBreadcrumb } from 'src/components/common/breadcrumbs/Breadcrumbs';
import {
  ApplicationActions,
  ApplicationSections
} from 'src/components/company/programs/viewApplication';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getApplication } from 'src/store/program/program.slice';

import './viewProgramApplication.scss';
import TrackStepper from 'src/components/common/TrackStepper/ActionStepper';

const ViewProgramApplication = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const { application: viewApplication, allstages, stage }: { application: TApplicant, allstages?: any, stage?: any } = useAppSelector(
    (store) => store.program.viewApplication
  );
  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();
  const applicationId: string = paramValue({ paramName: 'id' }) as string;

  const breadCrumbs: TBreadcrumb[] = [
    { label: 'Company Profile', url: '/my-company' },
    {
      label: 'Program Details',
      url: `/view-program?id=${viewApplication?.programId}&tab=submittedApplications`
    },
    { label: 'Submitted Application', url: '' }
  ];

  const handleGetApplication = () => {
    const payload: TGetApplicationRequest = {
      applicationId: applicationId,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getApplication(payload));
  };

  useEffect(() => {
    handleGetApplication();
  }, [applicationId]);
  const copyStages = allstages ? JSON.parse(JSON.stringify(allstages)) : []
  const steps: any = copyStages?.sort((a:any, b:any) => a?.stageNumber - b?.stageNumber)?.map((stage: any) => ({ title: stage?.stageTitle }));
  return (
    <div className="view-program-application">
      <Header />
      <div className="view-program-application__body">
        <Breadcrumbs breadcrumbs={breadCrumbs} key={applicationId} />
        <ApplicationActions key={applicationId} />
        {allstages?.length > 0 && <TrackStepper steps={steps} activeStep={stage.stageNumber - 1}/>}
        <ApplicationSections key={applicationId} />
      </div>
    </div>
  );
};

export default ViewProgramApplication;
