import React, { ReactNode, useEffect, useState } from 'react';
import cx from './createSurvey.module.scss';

import { AiFillCamera } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import { upArrow } from '../../../../../assets/images/index';

import { Button, Card, ConfirmModal, Loader, ProfilePicture, Separator, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import OptionsField from '../../applyToProgram/previewField/OptionsField';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Form, Formik, FormikErrors } from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorMessage from '../../../../common/formFields/errorMessage/ErrorMessage';
import createSurveyReportsValidationSchema from './createSurveyReportsValidationSchema';
import { useToggleOpenModal, useUrlParamValue } from 'src/hooks';
import { CheckboxIcon, CrossIcon, NoteIcon, PlusIcon, PlusIconCircle, RadioIcon, SingleChevronIcon, TextIcon, UploadIcon } from 'src/components/common/common.icons';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { TApplicationFormSection, TCustomApplicationSection, TFieldType } from '../../createProgram';
import { getUuid } from 'src/utils';
import CustomApplicationFormSection from '../../createProgram/customApplicationForm/customApplicationFormSection/CustomApplicationFormSection';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import createCustomApplicationFormSchema from '../../createProgram/customApplicationForm/createCustomApplicationFormSchema';
import validateForm from '../../validateForm/validateForm';
import { createCohortSurvey } from 'src/store/cohort/cohort.slice';
import TextAreaEditor from 'src/components/common/formFields/textAreaEditor/TextAreaEditor';

interface TOnboardProps {
    handleBack: () => void;
}
interface TInitialReport {
    type: string,
    objective?: string,
    title: string,
    applicationForm?: any
}
const initialValues: TInitialReport = {
    type: '',
    objective: '',
    title: ''
};
export const changeSurveyFormFieldTypeId = 'changeApplicationFormFieldType';
export const deleteSurveyFormFieldModalId = 'deleteApplicationFormFieldModalId';
export default function CreateSurvey({ handleBack }: TOnboardProps) {
    const { paramValue } = useUrlParamValue();
    const companyId: string = paramValue({ paramName: 'companyId' }) as string;
    const cohortId: string = paramValue({ paramName: 'id' }) as string;
    const [surveyError, setSurveyError] = useState<Record<string, string>>({});
    const resourceId: string = paramValue({ paramName: 'resourceId' }) as string;
    const [reportData, setReportData] = useState<TInitialReport>(initialValues);
    const { globalLoading }: { globalLoading: string } = useAppSelector((store: any) => store.cohort);
    const dispatch = useAppDispatch()
    const [selectedSectionForDeletion, setSelectedSectionForDeletion] = useState<string>('');
    const [selectedSectionFieldForDeletion, setSelectedSectionFieldForDeletion] = useState<{
        sectionId: string;
        fieldId: string;
    } | null>(null);
    // Hooks
    const modalId: string = useAppSelector((store: any) => store.common.modalId);
    const toggleOpenModal = useToggleOpenModal();

    const isDeleteApplicationFormFieldModalOpen = modalId === deleteSurveyFormFieldModalId;
    const sectionId = `section-0-${companyId}-survey`
    // state
    const [applicationFormSections, setApplicationFormSections] = useState<TApplicationFormSection[]>(
        [{
            sectionItems: [],
            id: sectionId,
            sectionTitle: 'section-0'
        }]
    );
    console.log(applicationFormSections, 'applicationFormSections');

    // Field management
    const handleToggleOpenChangeSectionFieldTypeModal = () => {
        toggleOpenModal({ modalId: changeSurveyFormFieldTypeId });
    };

    const toggleOpenDeleteFieldModal = () => {
        toggleOpenModal({ modalId: deleteSurveyFormFieldModalId });
    };
    const handleAddField = ({
        sectionId,
        fieldType
    }: {
        sectionId: string;
        fieldType: TFieldType;
    }) => {
        setApplicationFormSections((prev) => {
            const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));

            const selectedSection = currentSections.find(
                (section) => section.id === sectionId
            ) as TApplicationFormSection;

            if (selectedSection) {
                const fields = selectedSection.sectionItems;
                const newId = getUuid();

                const newField: TCustomApplicationSection = {
                    id: newId,
                    label: '',
                    type: fieldType,
                    options: ['', ''],
                    validations: { minChararactes: 1, maxCharacters: 3000, minOptionCount: 2 },
                    required: false,
                    response: '',
                    files: [],
                    placeholder: '',
                    hasCharacterLimit: false
                };

                fields.push(newField);
            }

            return currentSections;
        });
    };
    const handleUpdateField = ({
        sectionId,
        fieldId,
        field
    }: {
        sectionId: string;
        fieldId: string;
        field: TCustomApplicationSection;
    }) => {
        setApplicationFormSections((prev) => {
            try {
                const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));

                const selectedSection = currentSections.find(
                    (section) => section.id === sectionId
                ) as TApplicationFormSection;

                const selectedFieldIndex: number = selectedSection?.sectionItems?.findIndex(
                    (field: TCustomApplicationSection) => field?.id === fieldId
                );

                if (selectedFieldIndex > -1) {
                    selectedSection.sectionItems[selectedFieldIndex] = field;
                }

                return currentSections;
            } catch {
                return prev;
            }
        });
    };
    const handleChangeFieldType = ({
        sectionId,
        fieldId,
        fieldType
    }: {
        sectionId: string;
        fieldId: string;
        fieldType: TFieldType;
    }) => {
        setApplicationFormSections((prev) => {
            try {
                const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));

                const selectedSection = currentSections.find(
                    (section) => section.id === sectionId
                ) as TApplicationFormSection;

                const selectedFieldIndex: number = selectedSection?.sectionItems?.findIndex(
                    (field: TCustomApplicationSection) => field?.id === fieldId
                );

                if (selectedFieldIndex > -1) {
                    const newField: TCustomApplicationSection = {
                        id: fieldId,
                        label: '',
                        type: fieldType,
                        options: ['', ''],
                        validations: { minChararactes: 1, maxCharacters: 3000, minOptionCount: 2 },
                        required: false,
                        response: '',
                        files: [],
                        placeholder: '',
                        hasCharacterLimit: false
                    };

                    selectedSection.sectionItems[selectedFieldIndex] = newField;
                }

                return currentSections;
            } catch {
                return prev;
            }
        });

        handleToggleOpenChangeSectionFieldTypeModal();
    };
    const toggleOpenDeleteSectionModal = () => {
        toggleOpenModal({ modalId: deleteSurveyFormFieldModalId });
    };
    const selectSectionForDeletion = ({ sectionId }: { sectionId: string }) => {
        setSelectedSectionForDeletion(sectionId);
        toggleOpenDeleteSectionModal();
    };
    const selectedFieldForDeletion = ({
        sectionId,
        fieldId
    }: {
        sectionId: string;
        fieldId: string;
    }) => {
        console.log(sectionId, fieldId, 'fieldId');

        setSelectedSectionFieldForDeletion({ sectionId, fieldId });
        toggleOpenDeleteFieldModal();
    };
    const updateSectionTitle = ({ title, sectionId }: { title: string; sectionId: string }) => {
        setApplicationFormSections((prev) => {
            try {
                const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));
                const selectedSection = currentSections.find(
                    (section) => section.id === sectionId
                ) as TApplicationFormSection;

                if (selectedSection) {
                    selectedSection.sectionTitle = title;
                }

                return currentSections;
            } catch {
                return prev;
            }
        });
    };
    const handleDeleteField = () => {
        if (selectedSectionFieldForDeletion) {
            const { sectionId, fieldId } = selectedSectionFieldForDeletion;

            setApplicationFormSections((prev) => {
                try {
                    const currentSections: TApplicationFormSection[] = JSON.parse(JSON.stringify(prev));

                    const selectedSection = currentSections.find(
                        (section) => section.id === sectionId
                    ) as TApplicationFormSection;

                    const selectedFieldIndex: number = selectedSection?.sectionItems?.findIndex(
                        (field: TCustomApplicationSection) => field?.id === fieldId
                    );

                    if (selectedFieldIndex > -1) {
                        selectedSection.sectionItems.splice(selectedFieldIndex, 1);
                    }

                    return currentSections;
                } catch {
                    return prev;
                }
            });

            toggleOpenDeleteFieldModal();
        }
    };
    const fields: { fieldType: TFieldType; label: string; icon: ReactNode }[] = [
        { fieldType: 'label', label: 'Add Label', icon: <TextIcon /> },
        {
            fieldType: 'dropdown',
            label: 'Dropdown',
            icon: <SingleChevronIcon color={colors.neutral[1100]} direction="down" />
        },
        { fieldType: 'textEditor', label: 'Text Editor', icon: <NoteIcon /> },
        { fieldType: 'fileUpload', label: 'File upload', icon: <UploadIcon /> },
        { fieldType: 'singleChoice', label: 'Single choices options', icon: <RadioIcon /> },
        { fieldType: 'multipleChoice', label: 'Multiple choices options', icon: <CheckboxIcon /> },
        { fieldType: 'tableColumns', label: 'Table', icon: <CheckboxIcon /> }
    ]
    // Section management
    const updateErrors = ({ errors }: { errors: Record<string, string> }) => {
        setSurveyError(errors);
    };

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            const customFieldValues: Record<string, string> = {};

            applicationFormSections.forEach((section) => {
                const sectionId: string = section?.id;
                const sectionItems = section?.sectionItems;

                // customFieldValues[`sectionTitle-${sectionId}`] = section?.sectionTitle;

                sectionItems.forEach((sectionItem) => {
                    const sectionItemId: string = sectionItem?.id;
                    const sectionItemType: TFieldType = sectionItem?.type;
                    const sectionItemLabel: string = sectionItem?.label;
                    const sectionItemOptions: string[] = sectionItem?.options;
                    const hasOptions: boolean =
                        sectionItemType === 'dropdown' ||
                        sectionItemType === 'multipleChoice' ||
                        sectionItemType === 'singleChoice';

                    customFieldValues[sectionItemId] = sectionItemLabel;

                    if (hasOptions && sectionItemOptions?.length > 0) {
                        sectionItemOptions.forEach((option, index) => {
                            customFieldValues[`option-${index}-${sectionItemId}`] = option;
                        });
                    }
                });
            });

            const schema = createCustomApplicationFormSchema({ sectionFields: customFieldValues });

            const errors = await validateForm({
                values: customFieldValues,
                schema,
                updateErrors
            });

            const hasZeroErrors: boolean = errors?.length < 1;
            if (hasZeroErrors) {
                const applicationForm = applicationFormSections.map((section) => {
                    const { sectionTitle, sectionItems: sectionItemsOriginal } = section;
                    const sectionItems = sectionItemsOriginal.map((section) => {
                        const { id, label, type, validations, required, response, options, files } = section;
                        return { id, label, type, validations, required, response, options, files };
                    });
                    return { sectionTitle, sectionItems };
                });
                let data = {
                    incubatorId: companyId,
                    cohortId,
                    ...values
                }
                if (applicationForm?.[0]?.sectionItems?.length > 0) {
                    data = { ...data, applicationForm: { sectionTitle: applicationForm?.[0]?.sectionTitle, sectionItems: applicationForm?.[0]?.sectionItems } }
                }
                submitSurvey(data)
            }
        } catch (e) {
            console.log(e);
        }
    };

    function submitSurvey(values) {
        dispatch(createCohortSurvey(values))
    }

    return (
        <>

            {isDeleteApplicationFormFieldModalOpen && (
                <ConfirmModal
                    title="Are you sure you want to delete this field?"
                    openConfirmModal={isDeleteApplicationFormFieldModalOpen}
                    onClose={toggleOpenDeleteFieldModal}
                    onSubmit={handleDeleteField}
                    submitText="Confirm"
                    type="error"
                />
            )}
            <div className={`${cx.createSurvey}`}>
                <div className={`${cx.titleHeader}`}>
                    <h5 className={`${cx.title}`}>Create Report / Survey</h5>
                    <Button
                        size="small"
                        variant="primary"
                        className="program-actions__actions__next"
                        style={{ width: 'auto' }}
                        type="button"
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                </div>
                <div className="insideCard">
                    <Formik
                        initialValues={reportData}
                        onSubmit={handleSubmit}
                        validationSchema={createSurveyReportsValidationSchema}
                        enableReinitialize={true}
                    >
                        {({ setFieldValue, values, errors, touched }) => {
                            console.log(values, 'valuessssss', errors);

                            return (
                                <Form className="edit-cohort__form" noValidate>
                                    <div className={cx.container}>
                                        <Row>
                                            <Col md={8}>
                                                <Card className='mt-3'>
                                                    <div className={`${cx.inputFields}`}>
                                                        {/* <h5 className='typography__caption typography__semiBold mb-2'>Type</h5> */}
                                                        <OptionsField
                                                            options={['Report', 'Survey']}
                                                            // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            //     setFieldValue('type', e.target.value)
                                                            // }
                                                            // value={values?.type}
                                                            type="singleChoice"
                                                            label="Type"
                                                            required={true}
                                                            id="type"
                                                        />
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col md={9}></Col>
                                            <Col md={8}>
                                                <Card className='mt-3'>
                                                    <div className={`${cx.inputFields}`}>
                                                        <InputField
                                                            required
                                                            label="Name of Reports/Survey"
                                                            id="title"
                                                            isTouched={touched.title}
                                                            variant={componentSizeVariantsEnum.SMALL}
                                                            placeholder="Name of Reports/Survey"
                                                            type="text"
                                                        />
                                                    </div>

                                                </Card>
                                            </Col>
                                            <Col md={8}>
                                                <Card className='mt-3'>
                                                    {/* <h5 className='typography__caption typography__semiBold mb-2'>Objective</h5>
                                                    <TextArea id='objective' placeholder='Objective' required={true}
                                                        textAreaStyle={{ width: '100%' }}
                                                    /> */}
                                                    <TextAreaEditor
                                                        id="objective"
                                                        style={{ minHeight: '230px' }}
                                                        required={true}
                                                        label="Objective"
                                                        value={values.objective}
                                                        onChange={(e) => setFieldValue('objective', e.target.value)}
                                                        variant={componentSizeVariantsEnum.SMALL}
                                                        placeholder={'Objective'}
                                                        type={"text"}
                                                    />
                                                    {errors.objective && touched.objective && !values.objective && <ErrorMessage message='Objective is required' />}
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col md={8}>
                                                {/* Sections start */}
                                                {applicationFormSections.map((section: TApplicationFormSection, index) => {
                                                    return (
                                                        <CustomApplicationFormSection
                                                            id={section.id}
                                                            fields={fields}
                                                            type='cohort'
                                                            key={index}
                                                            handleAddField={handleAddField}
                                                            handleUpdateField={handleUpdateField}
                                                            handleChangeFieldType={handleChangeFieldType}
                                                            selectSectionForDeletion={selectSectionForDeletion}
                                                            selectedFieldForDeletion={selectedFieldForDeletion}
                                                            updateSectionTitle={updateSectionTitle}
                                                            setApplicationFormSections={setApplicationFormSections}
                                                            errors={surveyError}
                                                            {...section}
                                                        />
                                                    );
                                                })}
                                                {/* Sections end */}
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className='mt-3'>
                                        <Col md={2}>
                                            <Button variant="primary" type="submit" size="small">
                                                {globalLoading === 'survey' ? <Loader /> : 'Submit'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
}
