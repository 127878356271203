import React, { useEffect } from 'react';
import cx from './viewEvents.module.scss'
import { Button, Dropdown, InfiniteScroll, Loader, SubMenuModal, Tabs, TDropdown, TDropdownOptions, TTabItem } from 'src/components/common';
import { TViewProgramTabs } from 'src/pages/viewProgram/viewProgram.types';
import { useCopyToClipboard, useToggleOpenDropdown, useUrlParamValue } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import CommonDetail from '../CommonDetail/CommonDetail';
import SubmittedApplications from '../SubmittedApplications/SubmittedApplications';
import { getViewEvent, viewEventSuccess } from 'src/store/cohort/cohort.slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { formatDate } from 'src/utils';
import convertTo12HourFormat from 'src/utils/convertTo12Hours/convertTo12Hours';
import { ShareIcon } from 'src/components/common/common.icons';
interface TViewReportSurveyProps {
    handleBack: () => void
}

export default function ViewEvent({ handleBack }: TViewReportSurveyProps) {
    // hooks
    const { paramValue } = useUrlParamValue();
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const toggleOpenDropdown = useToggleOpenDropdown();
    const { viewEvent }: any = useAppSelector(state => state.cohort);
    const { loading }: { loading: boolean } = useAppSelector(state => state.common);
    const { copyToClipboard } = useCopyToClipboard();

    // query params
    const currentTab: TViewProgramTabs = paramValue({ paramName: 'key' }) as TViewProgramTabs;
    const companyId: string = paramValue({ paramName: 'companyId' }) as string;
    const cohortId: string = paramValue({ paramName: 'id' }) as string;
    const id: string = paramValue({ paramName: 'resourceId' }) as string;

    // constant
    const resourcesurveyid: string = `shareProgram-${id}`;
    const details = viewEvent ? [
        {
            label: "Event Name",
            value: viewEvent?.title
        },
        {
            label: "Start Date",
            value: `${formatDate({ dateString: viewEvent?.startDate })} ${convertTo12HourFormat(viewEvent?.startTime)}`
        },
        {
            label: "End Date",
            value: `${formatDate({ dateString: viewEvent?.endDate })} ${convertTo12HourFormat(viewEvent?.endTime)}`
        }
    ] : []
    if (viewEvent?.banner) {
        details.push(
            {
                label: "Banner",
                value: viewEvent?.banner?.url
            }
        )
    }
    if (viewEvent?.eventDescription) {
        details.push(
            {
                label: "Description",
                value: viewEvent?.eventDescription
            }
        )
    }
    const tabItems: TTabItem[] = [
        {
            key: 'details',
            text: 'Event Details',
            disabled: false,
            component: <CommonDetail detailFields={details} applicationDetails={viewEvent?.applicationForm || []} />
        }
    ];
    const shareProgramDropdownOptions: TDropdownOptions[] = [
        {
            label: `Copy Event link`,
            onClick: () => {
                const textObj = { text: `${window.location.host}/survey-report?id=${id}&type=event` }
                copyToClipboard(textObj);
            }
        }
    ];
    const shareSurveyReportDropdownProps: TDropdown = {
        id: resourcesurveyid,
        size: 'large',
        alignment: 'left',
        items: shareProgramDropdownOptions
    };

    // handler
    const handleChangeTab = (value: string) => {
        const path = `/view-cohort?id=${cohortId}&companyId=${companyId}&key=${value}&resourceId=${id}&tab=View-Event`;
        navigate(path, { replace: true });
    };
    const handleOpenShareDropdown = () => {
        toggleOpenDropdown({ dropdownId: resourcesurveyid });
    };

    // effects
    useEffect(() => {
        if (id) {
            dispatch(getViewEvent({ cohortId, incubatorId: companyId, id }))
        }
    }, [id])
    // if (loading) {
    //     return <Loader />
    // }

    return (
        <div className={`${cx.viewEvent}`}>
            <div className={`${cx.titleHeader}`}>
                <h5 className={`${cx.title}`}>Event</h5>
                <div className="d-flex gap-3">
                    {viewEvent?.id && <div className="program-actions__actions">
                        <div
                            className="program-actions__actions__share"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOpenShareDropdown();
                            }}
                        >
                            <div className="program-actions__actions__share__icon">
                                <ShareIcon />
                            </div>
                            <SubMenuModal>
                                <Dropdown {...shareSurveyReportDropdownProps} />
                            </SubMenuModal>
                        </div>
                    </div>}
                    <Button
                        size="small"
                        variant="primary"
                        className="program-actions__actions__next"
                        style={{ width: 'auto' }}
                        type="button"
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                </div>
            </div>
            <div>
                <Tabs activeItem={currentTab} onTabChange={handleChangeTab} items={tabItems} />
            </div>
        </div>
    )
}
