import TextAreaEditor from 'src/components/common/formFields/textAreaEditor/TextAreaEditor';
import { componentSizeVariantsEnum } from 'src/constants';
import Label from 'src/components/common/formFields/label/Label';
import { useGetFormikField } from 'src/hooks';

import './previewField.scss';
import { TPreviewFieldProps } from './previewField.types';

const TextField = ({ id, label, validations, required, createProgramStep }: TPreviewFieldProps) => {
  // Hooks
  const { getFormikField } = useGetFormikField();

  const [field, fieldMetaData, helpers] = getFormikField({ id: id || '' });
  console.log(getFormikField({ id: id || '' }), 'heloper');

  function removeTags(str: any) {
    if ((str === null) || (str === ' ') || (str === undefined)) {
      return false;
    } else {
      str = str.toString();
      str = str.replace(/&nbsp;/g, '')
      return str.replace(/(<([^>]+)>)/ig, '')
    }
  }
  const fieldValue = removeTags(field?.value);
  return (
    <div key={id} className="dynamic-field">
      <Label required={required}>{label}</Label>
      <div className="dynamic-field__field">
        {' '}
        <TextAreaEditor
          id={id}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder={label}
          type="text"
          {...(validations?.maxCharacters < 3000 && { textLengthLimit: validations.maxCharacters })}
          value={field?.value}
          onChange={(e) => {
            const value = removeTags(e.target.value)
            const fieldValue = removeTags(field.value);
            if (!validations.maxCharacters || (value?.length <= fieldValue?.length) || (value.length <= +validations.maxCharacters)) {
              helpers?.setValue(e.target.value);
            }
          }}
          isTouched={fieldMetaData?.touched || (validations.maxCharacters && fieldValue?.length > +validations.maxCharacters)}
          errorMessage={fieldMetaData?.error}
          createProgramStep
        />
      </div>
    </div>
  );
};

export default TextField;
