import { FC } from 'react';
import {
  Card,
  ConfirmModal,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Tooltip,
  Typography
} from 'src/components/common';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { TDeleteDocumentRequest, TGetDocumentRequest } from 'src/services/apiEndpoint.types.ts';
import { deleteDocument, getDocument } from 'src/store/ideaHub/ideaHub.slice';
import { useToggleOpenModal } from 'src/hooks';
import { textEllipsis, timeAgo } from 'src/utils';

import './documentCard.scss';

import { TIdeaDocument } from '../../ideaHub.types';
import { fileIcon } from 'src/assets/images';

export type TDocumentCardProps = TIdeaDocument & { showDeleteButton: boolean };

const documentDropdownId = 'documentDropdown';

const DocumentCard: FC<TDocumentCardProps> = ({
  id,
  fileName,
  s3Key,
  ideaId,
  showDeleteButton,
  updatedAt
}) => {
  // Hooks
  const dispatch = useAppDispatch();
  const toggleOpenModal = useToggleOpenModal();
  const { dropdownId, modalId, loading } = useAppSelector((store) => store.common);

  const documentUpdatedAt: string = timeAgo({ timestamp: updatedAt });

  // Utils
  const docTitleWitEllipsis = textEllipsis({ text: fileName, charLimit: 20 });

  // Constants
  const deleteDocumentModalId = `deleteDocumentModal-${id}`;
  const openDeleteDocumentModal = modalId === deleteDocumentModalId;

  const handleDownloadDocument = () => {
    const payload: TGetDocumentRequest = { s3Key };

    dispatch(getDocument(payload));
  };

  const handleDeleteDocument = () => {
    const payload: TDeleteDocumentRequest = { id, ideaId };

    dispatch(deleteDocument(payload));
  };

  const toggleDeleteDocumentModal = () => {
    toggleOpenModal({ modalId: deleteDocumentModalId });
  };

  const documentDropdownOptions: TDropdownOptions[] = [
    { label: 'Download', onClick: handleDownloadDocument }
  ];

  if (showDeleteButton) {
    documentDropdownOptions[1] = { label: 'Delete', onClick: toggleDeleteDocumentModal };
  }

  const documentDropdownProps: TDropdown = {
    id: `${documentDropdownId}-${id}`,
    items: documentDropdownOptions,
    className: 'idea-document-card__footer__actions__dropdown'
  };

  const openDocumentDropdown = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(documentDropdownProps.id));
    }
  };
  console.log(document, 'documents');

  return (
    <Card className="idea-document-card">
      <img src={fileIcon} style={{ height: '80px', width: '80px', margin: '0 auto 10px auto' }} alt="pdf-con" />
      <div className="idea-document-card__footer">
        <div className="idea-document-card__footer__info">
          <Tooltip text={fileName} conditionToDisplay={docTitleWitEllipsis?.hasEllipsis} limitWidth>
            <Typography
              as="p"
              variant="body-2"
              fontWeight="semiBold"
              className="idea-document-card__footer__info__title"
            >
              {docTitleWitEllipsis?.text}
            </Typography>
          </Tooltip>
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            className="idea-document-card__footer__info__updated-at"
          >
            {documentUpdatedAt}
          </Typography>
        </div>
        <div
          className="idea-document-card__footer__actions"
          onClick={(e) => {
            e.stopPropagation();
            openDocumentDropdown();
          }}
        >
          <MoreOptionsIcon />
          <SubMenuModal>
            <Dropdown {...documentDropdownProps} />
          </SubMenuModal>
        </div>
      </div>
      {openDeleteDocumentModal && (
        <ConfirmModal
          title="Do wish to delete this document?"
          description="Deleting this document will permanently removed."
          openConfirmModal={openDeleteDocumentModal}
          onClose={toggleDeleteDocumentModal}
          submitText="Confirm"
          onSubmit={handleDeleteDocument}
          type="error"
          loading={loading}
        />
      )}
    </Card>
  );
};

DocumentCard.defaultProps = {
  fileName: '',
  lastModified: ''
};

export default DocumentCard;
