import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';
const CohortIcon = ({ color = colors.neutral[600], ...rest }: TSvgIcon) => {
    return (
        // <svg width="512" height="512" viewBox="0 0 512 512" fill={color} xmlns="http://www.w3.org/2000/svg">
        //     <path d="M112 256H320C324.4 256 328 252.4 328 248C328 243.6 324.4 240 320 240H264V232C264 218.8 274.8 208 288 208H408V232C408 236.4 404.4 240 400 240H352C347.6 240 344 243.6 344 248C344 252.4 347.6 256 352 256H400C413.2 256 424 245.2 424 232V40C424 26.8 413.2 16 400 16H288C274.88 16 263.28 22.48 256 32.24C252.314 27.2122 247.497 23.1223 241.938 20.3011C236.379 17.4799 230.234 16.0064 224 16H176C171.6 16 168 19.6 168 24C168 28.4 171.6 32 176 32H224C237.2 32 248 42.8 248 56V200.24C241.28 195.2 233.04 192 224 192H104V40C104 35.6 107.6 32 112 32H144C148.4 32 152 28.4 152 24C152 19.6 148.4 16 144 16H112C98.8 16 88 26.8 88 40V232C88 245.2 98.8 256 112 256ZM264 56C264 42.8 274.8 32 288 32H400C404.4 32 408 35.6 408 40V192H288C278.96 192 270.72 195.12 264 200.24V56ZM104 208H224C237.2 208 248 218.8 248 232V240H112C107.6 240 104 236.4 104 232V208Z" fill={color} stroke={color} />
        //     <path d="M216 56H136C131.6 56 128 59.6 128 64C128 68.4 131.6 72 136 72H216C220.4 72 224 68.4 224 64C224 59.6 220.4 56 216 56ZM216 88H136C131.6 88 128 91.6 128 96C128 100.4 131.6 104 136 104H216C220.4 104 224 100.4 224 96C224 91.6 220.4 88 216 88ZM216 120H136C131.6 120 128 123.6 128 128C128 132.4 131.6 136 136 136H216C220.4 136 224 132.4 224 128C224 123.6 220.4 120 216 120ZM216 152H136C131.6 152 128 155.6 128 160C128 164.4 131.6 168 136 168H216C220.4 168 224 164.4 224 160C224 155.6 220.4 152 216 152ZM328 72H376C380.4 72 384 68.4 384 64C384 59.6 380.4 56 376 56H328C323.6 56 320 59.6 320 64C320 68.4 323.6 72 328 72ZM296 104H376C380.4 104 384 100.4 384 96C384 91.6 380.4 88 376 88H296C291.6 88 288 91.6 288 96C288 100.4 291.6 104 296 104ZM296 136H376C380.4 136 384 132.4 384 128C384 123.6 380.4 120 376 120H296C291.6 120 288 123.6 288 128C288 132.4 291.6 136 296 136ZM296 168H376C380.4 168 384 164.4 384 160C384 155.6 380.4 152 376 152H296C291.6 152 288 155.6 288 160C288 164.4 291.6 168 296 168ZM286 405.36C291.607 400.879 296.136 395.195 299.251 388.728C302.367 382.262 303.99 375.178 304 368V352C304 325.52 282.48 304 256 304C229.52 304 208 325.52 208 352V368C208 383.12 215.04 396.56 226 405.36C192.24 417.68 168 450 168 488C168 492.4 171.6 496 176 496C180.4 496 184 492.4 184 488C184 448.32 216.32 416 256 416C295.68 416 328 448.32 328 488C328 492.4 331.6 496 336 496C340.4 496 344 492.4 344 488C344 450 319.76 417.68 286 405.36ZM256 400C238.32 400 224 385.68 224 368V352C224 334.32 238.32 320 256 320C273.68 320 288 334.32 288 352V368C288 385.68 273.68 400 256 400Z" fill={color} stroke={color} />
        //     <path d="M422 373.36C427.607 368.879 432.136 363.195 435.251 356.728C438.367 350.262 439.99 343.178 440 336V320C440 293.52 418.48 272 392 272C365.52 272 344 293.52 344 320V336C344 351.12 351.04 364.56 361.92 373.36C347.52 378.56 334.56 387.52 324.4 399.6C323.722 400.404 323.21 401.333 322.891 402.335C322.573 403.337 322.455 404.391 322.544 405.439C322.633 406.486 322.927 407.506 323.411 408.439C323.894 409.373 324.556 410.202 325.36 410.88C326.164 411.558 327.093 412.07 328.095 412.389C329.097 412.707 330.151 412.825 331.199 412.736C332.246 412.647 333.266 412.352 334.199 411.869C335.133 411.386 335.962 410.724 336.64 409.92C343.414 401.844 351.871 395.346 361.42 390.883C370.97 386.419 381.379 384.097 391.92 384.08C431.6 384.08 463.92 416.4 463.92 456.08C463.92 460.48 467.52 464.08 471.92 464.08C476.32 464.08 479.92 460.48 479.92 456.08C479.92 418.08 455.68 385.76 421.92 373.44L422 373.36ZM392 368C374.32 368 360 353.68 360 336V320C360 302.32 374.32 288 392 288C409.68 288 424 302.32 424 320V336C424 353.68 409.68 368 392 368ZM175.28 409.84C176.648 411.463 178.606 412.476 180.721 412.656C182.837 412.836 184.937 412.168 186.56 410.8C188.183 409.431 189.196 407.474 189.376 405.359C189.556 403.243 188.888 401.143 187.52 399.52C177.521 387.645 164.585 378.597 150 373.28C155.603 368.8 160.124 363.115 163.226 356.646C166.329 350.178 167.933 343.094 167.92 335.92V319.92C167.92 293.44 146.4 271.92 119.92 271.92C93.4399 271.92 71.9199 293.44 71.9199 319.92V335.92C71.9199 351.04 78.9599 364.48 89.9199 373.28C56.1599 385.6 31.9199 417.92 31.9199 455.92C31.9199 460.32 35.5199 463.92 39.9199 463.92C44.3199 463.92 47.9199 460.32 47.9199 455.92C47.9199 416.24 80.2399 383.92 119.92 383.92C141.28 383.92 161.44 393.36 175.2 409.76L175.28 409.84ZM120 368C102.32 368 87.9999 353.68 87.9999 336V320C87.9999 302.32 102.32 288 120 288C137.68 288 152 302.32 152 320V336C152 353.68 137.68 368 120 368Z" fill={color} stroke={color} />
        // </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 24 24" stroke={color}>
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM8 17.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM9.5 8c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8zm6.5 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
            </g>
        </svg>
    );
}
export default CohortIcon
