import { AuthLayout, PublicLayout, ProtectedLayout, UserOnboardingLayout } from 'src/layouts';
import { TLayoutRoute } from 'src/types/routes.types';
import {
  EmailVerification,
  ForgotPassword,
  LandingPage,
  Login,
  OnboardingUserType,
  SetNewPassword,
  SignUp,
  TellMeMore,
  ForceChangePassword,
  AccessDenied,
  PageNotFound,
  ApplyToProgram
} from 'src/pages';

import { renderRoutes } from './generate-routes';
import getProtectedRoutes from './getProtectedRoutes';
import SuccessScreen from 'src/components/common/SuccessScreen/SuccessScreen';
import CheckPassword from 'src/pages/check-password';
import RatingSection from 'src/components/company/programs/ApplicationRating/RatingSection/RatingSection';
import ApplyToProgramExternal from 'src/pages/applyToProgramExternal/ApplyToProgramExternal';
import KeyMemberVerification from 'src/pages/KeyMemberVerification/KeyMemberVerification';
import Feedback from 'src/pages/feedback/feedback';
import PublicSurveyReportForm from 'src/components/company/programs/Cohorts/PublicSurveyReportForm';
import RatingResponses from 'src/components/company/programs/Cohorts/ApplicationRating/RatingResponses/RatingResponses';

export const routes: TLayoutRoute[] = [
  {
    layout: PublicLayout,
    isPublic: true,
    routes: [
      {
        name: 'home-page',
        title: 'Home page',
        component: LandingPage,
        path: '/',
        availableToUserType: 'All'
      },
      {
        name: 'access-denied',
        title: 'Access Denied',
        component: AccessDenied,
        path: '/access-denied',
        availableToUserType: 'All'
      },
      {
        name: 'Success',
        title: 'Success',
        component: SuccessScreen,
        path: '/success',
        availableToUserType: 'All'
      },
      {
        name: 'KeyMemberVerification',
        title: 'KeyMemberVerification',
        component: KeyMemberVerification,
        path: '/keymember-verification',
        availableToUserType: 'All'
      },
      {
        name: 'apply-to-program-external',
        title: 'Apply to External Program',
        component: ApplyToProgramExternal,
        path: '/apply-to-program-external',
        availableToUserType: 'All'
      },
      {
        name: 'feedback',
        title: 'Feedback',
        component: Feedback,
        path: '/feedback',
        availableToUserType: 'All'
      },
      {
        name: 'Check Password',
        title: 'Check Password',
        component: CheckPassword,
        path: '/check-password',
        availableToUserType: 'All'
      },
      {
        name: 'Application Rating',
        title: 'application rating',
        component: RatingSection,
        path: '/application-rating',
        availableToUserType: 'All'
      },
      {
        name: 'apply-to-program',
        title: 'Apply Program',
        component: ApplyToProgram,
        path: '/apply-to-program',
        availableToUserType: 'All'
      },
      {
        name: 'survey-report',
        title: 'Survey Report',
        component: PublicSurveyReportForm,
        path: '/survey-report',
        availableToUserType: 'All'
      }
    ]
  },
  {
    layout: AuthLayout,
    isPublic: true,
    routes: [
      {
        name: 'login',
        title: 'Login page',
        component: Login,
        path: '/login',
        availableToUserType: 'All'
      },
      {
        name: 'sign up',
        title: 'Sign up page',
        component: SignUp,
        path: '/sign-up',
        availableToUserType: 'All'
      },
      {
        name: 'email-verification',
        title: 'Email Verification',
        component: EmailVerification,
        path: '/email-verification',
        availableToUserType: 'All'
      },
      {
        name: 'forgot-password',
        title: 'Forgot Password',
        component: ForgotPassword,
        path: '/forgot-password',
        availableToUserType: 'All'
      },
      {
        name: 'set-new-password',
        title: 'Set New Password',
        component: SetNewPassword,
        path: '/set-new-password',
        availableToUserType: 'All'
      },
      {
        name: 'force-change-password',
        title: 'Force Change Password',
        component: ForceChangePassword,
        path: '/force-change-password',
        availableToUserType: 'All'
      }
    ]
  },
  {
    layout: UserOnboardingLayout,
    routes: [
      {
        name: 'onboarding-user-type',
        title: 'Onboarding User Type',
        component: OnboardingUserType,
        path: '/onboarding-user-type',
        availableToUserType: 'All'
      },

      {
        name: 'tell-me-more',
        title: 'Tell Me More',
        component: TellMeMore,
        path: '/tell-me-more',
        availableToUserType: 'All'
      }
    ]
  },
  {
    layout: ProtectedLayout,
    routes: getProtectedRoutes
  },
  {
    layout: PublicLayout,
    routes: [{ name: 'not-found', title: 'Not found', component: PageNotFound, path: '*' }]
  },
  {
    layout: PublicLayout,
    routes: [{ name: 'Success', title: 'Success', component: SuccessScreen, path: '/success' }]
  },
  {
    layout: PublicLayout,
    routes: [{ name: 'apply-to-program', title: 'Apply Program', component: ApplyToProgram, path: '/apply-to-program' }]
  },
  {
    layout: PublicLayout,
    routes: [{ name: 'Check Password', title: 'Check Password', component: CheckPassword, path: '/check-password' }]
  },
  {
    layout: PublicLayout,
    routes: [{ name: 'Application Rating', title: 'application rating', component: RatingSection, path: '/application-rating' }]
  }
];

export const Routes = renderRoutes(routes);
