import * as Yup from 'yup';

const createEventValidationSchema = Yup.object().shape({
    title: Yup.string().required('Please enter the event title'),
    startDate: Yup.string().required('Please select the start date'),
    startTime: Yup.string().required('Please select the start Time'),
    endTime: Yup.string().required('Please select the end Time'),
    eventDescription: Yup.string().optional(),
    endDate: Yup.string()
        .required('Please select the end date')
        .test('is-greater-than-start', 'End date cannot be prior to start date', function (value) {
            const { startDate } = this.parent;
            if (!startDate || !value) return true; // Skip validation if start or end is empty

            const programStartDate = new Date(startDate).getTime();
            const programEndDate = new Date(value).getTime();
            return programEndDate >= programStartDate; // Return true if end date is greater than or equal to start date
        }),
    applicationForm: Yup.object().shape({
        // Define fields in applicationForm as per your requirements
    }).optional(),
    banner: Yup.object().shape({
        url: Yup.string().optional(),
        height: Yup.number().optional(),
        width: Yup.number().optional()
    }).optional()
});

export default createEventValidationSchema;
