import React from 'react';
import cx from './globalMilestone.module.scss';
import { Loader } from 'src/components/common';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from 'src/store/hooks';
import { useUrlParamValue } from 'src/hooks';

export default function GlobalMilestoneView({ type }: any) {
  const { paramValue } = useUrlParamValue();
  const milestoneData = useAppSelector((state: any) => state.cohort.Milestone);
  const { loading } = useAppSelector((state: any) => state.common);

  const cohortId = paramValue({ paramName: 'id' }) || '';
  const teamId = paramValue({ paramName: 'teamId' }) as unknown as string;

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={cx.details}>
      <Row className="mt-3">
        <Col md={12}>
          <div className={cx.insideCard}>
            {type === 'team' && <h5 className={`${cx.heading}`}>Team&lsquo;s Milestone</h5>}
            {milestoneData?.length > 0 ? (
              <ul className={cx.milestonelist}>
                {milestoneData?.map((milestone: any, milestoneIndex: number) => (
                  <li key={milestone.id} className={cx.milestoneItem}>
                    {/* Milestone Box */}
                    <div className={cx.milestoneBox}>
                      <div className={cx.milestoneHeader}>
                        <span className={cx.mileStoneHeading}>
                          {milestone.cohortMilestoneTitle}
                        </span>
                        <div className={cx.milestonePercentage}>
                          <span className={cx.label}>Completion:</span>{' '}
                          <span className={cx.value}>{Math.floor(milestone.percentage || 0)}%</span>
                        </div>
                      </div>
                      <div className={cx.dateContainer}>
                        <div className={cx.dateItem}>
                          <span className={cx.label}>Start Date:</span>{' '}
                          <span className={cx.value}>{milestone.startDate || 'N/A'}</span>
                        </div>
                        <div className={cx.dateItem}>
                          <span className={cx.label}>End Date:</span>{' '}
                          <span className={cx.value}>{milestone.endDate || 'N/A'}</span>
                        </div>
                      </div>
                      <hr className={cx.divider} />

                      {/* Render Tasks if Available */}
                      {milestone.tasks && milestone.tasks.length > 0 && (
                        <ul className={cx.taskList}>
                          {milestone.tasks.map((task: any, taskIndex: number) => (
                            <li className={cx.taskItem} key={task.id}>
                              {/* Task Box */}
                              <div className={cx.taskBox}>
                                <div className={cx.taskHeader}>
                                  <span className={cx.taskHeading}>{task.taskTitle}</span>
                                  <div className={cx.taskPercentage}>
                                    <span className={cx.label}>Completion:</span>{' '}
                                    <span className={cx.value}>{task.percentage || 0}%</span>
                                  </div>
                                </div>
                                {/* Task Dates */}
                                <div className={`${cx.dateContainer} ${cx.taskPercentage}`}>
                                  <div className={cx.dateItem}>
                                    <span className={cx.label}>Start Date:</span>{' '}
                                    <span className={cx.value}>{task.startDate || 'N/A'}</span>
                                  </div>
                                  <div className={cx.dateItem}>
                                    <span className={cx.label}>End Date:</span>{' '}
                                    <span className={cx.value}>{task.endDate || 'N/A'}</span>
                                  </div>
                                </div>

                                {/* Task Comments */}
                                {task.comments && (
                                  <div className={cx.taskComments}>
                                    <span className={cx.label}>Comments:</span>{' '}
                                    <span className={cx.taskCommentsValue}>{task.comments}</span>
                                  </div>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </li>
                ))}
                <li>
                  <hr />
                </li>
              </ul>
            ) : (
              <h5 className={cx.emptyItem}>No team level milestone added yet</h5>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
