import { MouseEvent, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import {
  Card,
  ConfirmModal,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Tooltip,
  Typography,
  VerticalSeparator
} from 'src/components/common';
import { formatDate, textEllipsis, StorageUtils } from 'src/utils';
import CreateEditPostModal from 'src/components/posts/createEditPostModal/CreateEditPostModal';

import './cohortCard.scss';

import { TCohort } from 'src/services/apiEndpoint.types.ts/cohort/cohort.types';
import { Dropdown } from 'react-bootstrap';
import { dotMenu } from 'src/assets/images';
import cx from './dropdown.module.scss';
import { useAppDispatch } from 'src/store/hooks';
import { changeCreateCohortModalState } from 'src/store/common/common.slice';
const CohortCard = ({ cohortTitle, startDate, endDate, companyId, id }: TCohort) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  function handleNavigate() {
    navigate(`/view-cohort?id=${id}&companyId=${companyId}&tab=Dashboard`)
  }
  function handleUpdate() {
    dispatch(changeCreateCohortModalState({ state: true, id }))
  }

  return (
    <>
      <Card className="program-card">
        <div className={cx.dotDropdown}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic-6">
              <img src={dotMenu} className={cx.dots} alt="dot" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item href="#">
                <p className={cx.dotDropInside}>
                  <span>Delete</span>
                </p>
              </Dropdown.Item> */}
              <Dropdown.Item href="#" onClick={() => handleUpdate()}>
                <p className={cx.dotDropInside} >
                  <span>Update</span>
                </p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="program-card__content">
          <div className="program-card__content__details">
            <div className="program-card__content__details__heading">
              <Typography
                as="span"
                variant="body-1"
                fontWeight="semiBold"
                className="program-card__content__details__heading__cohortTitle"
              >
                {cohortTitle}
              </Typography>{' '}
              <VerticalSeparator />
            </div>
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="program-card__content__details__start"
            >
              Start date: {formatDate({ dateString: startDate })}
            </Typography>{' '}
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="program-card__content__details__start"
            >
              End date: {formatDate({ dateString: endDate })}
            </Typography>
          </div>
        </div>

        <div className="program-card__footer">
          <Button
            className="program-card__footer__action"
            size="extraSmall"
            variant="secondary"
            onClick={handleNavigate}
          >
            View Details
          </Button>
        </div>
      </Card>
    </>
  );
};

export default CohortCard;
