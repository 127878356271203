import { ChangeEvent } from 'react';
import { ActionIcon, Card, Typography } from 'src/components/common';
import { TrashIcon } from 'src/components/common/common.icons';
import TextAreaEditor from 'src/components/common/formFields/textAreaEditor/TextAreaEditor';
import { componentSizeVariantsEnum } from 'src/constants';
import { useGetFormikField } from 'src/hooks';

import './dynamicField.scss';
import { TDynamicFieldProps } from './dynamicField.types';

const TextEditor = ({ id, label, placeholder, deleteField, errors }: TDynamicFieldProps) => {
  const { getFormikField } = useGetFormikField();
  const [field, , helpers] = getFormikField({ id: id || '' });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label,
        type: 'textEditor',
        values: e.target.value,
        files: []
      });
    }
  };

  const handleDeleteField = () => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue(undefined, false);
    }

    deleteField({ id });
  };

  return (
    <Card key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Typography as="span" variant="caption" fontWeight="semiBold">
          {label}
        </Typography>
        <ActionIcon onClick={handleDeleteField}>
          <TrashIcon />
        </ActionIcon>
      </div>
      <TextAreaEditor
        id={id}
        variant={componentSizeVariantsEnum.SMALL}
        placeholder={placeholder}
        type="text"
        onChange={handleChange}
        value={field?.value?.values}
        errorMessage={errors?.[id]}
        isTouched={true}
      />
    </Card>
  );
};

export default TextEditor;
