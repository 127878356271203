import React from 'react'
import { Button, Card, ProfilePicture, Typography } from 'src/components/common'
import { formatDate, textEllipsis } from 'src/utils';
import ViewApplicationResponseModal from '../ViewApplicationResponseModal/ViewApplicationResponseModal';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { changeCohortSubmittedApplicationModal, viewResponseStateChange } from 'src/store/cohort/cohort.slice';
interface TSubmitCardProps {
    user: { name: string, profilePic?: string | null };
    name: string;
    submittedDate?: string
    sticker?: string;
    sectionData?: any[];
    id?: string
}

export default function SubmittedCard({ user, name, submittedDate, sticker = '', sectionData, id }: TSubmitCardProps) {
    const dispatch = useAppDispatch();
    function handleSubmittedReport() {
        dispatch(viewResponseStateChange({ state: true, data: sectionData, id }));
    }
    const nameEllipsis = textEllipsis({ charLimit: 30, text: name });
    return (
        <>
            <Card className="submitted-application-card">
                <div className="submitted-application-card__header">
                    <div className="submitted-application-card__header__left">
                        <ProfilePicture url={user?.profilePic} fallback={user?.name?.slice(0, 1)} />
                        <div className="submitted-application-card__header__left__user-info">
                            <Typography as="p" variant="body-2" fontWeight="semiBold" title={name}>
                                {nameEllipsis.text}
                            </Typography>
                        </div>
                    </div>
                    {sticker && <div className="submitted-application-card__header__stage">
                        <Typography as="span" variant="caption" fontWeight="medium" >
                            {sticker}
                        </Typography>
                    </div>}
                </div>
                {/* <Typography as="p" variant="caption" fontWeight="regular">
                {email}
            </Typography> */}
                {submittedDate && <Typography as="p" variant="caption" fontWeight="regular">
                    Submitted date: {formatDate({ dateString: submittedDate })}
                </Typography>}
                <div className="submitted-application-card__footer mt-2">
                    <Button variant="secondary" size="extraSmall" onClick={handleSubmittedReport}>
                        View Details
                    </Button>
                </div>
            </Card>
        </>
    )
}
