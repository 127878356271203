/* eslint-disable multiline-ternary */
import Header from 'src/components/header/Header';
import { BackNavigation, InfiniteScroll, Typography } from 'src/components/common';
import cx from './allMarketplaceproduct.module.scss';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CompanyCard from 'src/components/MarketPlace/CompanyCard/CompanyCard';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from 'src/components/common/common.icons';
import { InputField } from 'src/components/common/formFields';
import { filterIcon, sortIcon } from 'src/assets/images';

const AllMarketPlaceProduct = () => {
    const navigate = useNavigate()
    function handleGoBack() {
        navigate(-1)
    }
    return (
        <InfiniteScroll
            className="notifications"
            moreItemsLeft={false}
            onScrollEnd={() => { }}
        >
            <Header />
            <div className="notifications__body">
                <div>
                    <header className={`${cx.topbar}`}>
                        <BackNavigation text='Back' onClick={handleGoBack} />
                        <Typography
                            variant="subHeading-2"
                            fontWeight="semiBold"
                            className="notifications__body__heading"
                        >
                            All companies
                        </Typography>
                        <div className={`${cx.searchfilter}`}>
                            <Row className='w-100'>
                                <Col md={9}>
                                    <InputField
                                        id="filterString"
                                        style={{ borderRadius: '100px' }}
                                        endIcon={<span className={`${cx.actionIcon}`}><SearchIcon /></span>}
                                        placeholder="Search"
                                    />
                                </Col>
                                <Col md={3}>
                                    <div className={`${cx.filters}`}>
                                        <button><span>Sort by</span> <img src={sortIcon} alt="sort-icon" /> </button>
                                        <button><span>Filter</span> <img src={filterIcon} alt="filter-con" /></button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </header>
                    <section className={`${cx.content}`}>
                        <Row>
                            <Col md={3}>
                                <CompanyCard />
                            </Col>
                            <Col md={3}>
                                <CompanyCard />
                            </Col>
                            <Col md={3}>
                                <CompanyCard />
                            </Col>
                            <Col md={3}>
                                <CompanyCard />
                            </Col>
                            <Col md={3}>
                                <CompanyCard />
                            </Col>
                            <Col md={3}>
                                <CompanyCard />
                            </Col>
                            <Col md={3}>
                                <CompanyCard />
                            </Col>
                            <Col md={3}>
                                <CompanyCard />
                            </Col>
                        </Row>
                    </section>
                    <footer className={cx.footer}>
                        <button className={`${cx.actionButton} ${cx.disabled}`}>{"<"} Previous</button>
                        <div className={`${cx.pages}`}>
                            {[1, 2, 3, 4, ".", ".", 10].map((page: any) => (<span key={page} className={`${cx.page} ${page === 2 ? cx.active : ''}`}>{page}</span>))}
                        </div>
                        <button className={`${cx.actionButton}`}>Next {">"}</button>
                    </footer>
                </div>
            </div>
        </InfiniteScroll >
    );
};

export default AllMarketPlaceProduct;
