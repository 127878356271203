import * as Yup from 'yup';

// const milestoneCohortValidationSchema = Yup.object().shape({
//     cohortId: Yup.string().uuid().optional(),
//     userId: Yup.string().uuid().optional(),
//     cohortMilestoneTitle: Yup.string().required(),
//     startDate: Yup.date().required(),
//     endDate: Yup.date().required(),
//     tasks: Yup.array()
//         .of(
//             Yup.object().shape({
//                 taskTitle: Yup.string().required('Task title is required'),
//                 startDate: Yup.date().required('Task start date is required'),
//                 endDate: Yup.date().required('Task end date is required')
//             })
//         )
//         .required('At least one task is required')
//         .min(1, 'At least one task is required')
// });
const milestoneCohortValidationSchema = Yup.object().shape({
    milestones: Yup.array()
        .of(
            Yup.object().shape({
                // cohortId: Yup.string().uuid().optional(),
                // userId: Yup.string().uuid().optional(),
                cohortMilestoneTitle: Yup.string()
                    .required('Milestone title is required'),
                startDate: Yup.date()
                    .required('Start date is required')
                    .typeError('Invalid start date')
                    .nullable(),
                endDate: Yup.date()
                    .required('End date is required')
                    .typeError('Invalid end date')
                    .min(
                        Yup.ref('startDate'),
                        'End date cannot be earlier than start date'
                    )
                    .nullable(),
                tasks: Yup.array()
                    .of(
                        Yup.object().shape({
                            taskTitle: Yup.string()
                                .required('Task title is required'),
                            startDate: Yup.date()
                                .required('Task start date is required')
                                .typeError('Invalid task start date')
                                .nullable(),
                            endDate: Yup.date()
                                .required('Task end date is required')
                                .typeError('Invalid task end date')
                                .min(
                                    Yup.ref('startDate'),
                                    'Task end date cannot be earlier than task start date'
                                )
                                .nullable()
                        })
                    )
                    .min(1, 'At least one task is required')
            })
        )
        .min(1, 'At least one milestone is required')
});
export default milestoneCohortValidationSchema;
