import React from 'react';
import cx from './eventCard.module.scss';
import { eventsTest, test } from 'src/assets/images';
import { Button } from 'src/components/common';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';

export default function EventCard({ isAction = true }: { isAction?: boolean }) {
    return (
        <div className='item'>
            <div className={`${cx.outer_box}`}>
                <div className={`${cx.imageBox}`}>
                    <img src={eventsTest} alt="meeting-img" />
                </div>
                {!isAction && <div className={`${cx.actions}`}>
                    <EditIcon className={cx.icons} />
                    <TrashIcon className={cx.icons} />
                </div>}
                <div className={`${cx.marketplace_heading}`}>
                    <h4>Event Title</h4>
                    <h5>Organizer Name</h5>
                    <div className={`${cx.marketplace_content}`}>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                    </div>
                    {isAction && <div className={`${cx.ctaButton}`}>
                        <Button size="small" variant="primary" type="submit" >
                            Learn More
                        </Button>
                    </div>}
                </div>
            </div>
        </div>
    )
}
