import React, { useState } from 'react'
import CohortRelationship from '../CohortRelationship/CohortRelationship'
import Header from 'src/components/header/Header'
import { SelfUserContext } from 'src/pages/myProfile/MyProfile'
import { InfiniteScroll, Typography } from 'src/components/common';
import './viewCohort.scss';
import MileStone from '../Milestone/MileStone'
import Resources from '../Resources/Resources';
import { useSelector } from 'react-redux';
import cx from './action.module.scss';
import { formatDate } from 'src/utils';
import { calculateRemainingTime } from 'src/utils/formatDate/formatDate';

export default function ViewUserCohort() {
    const [activeScreen, setActiveScreen] = useState('relationship')
    const { viewUserCohort }: any = useSelector(
        (state: any) => state?.cohort
    );
    const handleAction = (screen: string) => {
        setActiveScreen(screen)
    }
    console.log(viewUserCohort, 'viewUserCohort');

    return (
        <>
            <SelfUserContext.Provider value={true}>
                <InfiniteScroll
                    onScrollEnd={() => { }}
                    className="my-cohort"
                    moreItemsLeft={false}
                >
                    <Header />
                    <div className="my-cohort__body">
                        <div className={cx.header}>
                            <h5 className={cx.cohortName}>My Cohort {">"} {viewUserCohort?.cohortTitle}</h5>
                            <div className={cx.dates}>
                                <p>Start date: {formatDate({ dateString: viewUserCohort?.startDate })}</p>
                                <p>End Date : {formatDate({ dateString: viewUserCohort?.endDate })}</p>
                                <p>({calculateRemainingTime(viewUserCohort?.endDate)} remaining)</p>
                            </div>
                        </div>
                        {activeScreen === 'relationship'
                            ? (
                                <CohortRelationship handleAction={handleAction} />
                            )
                            : activeScreen === 'Milestone'
                                ? (
                                    <MileStone handleAction={handleAction} />
                                )
                                : activeScreen === 'Resource'
                                    ? (
                                        <Resources handleAction={handleAction} />
                                    )
                                    : (<></>)
                        }
                    </div>

                </InfiniteScroll>
            </SelfUserContext.Provider>
        </>
    )
}
