import * as Yup from 'yup';

const createCohortValidationSchema = Yup.object().shape({
  incubatorId: Yup.string().optional(),
  cohortTitle: Yup.string().required('this field is required'),
  startDate: Yup.date().required('this field is required'),
  endDate: Yup.date().required('this field is required'),
  programObjective: Yup.string().optional(),
  templates: Yup.object({
    investmentTracking: Yup.boolean().optional(),
    kpiTracking: Yup.boolean().optional()
  }).optional()
});

export default createCohortValidationSchema;
