import React, { useEffect, useState } from 'react';
import cx from './mentor.module.scss';
import { Button, Loader, ProfilePicture, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import { MdAdd, MdRemove } from 'react-icons/md';
import {
  assignCohortMentor,
  changeCohortModalState,
  cohortAssignedMentor,
  cohortMentorSearch,
  cohortTeamList,
  resetMentorSearch,
  searchedMentorList
} from 'src/store/cohort/cohort.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParamValue } from 'src/hooks';
import { AppDispatch } from 'src/store/store';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import { httpRequest } from 'src/services/commonApis';
import ShowAssignTeam from './ShowAssignTeam';
import {
  TAssignMentor,
  TAssignMentorList,
  TMentor,
  TTeam
} from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
import { useAppSelector } from 'src/store/hooks';
import { textEllipsis } from 'src/utils';

interface TOnMilestoneProps {
  handleBack: () => void;
}
export default function Mentor({ handleBack }: TOnMilestoneProps) {
  const { paramValue } = useUrlParamValue();
  const { cohortTeam, searchedMentor, assignedMentors, globalLoading } = useSelector(
    (state: any) => state.cohort
  );
  const { loading } = useAppSelector((state: any) => state.common);
  const [searchKey, setSearchKey] = useState<string>('');
  const [selectedTeam, setSelectedTeam] = useState<string[]>([]);
  const [selectedSearchedMentor, setSelectedSearchedMentor] = useState<TMentor | null>(null);
  const incubatorId: string = paramValue({ paramName: 'companyId' }) as string;
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const dispatch = useDispatch<AppDispatch>();
  const activeCohortTeams = cohortTeam?.filter((team: any) => team.status !== 'Deactivated');

  const fetchUsers = async ({ resetUsers = false }: { resetUsers: boolean }) => {
    dispatch(resetMentorSearch());
    if (searchKey) {
      setSelectedSearchedMentor(null);
      dispatch(cohortMentorSearch({ key: searchKey, incubatorId }));
    }
  };
  useDebounce({
    func: () => fetchUsers({ resetUsers: true }),
    delay: 500,
    dependency: searchKey
  });
  useEffect(() => {
    if (cohortId) {
      dispatch(cohortTeamList({ cohortId, incubatorId }));
      dispatch(cohortAssignedMentor({ cohortId }));
    }
  }, [cohortId]);
  function handleAssign() {
    if (selectedSearchedMentor && selectedTeam?.length > 0) {
      const body = {
        teamIds: selectedTeam,
        mentorId: selectedSearchedMentor?.id,
        cohortId
      };
      dispatch(assignCohortMentor(body)).then((res: any) => {
        if (res?.status) {
          setSelectedTeam([]);
          setSelectedSearchedMentor(null);
        }
      });
    }
  }
  function handleTeamModal(mentor: TAssignMentorList) {
    dispatch(changeCohortModalState({ state: true, mentor }));
  }
  if (globalLoading === 'mentor-team') {
    return <Loader />;
  }
  return (
    <>
      <ShowAssignTeam />
      <div className={`${cx.mentor}`}>
        <div className={`${cx.titleHeader}`}>
          <h5 className={`${cx.title}`}>Mentor</h5>
          <Button
            size="small"
            variant="primary"
            className="program-actions__actions__next"
            style={{ width: 'auto' }}
            type="button"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <Row>
          <Row>
            <Col md={3}>
              <h5 className={`${cx.chatHeading}`}>Mentor</h5>
              <div className="insideCard transparent">
                <div className={cx.teamContainer}>
                  <Row>
                    <Col md={12}>
                      <div className={`${cx.inputFields}`}>
                        <InputField
                          label="Mentor's name"
                          id="mentorName"
                          variant={componentSizeVariantsEnum.SMALL}
                          placeholder="Mentor's name"
                          type="search"
                          value={searchKey}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchKey(e.target.value)
                          }
                        />
                        {searchKey !== '' && (
                          <div className={`${cx.mentorList}`}>
                            <Row>
                              {globalLoading === '' &&
                                searchedMentor?.map((mentor) => {
                                  const nameWithEllipsis = textEllipsis({ charLimit: 12, text: mentor?.name });
                                  return (
                                    <Col
                                      md={12}
                                      key={mentor?.id}
                                      onClick={() => {
                                        setSelectedSearchedMentor(mentor);
                                        setSearchKey('');
                                      }}
                                    >
                                      <div
                                        className={`${cx.teamCard} ${selectedSearchedMentor?.id === mentor?.id ? cx.active : ''}`}
                                      >
                                        <ProfilePicture
                                          url={mentor?.profilePic}
                                          fallback={mentor?.name?.[0]?.toLocaleUpperCase()}
                                        />
                                        <div>
                                          <Typography as="p" variant="body-2" fontWeight="semiBold" title={mentor.name}>
                                            {nameWithEllipsis?.text}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                })}
                              {globalLoading === 'search-mentor' && <Loader />}
                              {searchedMentor?.length === 0 && globalLoading === '' && (
                                <Col md={12}>
                                  <p className="emptyList">No mentor found</p>
                                </Col>
                              )}
                            </Row>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {selectedSearchedMentor && (
                    <Row>
                      <Col md={12}>
                        <div className={`${cx.teamCard} ${cx.active}`}>
                          <ProfilePicture
                            url={selectedSearchedMentor?.profilePic}
                            fallback={selectedSearchedMentor?.name?.[0]?.toLocaleUpperCase()}
                          />
                          <div>
                            <Typography as="p" variant="body-2" fontWeight="semiBold" title={selectedSearchedMentor?.name}>
                              {textEllipsis({ charLimit: 12, text: selectedSearchedMentor?.name }).text}
                            </Typography>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {((selectedSearchedMentor && selectedTeam?.length > 0) || loading) && (
                    <Row>
                      <Col md={12}>
                        <div className={cx.btnContainer}>
                          <button className={cx.assignButton} onClick={() => handleAssign()}>
                            {loading ? <Loader /> : 'Assign'}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
            <Col md={9}>
              <h5 className={`${cx.chatHeading}`}>Teams</h5>
              <div className="insideCard transparent">
                <div className={cx.teamContainer}>
                  <Row>
                    {activeCohortTeams?.map((team: TTeam) => {
                      const nameWithEllipsis = textEllipsis({ charLimit: 30, text: team?.cohortTeamsTitle });
                      const about = textEllipsis({ charLimit: 70, text: team?.about || '' });
                      return (
                        <Col
                          md={6}
                          key={team?.id}
                          onClick={() => {
                            if (selectedTeam.includes(team?.id)) {
                              const filterTeam = selectedTeam?.filter(
                                (previousTeam: string) => previousTeam !== team?.id
                              );
                              setSelectedTeam(filterTeam);
                            } else {
                              setSelectedTeam([...selectedTeam, team?.id]);
                            }
                          }}
                        >
                          <div
                            className={`${cx.teamCard} ${selectedTeam?.includes(team?.id) ? cx.active : ''}`}
                          >
                            <ProfilePicture
                              url={team.profilePic}
                              fallback={team?.cohortTeamsTitle?.[0]?.toLocaleUpperCase()}
                            />
                            <div>
                              <Typography as="p" variant="body-2" fontWeight="semiBold" title={team?.cohortTeamsTitle}>
                                {nameWithEllipsis.text}
                              </Typography>
                              <Typography as="p" variant="caption" fontWeight="regular" title={team?.about || ''}>
                                {about.text}
                              </Typography>
                              <Typography as="p" variant="caption" fontWeight="regular">
                                {team?.onboardingTo}
                              </Typography>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                    {cohortTeam.length === 0 && (
                      <div className="emptyContainerBoxes">
                        <p>No team added yet</p>
                      </div>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5 className={`${cx.chatHeading}`}>Assigned Mentors</h5>
              <div className="insideCard transparent">
                <div className={cx.teamContainer}>
                  <Row>
                    {assignedMentors?.map((mentor: TAssignMentorList) => {
                      const nameWithEllipsis = textEllipsis({ charLimit: 30, text: mentor?.mentor?.name });
                      return (
                        <Col md={4} key={mentor.mentor.id}>
                          <div className={`${cx.teamCard}`} onClick={() => handleTeamModal(mentor)}>
                            <ProfilePicture
                              url={mentor?.mentor?.profilePic}
                              fallback={mentor?.mentor?.name?.[0]?.toLocaleUpperCase()}
                            />
                            <div>
                              <Typography as="p" variant="body-2" fontWeight="semiBold" title={mentor?.mentor?.name}>
                                {nameWithEllipsis?.text}
                              </Typography>
                            </div>
                            <div className={cx.teamCount}>
                              <span>{mentor?.teams?.length}</span>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                    {assignedMentors.length === 0 && (
                      <div className="emptyContainerBoxes">
                        <p>No mentor assigned yet</p>
                      </div>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}
