import React from 'react'
import cx from './CompanyCard.module.scss';
import { playerIcon, test } from 'src/assets/images';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
export default function CompanyCard({ type }: { type?: string }) {
    return (
        <div className='item'>
            <div className={`${cx.outer_box}`}>
                {type === 'Product' && <div className={`${cx.actions}`}>
                    <EditIcon className={cx.icons}/>
                    <TrashIcon className={cx.icons}/>
                </div>}
                <div className={`${cx.playerBox}`}>
                    <img src={test} alt="meeting-img" />
                    {type === 'Product' && <img src={playerIcon} className={`${cx.playerIcon}`} alt="player-icon" />}
                </div>
                <div className={`${cx.marketplace_heading}`}>
                    <h4>{type ? `${type} Name` : 'Company Name'}</h4>
                    <div className={`${cx.marketplace_content}`}>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
