import React from 'react';
import OwlCarousel from "react-owl-carousel";
import ProductCard from 'src/components/MarketPlace/ProductCard/ProductCard';
import { optionsSlider1 } from 'src/constants/OwlCarouselConstant';
import cx from './topproduct.module.scss';

export default function TopProduct() {
    return (
        <div className={`${cx.topCompanies}`}>
            <OwlCarousel className="owl-theme sliderRow" {...optionsSlider1}>
                <ProductCard />
                <ProductCard />
                <ProductCard />
                <ProductCard />
                <ProductCard />
                <ProductCard />
                <ProductCard />
                <ProductCard />
            </OwlCarousel>
        </div>
    )
}
