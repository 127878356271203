import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal, Tooltip, Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import { changeCreateCohortModalState, changeSubmittedReportModalState } from 'src/store/common/common.slice';
import { useGetPlaces, useUrlParamValue } from 'src/hooks';

import './cohortSubmitReport.scss';
import cohortSubmitReportValidationSchema from './cohortSubmitReportValidationSchema';
import { useNavigate } from 'react-router-dom';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { DownloadIcon, FileIcon2 } from 'src/components/common/common.icons';
import { colors } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import { getReports } from 'src/store/cohort/cohort.slice';
import { formatDate } from 'src/utils';

export type TCreateCohortValues = {
    name: string,
    startDate: Date | string,
    endDate: Date | string,
    programObjective?: string
};

const SubmittedReportSummeryModal = () => {
    const isModalOpen: boolean = useAppSelector<boolean>((store: any) => store.common.isSubmittedReportModal);
    const { globalLoading, reportResources }: { globalLoading: string, reportResources: any } = useAppSelector((store: any) => store.cohort);
    const navigate = useNavigate();
    const { paramValue } = useUrlParamValue();
    const teamId: string = paramValue({ paramName: 'teamId' }) as string;
    const cohortId: string = paramValue({ paramName: 'id' }) as string;
    
    // Hooks
    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(changeSubmittedReportModalState(false));
    };

    useEffect(() => {
        if (teamId && cohortId) {
            dispatch(getReports({ teamId, cohortId }))
        }
    }, [teamId, cohortId])

    if (globalLoading) {
        return <Loader />
    }
    const handleDownload = (url: string) => {
        const link: HTMLAnchorElement | any = document.createElement('a');
        if (link && url) {
            link.href = url;
            link.download = url.split('/').pop(); // Use the file name from the URL
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    return (
        <Modal
            isModalOpen={isModalOpen}
            onModalClose={closeModal}
            title="Submitted Report"
            className="submitted-report-modal"
        >
            <div className="submitted-report-body">
                <ul className={'submitted-report-list'}>
                    {reportResources?.map((resource: any, i: number) => {
                        const dataArr = resource?.key;
                        const fileNameWithTimeStamp = dataArr.split('/')[5] || '';
                        const fileStampArr = fileNameWithTimeStamp?.split("-");

                        const fileName = fileStampArr?.[0] || ''
                        const timeStamp = fileStampArr?.[1]?.split('.')?.[0];
                        let date = '';
                        if (timeStamp) {
                            date = new Date(+timeStamp)?.toString()
                        }

                        return (
                            <li className={'submitted-report-list-item'} key={i}>
                                <Row>
                                    <Col md={5}>
                                        <div className="submitted-report-item">
                                            <div className="program-file-card">
                                                <div className="program-file-card__file">{<FileIcon2 />}</div>
                                                <div className="program-file-card__text">
                                                    <Tooltip text={'fileName'}>
                                                        <Typography
                                                            as="p"
                                                            variant="caption"
                                                            fontWeight="semiBold"
                                                            style={{ color: colors.neutral[1000] }}
                                                        >
                                                            {fileName}
                                                        </Typography>
                                                    </Tooltip>
                                                </div>
                                                <div className="program-file-card__actions">
                                                    <DownloadIcon onClick={() => handleDownload(resource.url)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={10} xs={10}>
                                                <div className='submitted-report-item-timestamp'>
                                                    <span className='date-time'>{formatDate({ dateString: date || new Date().toString() })}</span>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            </li>
                        )
                    })}
                </ul>
            </div >
        </Modal >
    );
};

export default SubmittedReportSummeryModal;
