/* eslint-disable multiline-ternary */
import { Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TGetProgramApplication, TGetProgramData, TGetProgramDocumentRequest } from 'src/services/apiEndpoint.types.ts';
import { formatDate } from 'src/utils';
import "bootstrap/dist/css/bootstrap.css";
import { getProgramDocument } from 'src/store/program/program.slice';
import './programDetails.scss';
import FileCard from '../../fileCard/FileCard';
import Button from 'src/components/button/Button';
import ExtendDeadlineProgram from './extendDeadlineProgram/extendDeadlineProgram';
import { assignModalId } from 'src/store/common/common.slice';
import { useContext, useState } from 'react';
import { CreateProgramReview } from 'src/components/company';
import { useGetIdentity } from 'src/hooks';
import { ViewApplicationSelf } from 'src/pages/viewMyApplication/ViewMyApplication';

const ProgramDetails = ({ type = '' }: { type?: string }) => {
  // Hooks
  const ExtendDealinProgram = "ExtendDealinProgram"
  const viewProgram: TGetProgramData = useAppSelector((store) => store.program.viewProgram);
  const dispatch = useAppDispatch();
  const [applicationShow, setApplicationShow] = useState<boolean>(false)
  const getDocument = ({ key }: { key: string }) => {
    const payload: TGetProgramDocumentRequest = { s3Key: key };

    dispatch(getProgramDocument(payload));
  };
  const { modalId }: { modalId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );

  // Constants
  const isManageAccessModalOpen = modalId === ExtendDealinProgram;

  const openManageAccessModalOpen = () => {
    dispatch(assignModalId(ExtendDealinProgram));
  };

  const closeManageAccessModalOpen = () => {
    dispatch(assignModalId(''));
    // dispatch(resetSearchUsers());
  };
  const isUserViewingApplication = useContext(ViewApplicationSelf);
  let programDetailsFields: { label: string; value: string }[] = [
    // { label: 'Program Name', value: viewProgram?.title },
    // { label: 'Start date', value: formatDate({ dateString: viewProgram?.startDate }) },
    { label: 'End date', value: formatDate({ dateString: viewProgram?.endDate }) }
  ];

  if (type !== 'application' && viewProgram?.banner?.url) {
    programDetailsFields.push({ label: 'Banner', value: viewProgram?.banner?.url })
  } else {
    programDetailsFields = [...programDetailsFields]
  }
  function convertTo12HourFormat(time: string): string {
    if (time) {
      const timeArray: string[] = time.split(':');
      const [hour, minute]: [string, string] = [timeArray[0] || '', timeArray[1] || ''];
      let hourNum: number = parseInt(hour);
      const suffix: string = hourNum >= 12 ? 'PM' : 'AM';

      hourNum = hourNum % 12 || 12;

      return `${hourNum}:${minute} ${suffix}`;
    } else {
      return '00:00'
    }
  }

  return (
    <div className="view-program-program-details position-relative">
      <div className='view-program-program-details flex' >
      <div>
      {type !== 'application' && !isUserViewingApplication && <Button
        size="small"
        variant="primary"
        className="program-actions__actions__next"
        style={{ position: 'absolute', top: '0', right: '0', width: '180px' }}
        type="button"
        onClick={() => setApplicationShow(!applicationShow)}
      >
        {applicationShow ? "Hide Application Form" : "View Application Form"}
      </Button>}
      </div>
      <div>
      {type !== 'application' && !isUserViewingApplication && <Button
        size="small"
        variant="primary"
        className="program-actions__actions__next"
        style={{ position: 'absolute', top: '0', right: '200px', width: '180px' }}
        type="button"
        onClick={() => openManageAccessModalOpen()}
      >
        Extend Deadline
      </Button>}
      </div>
      </div>
      {applicationShow ? (
        <CreateProgramReview
          key="create-program-review"
          handleSave={() => { }}
          applicationFormSections={viewProgram?.applicationForm?.sections}
          createProgramStep={3}
          type='viewApplication'
        />
      ) : <>
        {programDetailsFields.map(({ label, value }, index) => {
          if (label === 'Banner') {
            return (
              <div key={index} className="view-program-program-details__field">
                <Typography
                  className="view-program-program-details__field__label"
                  as="p"
                  variant="body-2"
                  fontWeight="semiBold"
                >
                  {label}
                </Typography>
                <div style={{ borderRadius: '0px', overflow: 'hidden' }}>
                  <img src={value} alt="banner" style={{ height: '150px', width: '600px', backgroundPosition: 'center', objectFit: 'cover' }} />
                </div>
              </div>
            )
          }
          return (
            <div key={index} className="view-program-program-details__field">
              {label && (
                <Typography
                  className="view-program-program-details__field__label"
                  as="p"
                  variant="body-2"
                  fontWeight="semiBold"
                >
                  {label}
                </Typography>
              )}
              <Typography
                className="view-program-program-details__field__value"
                as="p"
                variant="caption"
                fontWeight="regular"
              >
                {value}
              </Typography>
            </div>
          )
        })}
        {viewProgram?.programDetails.map(({ label, values, type, files }, index) => (
          <div key={index} className="view-program-program-details__field">
            <Typography
              className="view-program-program-details__field__label"
              as="p"
              variant="body-2"
              fontWeight="semiBold"
            >
              {label}
            </Typography>
            {type === 'fileUpload' && files && files?.length > 0 && (
              <div className="view-program-program-details__field__files">
                {files.map((file) => {
                //    const fileName = file?.fileName;
                //    const lastDotIndex = fileName.lastIndexOf('.');
                //    // Ensure the dot isn't the first character (to avoid hidden files
                //    let keyExt:string = ''
                //    if (lastDotIndex > 0 && lastDotIndex < fileName.length - 1) {
                //      keyExt= fileName.substring(lastDotIndex);
                //    }
                //    //
                // // conso
                //    // console.log()
                //    const fileKey = `Company/${draftData?.Program?.companyId}/Programs/${draftData?.Program?.id}/Applications/${draftData?.id}/${key}${keyExt}`
                 
                //    console.log(file, 'file')
       
                  return (
                    <FileCard
                      fileName={file?.fileName}
                      fileSize={file?.fileSize}
                      key={file?.key}
                      type='preview'
                      onClick={() => {
                        getDocument({ key: file?.key });
                      }} removeFile={undefined} />
                  );
                })}
              </div>
            )}
            {type !== 'fileUpload' && (
              <Typography
                className="view-program-program-details__field__value"
                as="p"
                variant="caption"
                fontWeight="regular"
              >
                {type === 'dateSelection' ? (
                  formatDate({ dateString: values })
                ) : type === 'timeSelection' ? (
                  convertTo12HourFormat(values)
                ) : (
                  <div
                    className="view-program-program-details__field__value_editor"
                    dangerouslySetInnerHTML={{ __html: values }}
                  />
                )}
              </Typography>
            )}
          </div>
        ))}
      </>}
     
      {isManageAccessModalOpen && (
        <ExtendDeadlineProgram
          isModalOpen={isManageAccessModalOpen}
          onModalClose={closeManageAccessModalOpen}
          programId={viewProgram?.id}
        />
      )}
    </div>
  );
};

export default ProgramDetails;
