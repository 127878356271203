import {
  TApplicant,
  TAppliedProgram,
  TGetProgramApplication,
  TGetProgramData,
  TProgramKeyMember,
  TProgramList,
  TProgramMember
} from 'src/services/apiEndpoint.types.ts';

export type TInitialState = {
  programs: TProgramList[];
  programsPageNumber: number;
  programsPerPage: number;
  moreProgramsLeft: boolean;
  // View program
  viewProgram: TGetProgramData;
  // Applicants
  applicants: TApplicant[];
  applicantsPageNumber: number;
  applicantsPerPage: number;
  moreApplicantsLeft: boolean;
  viewSubmittedApplicationStages: { stageTitle: string; stageNumber: number; id: string }[];
  // View application
  viewApplication: TGetProgramApplication;
  // Program members
  programMembers: TProgramMember[];
  programMembersPageNumber: number;
  programMembersPerPage: number;
  moreProgramMembersLeft: boolean;
  // Applied programs
  appliedPrograms: TAppliedProgram[];
  appliedProgramsPageNumber: number;
  appliedProgramsPerPage: number;
  moreAppliedProgramsLeft: boolean;
  // Program key members
  programKeyMembers: TProgramKeyMember[];
  programKeyMembersPageNumber: number;
  programKeyMembersPerPage: number;
  moreProgramKeyMembersLeft: boolean;
};

export const initialState: TInitialState = {
  programs: [],
  programsPageNumber: 1,
  programsPerPage: 50,
  moreProgramsLeft: true,
  // View program
  viewProgram: {
    id: '',
    companyId: '',
    userId: '',
    title: '',
    startDate: '',
    endDate: '',
    stages: 0,
    programDetails: [],
    applicationForm: { sections: [] },
    status: 'Active',
    createdAt: '',
    updatedAt: '',
    emailDump: false,
    deletedAt: ''
  },
  // Applicants
  applicants: [],
  applicantsPageNumber: 1,
  applicantsPerPage: 50,
  moreApplicantsLeft: true,
  viewSubmittedApplicationStages: [],
  // View application
  viewApplication: {
    application: {
      id: '',
      userId: '',
      programId: '',
      name: '',
      email: '',
      response: {
        sections: []
      },
      feedback: [],
      applicationStageId: 0,
      createdAt: '',
      updatedAt: '',
      deletedAt: '',
      User: {
        id: '',
        email: '',
        name: '',
        profilePic: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: '',
        isExternal: false
      },
      ProgramApplicationStage: { stageTitle: '', stageNumber: 0 }
    },
    companyId: '',
    isFinalStage: false,
    stage: {
      id: '',
      programId: '',
      stageNumber: 0,
      stageTitle: '',
      createdAt: '',
      updatedAt: '',
      deletedAt: ''
    },
    allstages: []
  },
  // Program members
  programMembers: [],
  programMembersPageNumber: 1,
  programMembersPerPage: 50,
  moreProgramMembersLeft: true,
  // Applied programs
  appliedPrograms: [],
  appliedProgramsPageNumber: 1,
  appliedProgramsPerPage: 50,
  moreAppliedProgramsLeft: true,
  // Program key members
  programKeyMembers: [],
  programKeyMembersPageNumber: 1,
  programKeyMembersPerPage: 50,
  moreProgramKeyMembersLeft: true
};
