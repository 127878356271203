import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';

const editProfileFormFields = {
  id: '0',
  name: '',
  role: '',
  introduction: '',
  about: '',
  gender: '',
  dob: '',
  address: '',
  country: '',
  state: '',
  city: '',
  cityQuery: '',
  zipCode: '',
  phoneNumber: '',
  linkedin: '',
  facebook: '',
  instagram: '',
  twitter: ''
};

const editProfileErrors = {
  id: '',
  name: '',
  role: '',
  introduction: '',
  about: '',
  gender: '',
  dob: '',
  address: '',
  country: '',
  state: '',
  city: '',
  cityQuery: '',
  zipCode: '',
  phoneNumber: '',
  linkedin: '',
  facebook: '',
  instagram: '',
  twitter: ''
};

export const genderOptions: TSelectCardRadioButton[] = [
  {
    label: 'Male',
    value: 'Male',
    id: 'male'
  },
  { label: 'Female', value: 'Female', id: 'female' },
  { label: 'Others', value: 'Others', id: 'others' }
];

export { editProfileFormFields, editProfileErrors };
