export default function convertTo12HourFormat(time: string): string {
    if (time) {
        const timeArray: string[] = time.split(':');
        const [hour, minute]: [string, string] = [timeArray[0] || '', timeArray[1] || ''];
        let hourNum: number = parseInt(hour);
        const suffix: string = hourNum >= 12 ? 'PM' : 'AM';

        hourNum = hourNum % 12 || 12;

        return `${hourNum}:${minute} ${suffix}`;
    } else {
        return '00:00'
    }
}
