// import { Typography } from 'src/components/common';
// import { TGetProgramDocumentRequest } from 'src/services/apiEndpoint.types.ts';
// import { getProgramDocument } from 'src/store/program/program.slice';
// import { useAppDispatch } from 'src/store/hooks';
// import { formatDate } from 'src/utils';
// import { useUrlParamValue } from 'src/hooks';
import { formatDate } from 'src/utils';
import './printTemplateSurvey.scss';

const PrintTemplateSurvey = ({ invoiceData }: any) => {
  const styles = {};

  return (
    <div className="page">
      <h1>Survey Response</h1>
      {invoiceData?.map((item, index) => (
        <div className="section" key={index}>
          <p className="question">{`Q${index + 1}.${item.label}`}</p>
          <p className="answer">
            ANS.
            {item.type !== 'fileUpload' && item.type !== 'textEditor' && (
              <span>
                {item.type === 'dateSelection'
                  ? formatDate({ dateString: item.value })
                  : item.value}
              </span>
            )}
            {item.type === 'textEditor' && <div dangerouslySetInnerHTML={{ __html: item.value }} />}
            {item.type === 'fileUpload' && item.value.length > 0 ? 'docs uploaded' : ''}
            {/* {item.type === 'tableColumns' && (
              <div>
                <table className="simple-table">
                  <thead>
                    <tr>
                      {headers.map((header: any, index) => (
                        <th key={index}>{header.trim()}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.value.map((item: any, rowIndex) => (
                      <tr key={rowIndex}>
                        {headers.map((header: string, colIndex) => (
                          <td key={colIndex}>{item[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )} */}
          </p>
        </div>
      ))}
    </div>
  );
};

export default PrintTemplateSurvey;
