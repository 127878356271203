import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { TProgramListRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { programList, resetPrograms } from 'src/store/program/program.slice';
import { Loader, Separator, Typography } from 'src/components/common';
import Button from 'src/components/button/Button';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';

import { TCohortsProps } from './cohortstypes';
import './cohorts.scss';
import CohortCard from '../cohortCard/CohortCard';
import { changeCreateCohortModalState } from 'src/store/common/common.slice';
import CreateCohortModal from './CreateCohort';
import { cohortList, resetCohort } from 'src/store/cohort/cohort.slice';
import { TCohort } from 'src/services/apiEndpoint.types.ts/cohort/cohort.types';

const Cohorts = ({ companyId, isViewerProgramCreator = true }: TCohortsProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const isModalOpen: boolean = useAppSelector<boolean>((store: any) => store.common.isCohortOpen);
  const { cohorts, isAccess, globalLoading }: any = useAppSelector<boolean>((store: any) => store.cohort);
  const dispatch = useAppDispatch();
  const selfCompany = useContext(SelfCompanyContext);

  // React state
  const { loggedInUserType, loggedInUserId } = getIdentities();
  function handelCreateCohort() {
    dispatch(changeCreateCohortModalState({ state: true, id: '' }))
  }
  useEffect(() => {
    if (companyId) {
      dispatch(cohortList({ incubatorId: companyId }))
    }
    return () => {
      dispatch(resetCohort())
    }
  }, [dispatch, companyId])

  if (globalLoading === 'cohort') {
    return <Loader />
  }
  return (
    <>
      {isModalOpen && <CreateCohortModal companyId={companyId} />}
      <div className="company-programs">
        <div className="company-programs__header">
          <Typography variant="subHeading-2" fontWeight="semiBold">
            Cohorts
          </Typography>
          {loggedInUserType === 'Company' && isAccess && selfCompany && isViewerProgramCreator && <div className="company-programs__header__buttons">
            <Button
              size="small"
              variant="primary"
              className="program-actions__actions__next"
              type="button"
              onClick={handelCreateCohort}
            >
              Create Cohort
            </Button>
          </div>}
        </div>
        <div className="company-programs__programs">
          {/* {loggedInUserType === 'Company' && selfCompany && <CreateProgramCard />} */}
          {isAccess && cohorts?.map((cohort: TCohort, index: number) => {
            return (
              <CohortCard {...cohort} key={index} companyId={companyId} />
            )
          })}
        </div>
        {!isAccess && (
          <div className='company-programs__not-access'>
            <p>Access denied</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Cohorts;
