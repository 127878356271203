import axios from 'axios';

import {
    TCreateIdeaGroupRequest,
    TCreateProgramGroupRequest,
    TDeleteIdeaGroupRequest,
    TDeleteProgramGroupRequest,
    TDislikeConversationMessageRequest,
    TDislikeIdeaConversationMessageRequest,
    TDownloadChatFileRequest,
    TEditIdeaGroupRequest,
    TEditProgramGroupRequest,
    TFindCreateConversationRequest,
    TGetChatFilePresignedUrlRequest,
    TGetIdeaConversationMessagesRequest,
    TGetIdeaConversationParticipantsRequest,
    TGetIdeaGroupParticipantsRequest,
    TGetIdeaGroupsRequest,
    TGetOneToOneConversationMessagesRequest,
    TGetProgramConversationMessagesRequest,
    TGetProgramConversationParticipantsRequest,
    TGetProgramGroupParticipantsRequest,
    TGetProgramGroupsRequest,
    TLikeConversationMessageRequest,
    TLikeIdeaConversationMessageRequest,
    TListOneToOneChatsRequest,
    TSearchUsersToAddIdeaGroupRequest,
    TSearchUsersToAddProgramGroupRequest,
    TUploadApplicationFileRequest,
    TUploadChatFileToS3Request
} from './apiEndpoint.types.ts';
import { APIResponse, BaseAPIService } from './base.api.service';
import { TListIdeaChatsRequest } from './apiEndpoint.types.ts/chat/listIdeaChats.api.endpoint.types.js';

export class CoohortApiServices extends BaseAPIService {
    private createCohortPath: string = '/cohort/create';
    private updateCohortPath: string = '/cohort/update';
    private cohorDashboardPath: string = '/cohort/dashboard';
    private checkCohortPath: string = '/cohort/checkEligibility';
    private getCohortListPath: string = '/cohort/get';
    private cohortTeamSearchPath: string = '/cohort/search/teamMember';
    private assignMentorPath: string = '/cohort/team/assignMentor';
    private unassignMentorPath: string = '/cohort/team/unassignMentor';
    private assignedMentorListPath: string = '/cohort/mentor/get';
    private mentorSearchPath: string = '/cohort/search/mentor';
    private createTeamPath: string = '/cohort/team/create';
    private updateTeamPath: string = '/cohort/team/update';
    private removeTeamPath: string = '/cohort/team/remove';
    private unassignTeamPath: string = '/cohort/team/unassignTeamMember';
    private getTeamPath: string = '/cohort/team/get';
    private myTeamPath: string = '/cohort/listMyTeams';
    private milestoneCreatePath: string = '/cohort/milestone/create';
    private milestoneUpdatePath: string = '/cohort/milestone/update';
    private milestoneGetPath: string = '/cohort/milestone/get';
    private deleteMilestonePath: string = '/cohort/milestone/delete';
    private getUploadedResourcesPath: string = '/cohort/uploads/get';
    private deleteResourcesPath: string = '/cohort/uploads/delete';
    private investmentPath: string = '/cohort/investmentTracking/get';
    private createInvestmentPath: string = '/cohort/investmentTracking/create';
    private myCohortPath: string = '/cohort/listMyCohort';
    private eventsPath: string = '/cohort/events';
    private serveyPath: string = '/cohort/servey';
    private publicServeyPath: string = '/cohort/servey/get';
    private publicEventPath: string = '/cohort/event/get';
    private publicCreateSurveyResponsePath: string = '/cohort/servey/response';
    private uploadApplicationFilePath: string = '/media/create';
    constructor() {
        super();
        this.axiosInstance = axios.create({ baseURL: process.env.REACT_APP_COHORT_ENDPOINT });
        this.configureInterceptors();
    }

    public async createCohort(
        createCohort: any
    ): Promise<APIResponse<any>> {
        const createCohortResponse: APIResponse<any> = await this.post(
            this.createCohortPath,
            createCohort,
            true
        );
        return createCohortResponse;
    }

    public async updateCohort(
        updateCohortRequest: any
    ): Promise<APIResponse<any>> {
        const updateCohortResponse: APIResponse<any> = await this.patch(
            this.updateCohortPath,
            updateCohortRequest,
            true
        );
        return updateCohortResponse;
    }

    public async checkCohortPermission(
        cohortPermissionRequest: any
    ): Promise<APIResponse<any>> {
        const cohortPermissionResponse: APIResponse<any> = await this.get(
            this.checkCohortPath,
            true,
            cohortPermissionRequest
        );
        return cohortPermissionResponse;
    }

    public async getCohortList(
        cohortListRequest: any
    ): Promise<APIResponse<any>> {
        const cohortListResponse: APIResponse<any> = await this.get(
            this.getCohortListPath,
            true,
            cohortListRequest
        );
        return cohortListResponse;
    }

    public async getMyCohort(
        myCohortRequest: any
    ): Promise<APIResponse<any>> {
        const myCohortResponse: APIResponse<any> = await this.get(
            this.myCohortPath,
            true,
            myCohortRequest
        );
        return myCohortResponse;
    }

    public async getCohortDashboard(
        cohortDashboardRequest: any
    ): Promise<APIResponse<any>> {
        const cohortdashBoardResponse: APIResponse<any> = await this.get(
            this.cohorDashboardPath,
            true,
            cohortDashboardRequest
        );
        return cohortdashBoardResponse;
    }

    public async getCohortTeamSearch(
        cohortSearchTeamRequest: any
    ): Promise<APIResponse<any>> {
        const cohortSearchTeamResponse: APIResponse<any> = await this.get(
            this.cohortTeamSearchPath,
            true,
            cohortSearchTeamRequest
        );
        return cohortSearchTeamResponse;
    }

    public async assignedMentorList(
        assignMentorListRequest: any
    ): Promise<APIResponse<any>> {
        const assignedMentorListResponse: APIResponse<any> = await this.get(
            this.assignedMentorListPath,
            true,
            assignMentorListRequest
        );
        return assignedMentorListResponse;
    }

    public async mentorSearch(
        mentorSearchRequest: any
    ): Promise<APIResponse<any>> {
        const mentorSearchResponse: APIResponse<any> = await this.get(
            this.mentorSearchPath,
            true,
            mentorSearchRequest
        );
        return mentorSearchResponse;
    }

    public async assignMentor(
        assignMentorRequest: any
    ): Promise<APIResponse<any>> {
        const assignMentorResponse: APIResponse<any> = await this.post(
            this.assignMentorPath,
            assignMentorRequest,
            true
        );
        return assignMentorResponse;
    }

    public async unassignMentor(
        unassignMentorRequest: any
    ): Promise<APIResponse<any>> {
        const unassignMentorResponse: APIResponse<any> = await this.post(
            this.unassignMentorPath,
            unassignMentorRequest,
            true
        );
        return unassignMentorResponse;
    }

    public async createTeam(
        createTeamRequest: any
    ): Promise<APIResponse<any>> {
        const createTeamResponse: APIResponse<any> = await this.post(
            this.createTeamPath,
            createTeamRequest,
            true
        );
        return createTeamResponse;
    }

    public async getTeams(
        teamRequest: any
    ): Promise<APIResponse<any>> {
        const teamResponse: APIResponse<any> = await this.get(
            this.getTeamPath,
            true,
            teamRequest
        );
        return teamResponse;
    }

    public async getMyTeam(
        myTeamRequest: any
    ): Promise<APIResponse<any>> {
        const myTeamResponse: APIResponse<any> = await this.get(
            this.myTeamPath,
            true,
            myTeamRequest
        );
        return myTeamResponse;
    }

    public async getMilestone(
        milestoneRequest: any
    ): Promise<APIResponse<any>> {
        const milestoneResponse: APIResponse<any> = await this.get(
            this.milestoneGetPath,
            true,
            milestoneRequest
        );
        return milestoneResponse;
    }

    public async deleteMilestone(
        deleteMilestoneRequest: any
    ): Promise<APIResponse<any>> {
        const deleteMilestoneResponse: APIResponse<any> = await this.delete(
            this.deleteMilestonePath,
            true,
            deleteMilestoneRequest
        );
        return deleteMilestoneResponse;
    }

    public async deleteResources(
        deleteResourceRequest: any
    ): Promise<APIResponse<any>> {
        const deleteResourceResponse: APIResponse<any> = await this.delete(
            this.deleteResourcesPath,
            true,
            deleteResourceRequest
        );
        return deleteResourceResponse;
    }

    public async updateMilestone(
        updateMilestoneRequest: any
    ): Promise<APIResponse<any>> {
        const updateMilestoneResponse: APIResponse<any> = await this.patch(
            this.milestoneUpdatePath,
            updateMilestoneRequest,
            true
        );
        return updateMilestoneResponse;
    }

    public async updateTeam(
        updateTeamRequest: any
    ): Promise<APIResponse<any>> {
        const updateTeamResponse: APIResponse<any> = await this.patch(
            this.updateTeamPath,
            updateTeamRequest,
            true
        );
        return updateTeamResponse;
    }

    public async removeTeam(
        removeTeamRequest: any
    ): Promise<APIResponse<any>> {
        const removeTeamResponse: APIResponse<any> = await this.patch(
            this.removeTeamPath,
            removeTeamRequest,
            true
        );
        return removeTeamResponse;
    }

    public async unassignTeamMember(
        unassignTeamRequest: any
    ): Promise<APIResponse<any>> {
        const unassignTeamResponse: APIResponse<any> = await this.post(
            this.unassignTeamPath,
            unassignTeamRequest,
            true
        );
        return unassignTeamResponse;
    }

    public async getInvestment(
        investmentRequest: any
    ): Promise<APIResponse<any>> {
        const investmentResponse: APIResponse<any> = await this.get(
            this.investmentPath,
            true,
            investmentRequest
        );
        return investmentResponse;
    }

    public async createInvestment(
        createInvestmentRequest: any
    ): Promise<APIResponse<any>> {
        const createInvestmentResponse: APIResponse<any> = await this.post(
            this.createInvestmentPath,
            createInvestmentRequest,
            true
        );
        return createInvestmentResponse;
    }
    
    public async createMilestone(
        createMilestoneRequest: any
    ): Promise<APIResponse<any>> {
        const createMilestoneResponse: APIResponse<any> = await this.post(
            this.milestoneCreatePath,
            createMilestoneRequest,
            true
        );
        return createMilestoneResponse;
    }

    public async getResources(
        resourcesRequest: any
    ): Promise<APIResponse<any>> {
        const resourcesResponse: APIResponse<any> = await this.get(
            this.getUploadedResourcesPath,
            true,
            resourcesRequest
        );
        return resourcesResponse;
    }

    public async createEvents(
        createEvents: any
    ): Promise<APIResponse<any>> {
        const createEventsResponse: APIResponse<any> = await this.post(
            this.eventsPath,
            createEvents,
            true
        );
        return createEventsResponse;
    }

    public async getEvents(
        eventRequest: any
    ): Promise<APIResponse<any>> {
        const eventResponse: APIResponse<any> = await this.get(
            this.eventsPath,
            true,
            eventRequest
        );
        return eventResponse;
    }

    public async createSurvey(
        createServey: any
    ): Promise<APIResponse<any>> {
        const createServeyResponse: APIResponse<any> = await this.post(
            this.serveyPath,
            createServey,
            true
        );
        return createServeyResponse;
    }

    public async createSurveyResponse(
        createServeyResponse: any
    ): Promise<APIResponse<any>> {
        const createServeyResponseResponse: APIResponse<any> = await this.post(
            this.publicCreateSurveyResponsePath,
            createServeyResponse,
            true
        );
        return createServeyResponseResponse;
    }

    public async getSurvey(
        serveyRequest: any
    ): Promise<APIResponse<any>> {
        const serveyResponse: APIResponse<any> = await this.get(
            this.serveyPath,
            true,
            serveyRequest
        );
        return serveyResponse;
    }

    public async getPublicSurveyForm(
        publicServeyRequest: any
    ): Promise<APIResponse<any>> {
        const publicServeyResponse: APIResponse<any> = await this.get(
            this.publicServeyPath,
            false,
            publicServeyRequest
        );
        return publicServeyResponse;
    }

    public async getPublicEvent(
        publicEventRequest: any
    ): Promise<APIResponse<any>> {
        const publicEventResponse: APIResponse<any> = await this.get(
            this.publicEventPath,
            false,
            publicEventRequest
        );
        return publicEventResponse;
    }

    public async getPublicSurveyResponse(
        publicServeyRequest: any
    ): Promise<APIResponse<any>> {
        const publicServeyResponse: APIResponse<any> = await this.get(
            this.publicCreateSurveyResponsePath,
            true,
            publicServeyRequest
        );
        return publicServeyResponse;
    }

    public async uploadApplicationFile<TUploadApplicationFileResponse>(
        uploadApplicationFileRequest: TUploadApplicationFileRequest
    ): Promise<APIResponse<TUploadApplicationFileResponse>> {
        const uploadApplicationFileResponse: APIResponse<TUploadApplicationFileResponse> =
            await this.post(this.uploadApplicationFilePath, uploadApplicationFileRequest, true);
        return uploadApplicationFileResponse;
    }
}
