import { useNavigate } from 'react-router-dom';
import { Button, Modal, Typography } from 'src/components/common';
import { hTractionLogoWithText } from 'src/assets/images';

import './applicationSuccessModal.scss';
import { TApplicationSuccessModalProps } from './applicationSuccessModal.types';

export const applicationSuccessModalId = 'applicationSuccessModalI';

const ApplicationSuccessModal = ({
  isModalOpen,
  handleCloseModal,
  ApplicationDraft,
  type = 'application'
}: TApplicationSuccessModalProps) => {
  // Hooks
  console.log(ApplicationDraft, 'ApplicationDraft')
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate('/sign-up');
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      className="ext-application-success-modal"
      isClosable={type !== 'survey'}
    >
      <img
        src={hTractionLogoWithText}
        alt="htraction logo"
        className="ext-application-success-modal__content__logo"
      />
      {ApplicationDraft && type !== 'survey'
        ? (
          <Typography
            as="span"
            variant="subHeading-2"
            fontWeight="medium"
            className="ext-application-success-modal__content__text"
          >
            You have successfully created draft application. To continue with the {' '}
            <Typography
              as="span"
              variant="subHeading-2"
              fontWeight="medium"
              className="ext-application-success-modal__content__text--highlight"
            >
              saved draft application on htraction
            </Typography>{' '}
            and get most out of  your experience, please {' '}
            <Typography
              as="span"
              variant="subHeading-2"
              fontWeight="medium"
              className="ext-application-success-modal__content__text--highlight"
            >
              sign up
            </Typography>{' '} to create an account
            {/* and get the most out of your experience, please{' '} */}
            {/* <Typography
          as="span"
          variant="subHeading-2"
          fontWeight="medium"
          className="ext-application-success-modal__content__text--highlight"
        >
          sign up
        </Typography>{' '} */}
            {/* to create an account. */}
          </Typography>

        )
        : type !== 'survey' ? (
          <Typography
            as="span"
            variant="subHeading-2"
            fontWeight="medium"
            className="ext-application-success-modal__content__text"
          >
            You have successfully applied to the program! To join{' '}
            <Typography
              as="span"
              variant="subHeading-2"
              fontWeight="medium"
              className="ext-application-success-modal__content__text--highlight"
            >
              htraction
            </Typography>{' '}
            and get the most out of your experience, please{' '}
            <Typography
              as="span"
              variant="subHeading-2"
              fontWeight="medium"
              className="ext-application-success-modal__content__text--highlight"
            >
              sign up
            </Typography>{' '}
            to create an account.
          </Typography>
        ) : (
          <Typography
            as="span"
            variant="subHeading-2"
            fontWeight="medium"
            className="ext-application-success-modal__content__text"
          >
            Thanks for your response
          </Typography>
        )}

      {type !== 'survey' && <div className="ext-application-success-modal__content__buttons">
        <Button variant="secondary" size="small" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" size="small" onClick={handleSignUp}>
          Sign Up
        </Button>
      </div>}
    </Modal>
  );
};

export default ApplicationSuccessModal;
