import { ChangeEvent, useState } from 'react';
import { ActionIcon, Card, Typography } from 'src/components/common';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { useGetFormikField, useToggleOpenModal } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';
import TextAreaEditor from 'src/components/common/formFields/textAreaEditor/TextAreaEditor';

import './dynamicField.scss';
import { TDynamicFieldProps } from './dynamicField.types';

import { TCustomField, TFieldType } from '../programDetails.types';
import AddEditProgramDetailsFields from '../addEditProgramDetailsFields/AddEditProgramDetailsFields';

const editProgramDetailsTextFieldsId = 'editProgramDetailsTextFields';
const TextEditorNew = ({ id, type, label, placeholder, deleteField, setCustomFields, errors }: TDynamicFieldProps) => {
  // Hooks
  const { getFormikField } = useGetFormikField();
  const toggleOpenModal = useToggleOpenModal();
  const modalId: string = useAppSelector((store) => store.common.modalId);

  // State
  const [editFieldModalData, setEditFieldModalData] = useState<{
    id: string;
    fieldType: TFieldType;
  }>({ id: '', fieldType: 'label' });

  // Constants
  const isEditProgramDetailsFieldsOpen = modalId === editProgramDetailsTextFieldsId;

  const [field, , helpers] = getFormikField({ id: id || '' });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label: field?.value?.label,
        type: 'textEditor',
        values: e.target.value,
        files: []
      });
    }
  };

  const handleLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label: e.target.value,
        type: 'textEditor',
        values: field?.value?.values,
        files: []
      });
    }
  };

  const changeFieldType = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setCustomFields((prev) => {
      const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
      const selectedField = currentFields.find((field) => field.id === id);
      if (selectedField) {
        selectedField.type = fieldType;
      }

      return currentFields;
    });

    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label: '',
        type: fieldType,
        values: '',
        files: []
      });
    }

    setEditFieldModalData({ id: '', fieldType: 'label' });
    handleToggleOpenEditFieldsModal();
  };

  const handleDeleteField = () => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue(undefined, false);
    }

    deleteField({ id });
  };

  const handleToggleOpenEditFieldsModal = () => {
    toggleOpenModal({ modalId: editProgramDetailsTextFieldsId });
  };

  const openChangeFieldModal = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setEditFieldModalData({ fieldType, id: id || '' });
    handleToggleOpenEditFieldsModal();
  };

  return (
    <Card key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Typography as="span" variant="caption" fontWeight="semiBold">
          Text Editor
        </Typography>
        <div className="dynamic-field__header__actions">
          <ActionIcon
            onClick={() => {
              openChangeFieldModal({ id, fieldType: type });
            }}
          >
            <EditIcon />
          </ActionIcon>
          <ActionIcon onClick={handleDeleteField}>
            <TrashIcon />
          </ActionIcon>
        </div>
      </div>
      <InputField
        label="Field title"
        id={`${id}-label`}
        variant={componentSizeVariantsEnum.SMALL}
        placeholder="Enter field title"
        type="text"
        onChange={handleLabelChange}
        value={field?.value?.label}
        errorMessage={errors?.[`${id}-label`]}
        isTouched={true}
      />
    <TextAreaEditor
        id={id}
        variant={componentSizeVariantsEnum.SMALL}
        placeholder={placeholder}
        type="text"
        onChange={handleChange}
        value={field?.value?.values}
        errorMessage={errors?.[id]}
        isTouched={true}
      />
      {isEditProgramDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isEditProgramDetailsFieldsOpen}
          onModalClose={handleToggleOpenEditFieldsModal}
          title="Edit Fields"
          currentType={editFieldModalData.fieldType}
          id={editFieldModalData.id}
          onSelection={changeFieldType}
        />
      )}
    </Card>
  );
};

export default TextEditorNew;
