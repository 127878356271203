import React, { useState } from 'react'
import { ConfirmModal, ProfilePicture, Typography } from 'src/components/common'
import { TrashIcon } from 'src/components/common/common.icons'
import { TAssignMentorList, TTeam } from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
import cx from './showAssined.module.scss';
import { Col, Dropdown } from 'react-bootstrap';
import { dotMenu } from 'src/assets/images';
import { useAppDispatch } from 'src/store/hooks';
import { changeCohortModalState, cohortAssignedMentor, handleUssignCohortMentor } from 'src/store/cohort/cohort.slice';
import { openPopup } from 'src/store/common/common.slice';
import { useUrlParamValue } from 'src/hooks';
import { textEllipsis } from 'src/utils';
interface TMentorCardProps {
    team: TTeam;
    mentorId: string;
    assignedTeamdMentorData: null | TAssignMentorList;
    setAssignedTeamdMentorData: React.Dispatch<React.SetStateAction<TAssignMentorList>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function TeamCard({ team, mentorId, assignedTeamdMentorData, setAssignedTeamdMentorData, setLoading, loading }: TMentorCardProps) {
    const { paramValue } = useUrlParamValue();
    const cohortId: string = paramValue({ paramName: 'id' }) as string;
    const [openConfirmation, setOpenConfirmationModal] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    function handleUnassign(team: TTeam) {
        const body = {
            cohortId,
            teamId: team.id,
            mentorId
        }
        setLoading(true)
        dispatch(handleUssignCohortMentor(body)).then((res) => {
            if (res?.status) {
                const modifiedTeam = assignedTeamdMentorData?.teams?.filter((prevTeam: TTeam) => prevTeam.id !== team.id)
                setAssignedTeamdMentorData({ ...assignedTeamdMentorData, teams: modifiedTeam })
                dispatch(cohortAssignedMentor({ cohortId }))
                dispatch(
                    openPopup({
                        popupMessage: res?.message,
                        popupType: 'success'
                    })
                );
                if (modifiedTeam?.length === 0) {
                    dispatch(changeCohortModalState({ state: false, mentor: null }));
                }
            } else {
                dispatch(
                    openPopup({
                        popupMessage: res?.message,
                        popupType: 'error'
                    })
                );
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    const nameWithEllipsis = textEllipsis({ charLimit: 50, text: team?.cohortTeamsTitle });
    return (
        <>
            <ConfirmModal
                openConfirmModal={openConfirmation}
                title="Are you sure you want to remove this team?"
                description=""
                onClose={() => {
                    setOpenConfirmationModal(false);
                }}
                onSubmit={() => handleUnassign(team)}
                submitText="Confirm"
                type="error"
            />
            <Col md={6} key={team?.id}>
                <div className={cx.teamCard}>
                    <div className={cx.TrashIcon}>
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdown-basic-6"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    height: '10px',
                                    width: '10px'
                                }}
                            >
                                <img src={dotMenu} className={cx.dots} alt="dot" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href="#"
                                >
                                    <p className={cx.dotDropInside} style={{ margin: '0' }} onClick={() => setOpenConfirmationModal(true)}>
                                        <span style={{ margin: '0' }}>Remove</span>
                                    </p>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <ProfilePicture
                        url={team?.profilePic}
                        fallback={team?.cohortTeamsTitle?.[0]?.toLocaleUpperCase()}
                    />
                    <div>
                        <Typography
                            as="p"
                            variant="body-2"
                            fontWeight="semiBold"
                            title={team?.cohortTeamsTitle}

                        >
                            {nameWithEllipsis.text}
                        </Typography>
                    </div>
                </div>
            </Col>
        </>
    )
}
