import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { componentSizeVariantsEnum } from 'src/constants';
import { InputField } from 'src/components/common/formFields';
import { dotMenu } from 'src/assets/images';
import { Button, Loader, ProfilePicture, Typography, ConfirmModal } from 'src/components/common';
import db from '../../CohortSidebarComponent/Dashboard/dashboard.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import cx from './cohortRelationship.module.scss';
import { TActionButton } from '../../viewCohort/viewCohort.types';
import { UploadIcon, TrashIcon } from 'src/components/common/common.icons';
import SubmittedReportSummeryModal from './SubmittedReport';
import { useDispatch, useSelector } from 'react-redux';
import { changeSubmittedReportModalState, openPopup } from 'src/store/common/common.slice';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import GlobalMilestoneView from 'src/components/company/programs/CohortSidebarComponent/GlobalMilestoneView/GlobalMilestoneView';
import TrackStepper from 'src/components/common/TrackStepper/ActionStepper';
import { TMilestone, TTask } from '../../CohortsCompoents/Milestone/milestonetypes';

import { useUrlParamValue, useToggleOpenModal } from 'src/hooks';
import {
  cohortInvestment,
  getCohortTeamSuccess,
  removeTeamMember,
  viewcohortTeam,
  getMilestone,
  createCohortInvestment,
  handleUssignCohortMentor
} from 'src/store/cohort/cohort.slice';
import { httpRequest } from 'src/services/commonApis';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { AppDispatch } from 'src/store/store';
import { textEllipsis } from 'src/utils';
import { TExpenses } from '../../CohortSidebarComponent/GlobalMilestone/updateTracking.types';

const confirmDeleteServiceModalId = 'confirmTeamMemeberModal';

interface TOnboardProps {
  handleBack: () => void;
  handleClick: (
    id: string,
    resource?: { key: 'string'; id: 'string' } | null,
    resourceId?: string
  ) => void;
}
export default function CohortRelationship({ handleBack, handleClick }: TOnboardProps) {
  const { paramValue } = useUrlParamValue();
  const { viewCohortTeam, viewCohort, investmentTrackingData }: any = useSelector(
    (state: any) => state?.cohort
  );
  const { loading }: any = useSelector((state: any) => state?.common);
  const { myCompany }: any = useAppSelector<boolean>((store: any) => store.company);
  const teamId: string = paramValue({ paramName: 'teamId' }) as string;
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const incubatorId: string = paramValue({ paramName: 'companyId' }) as string;
  const [teamMemberId, setTeamMemberId] = useState<string>('');
  const [updateLoading, setUpdateLoading] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const modalId = useAppSelector((store) => store.common.modalId);
  const isConfirmDeleteServiceModalOpen = modalId === confirmDeleteServiceModalId;
  const [funds, setFunds] = useState<string | number>('');
  const [expenseData, setExpenseData] = useState<TExpenses>({
    amount: '',
    userName: '',
    timestamp: new Date().toString()
  });
  const toggleOpenModal = useToggleOpenModal();
  const toggleOpenDeleteServiceModal = () => {
    toggleOpenModal({ modalId: confirmDeleteServiceModalId });
  };
  const milestoneData = useAppSelector((state: any) => state.cohort.Milestone);

  const steps: { title: string; id: string }[] = milestoneData?.map((milestone: TMilestone) => ({
    title: milestone.cohortMilestoneTitle,
    percentage: milestone.percentage
  }));
  function handleExpense(e: React.ChangeEvent<HTMLInputElement>) {
    setExpenseData({ ...expenseData, amount: +e.target.value });
  }
  const handleDeleteService = () => {
    dispatch(
      removeTeamMember({
        cohortId,
        incubatorId,
        teamMemberIds: [teamMemberId],
        teamId
      })
    );
  };
  const handleDeleteMentor = () => {
    const body = {
      cohortId,
      teamId,
      mentorId: teamMemberId
    }
    dispatch(handleUssignCohortMentor(body)).then((res) => {
      if (res?.status) {
        const payload = { cohortId, teamId, incubatorId }
        dispatch(viewcohortTeam(payload));
        dispatch(
          openPopup({
            popupMessage: res?.message,
            popupType: 'success'
          })
        );
      } else {
        dispatch(
          openPopup({
            popupMessage: res?.message,
            popupType: 'error'
          })
        );
      }
    })
  };

  const actionButtons: TActionButton[] = [
    { text: 'Edit Team', id: `On-Board`, onClick: handleAction },
    { text: 'Add Mentor', id: 'Mentor', onClick: handleAction },
    // { text: 'Generate Report', id: 'Generate Report', onClick: handleAction },
    { text: 'Submitted Report', id: 'Submitted Report', onClick: handleAction }
  ];

  const dragDropRef = useRef<HTMLDivElement>(null);
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const openImageFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  function handleAction(type: string, resource, resourceId?: string) {
    if (type === 'Submitted Report') {
      dispatch(changeSubmittedReportModalState(true));
    } else {
      handleClick(type, null, resourceId);
    }
  }
  const handleSubmit = async (type) => {
    setUpdateLoading(type);
    const data = {
      cohortTeamsId: teamId,
      cohortId,
      fundsAlloted: funds ? +funds : 0,
      expenses: type === 'fund' ? viewCohortTeam?.investmentTracking?.expenses ? viewCohortTeam?.investmentTracking?.expenses : [] : viewCohortTeam?.investmentTracking?.expenses
        ? [
          { ...expenseData, timestamp: new Date().toString(), userName: myCompany?.name || '' },
          ...viewCohortTeam?.investmentTracking?.expenses
        ]
        : [expenseData],
      additionalsAllotment: viewCohortTeam?.investmentTracking?.additionalsAllotment || []
    };
    dispatch(createCohortInvestment(data)).then((response) => {
      if (response.status) {
        dispatch(cohortInvestment({ cohortId }));
        dispatch(viewcohortTeam({ cohortId, teamId, incubatorId }));
        dispatch(
          openPopup({
            popupMessage: response?.message,
            popupType: 'success'
          })
        );
      }
    }).catch((err) => {
      dispatch(
        openPopup({
          popupMessage: err?.message,
          popupType: 'error'
        })
      );
    }).finally(() => {
      setUpdateLoading('');
    })
  };
  function handleFund(e: React.ChangeEvent<HTMLInputElement>) {
    setFunds(e.target.value);
  }

  const isInvestmentTracking = viewCohort?.templates?.investmentTracking;

  useEffect(() => {
    if (teamId && cohortId) {
      const payload = { cohortId, teamId, incubatorId };
      dispatch(viewcohortTeam(payload));
      dispatch(getMilestone(payload));
    }
  }, [teamId, cohortId]);
  useEffect(() => {
    if (cohortId) {
      dispatch(cohortInvestment({ cohortId }));
    }
  }, [cohortId]);
  useEffect(() => {
    if (viewCohortTeam?.investmentTracking) {
      setFunds(viewCohortTeam?.investmentTracking?.fundsAlloted || '');
      setExpenseData({
        ...expenseData,
        amount: viewCohortTeam?.investmentTracking?.expenses?.[0]?.amount || ''
      });
    }
  }, [viewCohortTeam]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SubmittedReportSummeryModal />
      <div className={`${cx.relationship}`}>
        <div className={`${cx.titleHeader}`}>
          <h5 className={`${cx.title}`}>{viewCohortTeam?.cohortTeamsTitle}</h5>
          <Button
            size="small"
            variant="primary"
            className="program-actions__actions__next"
            style={{ width: 'auto' }}
            type="button"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {viewCohortTeam?.about && <div className="aboutCard">
          <Row>
            <Col md={12} style={{ marginBottom: '8px' }}>
              <div className={cx.details}>
                <h5 className={`${cx.actionHeading}`}>About</h5>
                <p className={`${cx.about}`}>{viewCohortTeam?.about}</p>
              </div>
            </Col>
          </Row>
        </div>}
        <div className="insideCard">
          <Row>
            <Col md={12} style={{ marginBottom: '8px' }}>
              <Row>
                <Col md={12}>
                  <div className={cx.actionButtons}>
                    {actionButtons?.map((button: TActionButton) => {
                      return (
                        <Button
                          size="small"
                          variant="primary"
                          className={`program-acti,ons__actions__next ${cx.actionBtn}`}
                          type="button"
                          key={button.id}
                          onClick={() => button.onClick(button.id, null, teamId)}
                        >
                          {/* <span className={cx.addIcon}>
                                                        <MdAdd />
                                                    </span> */}
                          <span>{button.text} </span>
                        </Button>
                      );
                    })}
                  </div>
                </Col>
                {isInvestmentTracking && (
                  <Col md={12}>
                    <Row>
                      <Col md={2}></Col>
                      <Col md={4}>
                        <div className={`${cx.fundAction}`}>
                          <h5 className={`${cx.actionHeading}`}>Fund Alloted :</h5>
                          <InputField
                            required
                            label=""
                            value={funds}
                            onChange={handleFund}
                            style={{ width: '95px', height: '35px', textAlign: 'center' }}
                            id="initialfund"
                            variant={componentSizeVariantsEnum.SMALL}
                            placeholder="0"
                            type="number"
                          />
                          <Button
                            size="small"
                            variant="primary"
                            className={`program-acti,ons__actions__next ${cx.actionBtn}`}
                            type="button"
                            style={{ height: '35px', width: '70px', fontSize: '12px' }}
                            onClick={() => handleSubmit('fund')}
                          >
                            {updateLoading === 'fund' ? <Loader /> : <span>Update</span>}
                          </Button>
                        </div>
                      </Col>
                      {/* <Col md={4}>
                        <div className={`${cx.fundAction}`}>
                          <h5 className={`${cx.actionHeading}`}>Expense</h5>
                          <span className={cx.actionValue}>
                            {viewCohortTeam?.investmentTracking?.expenses?.[0]?.amount || '0'}
                          </span>
                        </div>
                      </Col> */}
                      <Col md={4}>
                        <div className={`${cx.fundAction}`}>
                          <h5 className={`${cx.actionHeading}`}>Expense</h5>
                          <InputField
                            required
                            label=""
                            value={expenseData?.amount}
                            onChange={handleExpense}
                            style={{ width: '95px', height: '35px', textAlign: 'center' }}
                            id="expense"
                            variant={componentSizeVariantsEnum.SMALL}
                            placeholder="0"
                            type="number"
                          />
                          <Button
                            size="small"
                            variant="primary"
                            className={`program-acti,ons__actions__next ${cx.actionBtn}`}
                            type="button"
                            style={{ height: '35px', width: '70px', fontSize: '12px' }}
                            onClick={() => handleSubmit('expense')}
                          >
                            {updateLoading === 'expense' ? <Loader /> : <span>Update</span>}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
            <Col md={12}>
              <div className={`${cx.details}`}>
                <Row>
                  <Col md={12}>
                    <Row>
                      {/* team list  */}
                      <Col md={6}>
                        <div className="insideCard">
                          <Row>
                            <Col md={12}>
                              <h5 className={`${cx.actionHeading}`}>Teams</h5>
                            </Col>
                            {viewCohortTeam?.teamMemberMappings?.map((team: any) => {
                              const nameWithEllipsis = textEllipsis({
                                charLimit: 25,
                                text: team?.teamMember?.name
                              });
                              return (
                                <Col md={6} key={team.teamMemberId} className={cx.TeamColumn}>
                                  <div className={cx.teamCard}>
                                    <ProfilePicture
                                      url={team?.teamMember?.profilePic}
                                      fallback={team?.teamMember?.name?.[0]?.toLocaleUpperCase()}
                                    />
                                    <div>
                                      <Typography
                                        as="p"
                                        variant="body-2"
                                        fontWeight="semiBold"
                                        title={team?.teamMember?.name}
                                      >
                                        {nameWithEllipsis.text}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => {
                                      toggleOpenDeleteServiceModal(); // Open the delete modal
                                      setTeamMemberId(team?.teamMemberId); // Set the team ID for deletion
                                    }}
                                    className={cx.TrashIcon}
                                  ></div>
                                  <div className={cx.TrashIcon}>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        id="dropdown-basic-6"
                                        style={{
                                          background: 'none',
                                          border: 'none',
                                          height: '10px',
                                          width: '10px'
                                        }}
                                      >
                                        <img src={dotMenu} className={cx.dots} alt="dot" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {/* <Dropdown.Item href="#">
                <p className={cx.dotDropInside}>
                  <span>Delete</span>
                </p>
              </Dropdown.Item> */}
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() => {
                                            toggleOpenDeleteServiceModal(); // Open the delete modal
                                            setTeamMemberId(team?.teamMemberId); // Set the team ID for deletion
                                          }}
                                        >
                                          <p className={cx.dotDropInside} style={{ margin: '0' }}>
                                            <span style={{ margin: '0' }}>Remove</span>
                                          </p>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>

                                  {isConfirmDeleteServiceModalOpen && (
                                    <ConfirmModal
                                      openConfirmModal={isConfirmDeleteServiceModalOpen}
                                      onClose={toggleOpenDeleteServiceModal}
                                      title="Are you sure you want to Remove this member from team?"
                                      type="error"
                                      onSubmit={handleDeleteService}
                                      submitText="Remove"
                                    />
                                  )}
                                </Col>
                              );
                            })}
                            {viewCohortTeam?.teamMemberMappings?.length === 0 && (
                              <div className="emptyContainerBoxes">
                                <p>No team added yet</p>
                              </div>
                            )}
                          </Row>
                        </div>
                      </Col>
                      {/* mentor list  */}
                      <Col md={6}>
                        <div className="insideCard">
                          <Row>
                            <Col md={12}>
                              <h5 className={`${cx.actionHeading}`}>Mentors</h5>
                            </Col>
                            {viewCohortTeam?.mentorMappings?.map((mentor: any) => {
                              const nameWithEllipsis = textEllipsis({
                                charLimit: 25,
                                text: mentor?.mentor?.name
                              });
                              return (
                                <Col md={6} key={mentor?.mentorId} className={cx.TeamColumn}>
                                  <div className={`${cx.teamCard}`}>
                                    <ProfilePicture
                                      url={mentor?.mentor?.profilePic}
                                      fallback={mentor?.mentor?.name?.[0]?.toLocaleUpperCase()}
                                    />
                                    <div>
                                      <Typography
                                        as="p"
                                        variant="body-2"
                                        fontWeight="semiBold"
                                        title={mentor?.mentor?.name}
                                      >
                                        {nameWithEllipsis.text}
                                      </Typography>
                                    </div>
                                    <div
                                      onClick={() => {
                                        toggleOpenDeleteServiceModal(); // Open the delete modal
                                        setTeamMemberId(mentor?.mentorId); // Set the team ID for deletion
                                      }}
                                      className={cx.TrashIcon}
                                    ></div>
                                    <div className={cx.TrashIcon}>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-basic-6"
                                          style={{
                                            background: 'none',
                                            border: 'none',
                                            height: '10px',
                                            width: '10px'
                                          }}
                                        >
                                          <img src={dotMenu} className={cx.dots} alt="dot" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            href="#"
                                            onClick={() => {
                                              toggleOpenDeleteServiceModal(); // Open the delete modal
                                              setTeamMemberId(mentor?.mentorId); // Set the team ID for deletion
                                            }}
                                          >
                                            <p className={cx.dotDropInside} style={{ margin: '0' }}>
                                              <span style={{ margin: '0' }}>Remove</span>
                                            </p>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>

                                    {isConfirmDeleteServiceModalOpen && (
                                      <ConfirmModal
                                        openConfirmModal={isConfirmDeleteServiceModalOpen}
                                        onClose={toggleOpenDeleteServiceModal}
                                        title="Are you sure you want to Remove this mentor from team?"
                                        type="error"
                                        onSubmit={handleDeleteMentor}
                                        submitText="Remove"
                                      />
                                    )}
                                  </div>
                                </Col>
                              );
                            })}
                            {viewCohortTeam?.mentorMappings?.length === 0 && (
                              <div className="emptyContainerBoxes">
                                <p>No Mentor assign yet</p>
                              </div>
                            )}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Col md={12}>
                              <h5 className={`${cx.actionHeading}`}>Milestones</h5>
                            </Col> */}
                {steps?.length > 0 && (
                  <div className={cx.dashboardCard}>
                    <Row>
                      <Col md={12}>
                        <div className={cx.stepper}>
                          <TrackStepper steps={steps} type="cohort" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <Row className="mt-3">
                  <GlobalMilestoneView type="team" />
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

const CustomLegend = (props: any) => {
  const { payload } = props;
  return (
    <ul className={db.legendList}>
      {payload.map((entry: any, index: any) => (
        <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 10, height: 10, backgroundColor: entry.color, marginRight: 5 }} />
          {/* {entry.value}: {entry.payload[entry.dataKey]} */}
          {entry.value}: 2000
        </li>
      ))}
    </ul>
  );
};
