import { TMilestone, TTask } from 'src/components/company/programs/CohortsCompoents/Milestone/milestonetypes';
import { TMember } from 'src/services/apiEndpoint.types.ts';
import { TCohort } from 'src/services/apiEndpoint.types.ts/cohort/cohort.types';
import { TDashboard } from 'src/services/apiEndpoint.types.ts/cohort/Dashboard.types';
import { TAssignMentorList, TMentor, TTeam } from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
export type TOptionItems = { text: string, onClick?: (id: string) => void }
export type TInitialState = {
    isAccess: boolean,
    isTeamModalOpen?: { state: boolean, mentors: TAssignMentorList };
    isInvestmentModal?: { state: boolean, teams: null | TTeam };
    isUpdateTaskModal?: { state: boolean, task: null | TTask, milestoneId: string, milestone?: TMilestone | null };
    cohortSubmittedApplicationModal: { state: boolean, applicationId: string },
    globalLoading?: string;
    dashboardData?: TDashboard | null;
    cohorts?: TCohort[];
    moreCohortsLeft?: boolean;
    cohortPageNumber?: number;
    cohortPerPage?: number;
    viewCohort: TCohort;
    searchedTeam: TMember[];
    cohortLoading?: boolean;
    cohortTeam?: TTeam[],
    viewUserCohort?: any,
    Milestone?: TMilestone[]
    viewCohortTeam?: TTeam | null,
    searchedMentor?: TMentor[],
    investmentTrackingData?: any;
    cohortResouces?: any;
    assignedMentors?: TAssignMentorList[],
    reportResources?: any;
    optionMenu?: { state: false, items: TOptionItems[] },
    events?: any,
    viewEvent?: any,
    serveysReports?: any[],
    viewSurvey?: any,
    publicServey?: any,
    publicEvent?: any,
    surveyResponseList?: any[];
    isViewResponse?: { state: boolean, data: any, id: string }
};

export const initialState: TInitialState = {
    isAccess: true,
    isTeamModalOpen: { state: false, mentors: null },
    isInvestmentModal: { state: false, teams: null },
    cohortSubmittedApplicationModal: { state: false, applicationId: '' },
    isUpdateTaskModal: { state: false, task: null, milestoneId: null, milestone: null },
    globalLoading: '',
    dashboardData: {
        cohortTeam: 0,
        mentors: 0,
        teamMembers: 0,
        investmentTracking: [],
        cohort: {
            id: '',
            incubatorId: '',
            cohortTitle: '',
            startDate: '',
            endDate: '',
            programObjective: '',
            profilePic: null,
            templates: {
                kpiTracking: false,
                investmentTracking: false
            },
            createdAt: null,
            updatedAt: null,
            deletedAt: null
        },
        milestones: [],
        upcomingEvents: [],
        deadlines: []
    },
    cohorts: [],
    moreCohortsLeft: false,
    cohortPageNumber: 0,
    cohortPerPage: 0,
    viewCohort: null,
    searchedTeam: [],
    cohortLoading: false,
    cohortTeam: [],
    viewCohortTeam: null,
    viewUserCohort: null,
    searchedMentor: [],
    investmentTrackingData: [],
    cohortResouces: [],
    assignedMentors: [],
    reportResources: [],
    optionMenu: { state: false, items: [] },
    events: [],
    viewEvent: null,
    serveysReports: [],
    viewSurvey: null,
    publicServey: null,
    publicEvent: null,
    surveyResponseList: [],
    isViewResponse: { state: false, data: [], id: '' }
};
