import { Typography } from 'src/components/common';
import { TGetProgramDocumentRequest } from 'src/services/apiEndpoint.types.ts';
import { getProgramDocument } from 'src/store/program/program.slice';
import { useAppDispatch } from 'src/store/hooks';
import { formatDate } from 'src/utils';
import { useUrlParamValue } from 'src/hooks';
import './applicationSectionView.scss';
import PrintIcon from '../../../../common/common.icons/PrintIcon';
import PrintTemplateSurvey from '../printTemplateSurvey/printTemplateSurvey';

import { TApplicationSectionViewProps } from './applicationSectionView.types';

import FileCard from '../../fileCard/FileCard';
const ApplicationSectionView = ({ sectionTitle, data = [] }: TApplicationSectionViewProps) => {
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const tab: string = paramValue({ paramName: 'tab' }) as string;
  const getDocument = ({ key }: { key: string }) => {
    const payload: TGetProgramDocumentRequest = { s3Key: key };

    dispatch(getProgramDocument(payload));
  };
  const handlePrint = () => {
    const printContent = document.getElementById(`printable-content`);
    console.log(printContent, 'princonte');
    const printWindow = window.open('', '_blank');
    printWindow?.document.write(`
      <html>
        <head>
          <title>Print Invoice</title>
          <style>
            ${document.getElementsByTagName('style')[0].innerHTML} /* This copies the existing styles */
          </style>
        </head>
        <body>${printContent?.innerHTML}</body>
      </html>
    `);
    printWindow?.document.close();
    printWindow?.print();
  };
  console.log(data, 'dataareportaaa');
  return (
    <div className="program-application-section-view">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="program-application-section-view__title"
      >
        {sectionTitle}
      </Typography>
      <div>
        {tab === 'View-Report-Survey' && (
          <div onClick={() => handlePrint()} style={{ display: 'flex', justifyContent: 'end' }}>
            <span className="program-application-section-view__printIcon" style={{ width: '3%' }}>
              <PrintIcon className="program-application-section-view" />{' '}
            </span>
          </div>
        )}

        <div id={`printable-content`} style={{ display: 'none' }}>
          <PrintTemplateSurvey invoiceData={data} />
        </div>
      </div>

      <div className="program-application-section-view__fields">
        {data?.map((field, index) => {
          const { label, value, type, files } = field;

          let headers: Array<string> = [];
          let tableData: Array<string> = [];
          if (type === 'tableColumns' && typeof value === 'string' && value?.length > 0) {
            tableData = JSON.parse(value);
            headers = Object.keys(tableData[0]);
          }
          return (
            <div key={index} className="program-application-section-view__fields__field">
              <Typography
                as="p"
                variant="body-2"
                fontWeight="semiBold"
                className="program-application-section-view__fields__field__label"
              >
                {/* {label} */}
                {`${index + 1}. ${label}`}
              </Typography>
              {type === 'fileUpload' && files && files?.length > 0 && (
                <div className="view-program-program-details__field__files">
                  {files.map((file) => {
                    return (
                      <FileCard
                        fileName={file?.fileName}
                        fileSize={file?.fileSize}
                        key={file?.key}
                        type="view"
                        onClick={() => {
                          getDocument({ key: file?.key });
                        }}
                        removeFile={undefined}
                      />
                    );
                  })}
                </div>
              )}
              {type !== 'fileUpload' && type !== 'textEditor' && type !== 'tableColumns' && (
                <Typography
                  className="view-program-program-details__field__value"
                  as="p"
                  variant="caption"
                  fontWeight="regular"
                >
                  {type === 'dateSelection' ? formatDate({ dateString: value }) : value}
                </Typography>
              )}
              {type === 'textEditor' && (
                <div
                  className="view-program-program-details__field__value"
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              )}
              {type === 'tableColumns' && (
                <div>
                  <table className="simple-table">
                    <thead>
                      <tr>
                        {headers.map((header: any, index) => (
                          <th key={index}>{header.trim()}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item: any, rowIndex) => (
                        <tr key={rowIndex}>
                          {headers.map((header: string, colIndex) => (
                            <td key={colIndex}>{item[header]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {/* <Typography
                as="p"
                variant="caption"
                fontWeight="regular"
                className="program-application-section-view__fields__field__value"
              >
                {value}
              </Typography> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ApplicationSectionView;
