import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import cx from './survey.module.scss';
import { Button, Dropdown, Loader, SubMenuModal, TDropdown, Typography } from 'src/components/common';
import DetailCard from '../DetailCard/DetailCard';
import { Col, Row } from 'react-bootstrap';
import CreateCard from '../CreateCard/CreateCard';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useToggleOpenDropdown, useUrlParamValue } from 'src/hooks';
import { getServey } from 'src/store/cohort/cohort.slice';
import { SingleChevronIcon } from 'src/components/common/common.icons';

interface TSurvey {
  handleBack: () => void;
  handleClick?: (id: string, resource?: null, resourceId?: string) => void
}
const programFilterLabels = {
  All: 'All',
  Report: 'Report',
  Survey: 'Survey'
};
const reportFilterDropdownId = 'programFilters';
function Survey({ handleBack, handleClick }: TSurvey) {
  const dispatch = useAppDispatch()
  const toggleOpenDropdown = useToggleOpenDropdown();
  const { paramValue } = useUrlParamValue();
  const { serveysReports }: any = useAppSelector((store: any) => store.cohort);
  const { loading }: { loading: boolean } = useAppSelector((store: any) => store.common);
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const companyId: string = paramValue({ paramName: 'companyId' }) as string;
  const [filterState, setFilterState] = useState<string>('All');

  const programsFilterDropdownItems = Object.keys(programFilterLabels).map((filter) => {
    const label: string = programFilterLabels[filter];
    return {
      label,
      onClick: () => {
        setFilterState(filter)
      },
      state: filter
    };
  });

  const reportFilterDropdownProps: TDropdown = {
    id: reportFilterDropdownId,
    size: 'large',
    alignment: 'left',
    items: programsFilterDropdownItems
  };
  const filterSurveyReport = serveysReports?.filter((item) => filterState === 'All' || item.type === filterState);
  const handleToggleOpenDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleOpenDropdown({ dropdownId: reportFilterDropdownId });
  };

  useEffect(() => {
    if (cohortId) {
      dispatch(getServey({ cohortId, incubatorId: companyId }))
    }
  }, [cohortId])

  if (loading) {
    return <Loader />
  }
  return (
    <div className={`${cx.report}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>Report / Survey</h5>
        <div className="program-filters">
          {' '}
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Filter:
          </Typography>
          <div className="program-filters__dropdown">
            <Button
              variant="secondary"
              size="small"
              endIcon={<SingleChevronIcon />}
              onClick={handleToggleOpenDropdown}
            >
              <Typography variant="body-2" fontWeight="semiBold">
                {filterState}
              </Typography>
            </Button>
            <SubMenuModal>
              <Dropdown {...reportFilterDropdownProps} />
            </SubMenuModal>
          </div>
          <Button
            size="small"
            variant="primary"
            className="program-actions__actions__next"
            style={{ width: 'auto' }}
            type="button"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
      </div>
      <Row>
        <Col md={3}>
          <CreateCard title='Create Report / Survey' onClick={() => handleClick('create-survey-reports')} />
        </Col>
        {filterSurveyReport?.map((item) => {
          return (
            <Col md={3} key={item.id}>
              <DetailCard title={item.title}
                status={item.type}
                description={item.objective}
                onClick={() => handleClick('View-Report-Survey', null, item.id)} />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default Survey
