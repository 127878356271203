import React, { useEffect, useState } from 'react';
import cx from './milestone.module.scss';
import { Button, Loader } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useUrlParamValue } from 'src/hooks';
import { Form, Formik, FieldArray, Field } from 'formik';
import milesoneCohortValidationSchema from './milesoneCohortValidationSchema';
import { initialValues } from './initialValue';
import { useDispatch } from 'react-redux';
import {
  createCohortMilestone,
  deleteCohortMilestone,
  getMilestone,
  getMilestoneDataSuccess
} from 'src/store/cohort/cohort.slice';
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
import { TMilestone, TTask } from './milestonetypes';
import { disablePastDate } from 'src/utils/DisablePastDate/disablePasteDate';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

interface TOnMilestoneProps {
  handleAction: (screen: string) => void;
}
const newData = {
  cohortId: '',
  userId: '',
  cohortMilestoneTitle: '',
  startDate: '',
  endDate: '',
  tasks: [{ taskTitle: '', startDate: '', endDate: '' }]
};
export default function MileStone({ handleAction }: TOnMilestoneProps) {
  const { paramValue } = useUrlParamValue();
  const dispatch = useAppDispatch();
  const {
    viewUserCohort,
    Milestone: milestoneList,
    globalLoading
  } = useAppSelector((state) => state.cohort);
  const userId: string = paramValue({ paramName: 'userId' }) as string;
  const teamId: string = paramValue({ paramName: 'teamId' }) as string;
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<string>('');
  const [initialMilestone, setInitialMilestone] = useState<{ milestones: TMilestone[] }>(
    initialValues
  );
  const [updateLoading, setUpdateLoading] = useState<string>('');
  const handleSubmit = async (values) => {
    setLoading(true);
    const data = { ...values.milestones?.[0], cohortId, userId: teamId };

    dispatch(createCohortMilestone(data)).then(async (response) => {
      if (response?.status) {
        const path = `cohort/milestone/get?cohortId=${cohortId}&userId=${teamId}`;
        const milestoneRes = await httpRequest(path, 'GET', null, 'json', 'cohort');
        const allMilestone = { milestones: [newData, ...milestoneRes?.data] };
        setInitialMilestone(allMilestone);
        setLoading(false);
        dispatch(
          openPopup({
            popupMessage: response.message,
            popupType: 'success'
          })
        );
      } else {
        setLoading(false)
        dispatch(
          openPopup({
            popupMessage: response?.message || 'milestone is not created',
            popupType: 'error'
          })
        );
        throw new Error(response?.message || 'milestone is not created')
      }
    }).catch((err) => {
      setLoading(false);
      dispatch(openPopup({
        popupMessage: err.message,
        popupType: 'error'
      }))
    })
  };
  async function deleteMilestone(milestone: TMilestone) {
    setDeleteLoading(milestone.cohortMilestoneTitle);
    dispatch(deleteCohortMilestone({ milestoneId: milestone.id })).then(response => {
      if (response?.status) {
        const filterMilestone = initialMilestone?.milestones.filter(
          (initialMilestone: TMilestone) => initialMilestone?.id !== milestone.id
        );
        setInitialMilestone({ milestones: filterMilestone });
        dispatch(
          openPopup({
            popupMessage: response?.message || 'milestone is deleted',
            popupType: 'message'
          })
        );
      } else {
        dispatch(
          openPopup({
            popupMessage: response?.message,
            popupType: 'error'
          })
        );
        throw new Error(response.message)
      }
      setDeleteLoading('');
    }).catch((err) => {
      dispatch(
        openPopup({
          popupMessage: err?.message,
          popupType: 'error'
        })
      );
      setDeleteLoading('');
    })
  }
  async function handleUpdate(milestone: TMilestone) {
    setUpdateLoading(milestone.id);
    const tasks = milestone?.tasks?.map((task: TTask) => ({
      taskTitle: task?.taskTitle,
      startDate: task?.startDate,
      endDate: task?.endDate
    }));
    const { cohortMilestoneTitle, startDate, endDate, cohortId, id } = milestone;
    const data = {
      cohortMilestoneTitle,
      startDate,
      id,
      endDate,
      cohortId,
      userId: teamId,
      milestoneId: milestone?.id,
      tasks
    };
    dispatch(createCohortMilestone(data)).then(async (response) => {
      setUpdateLoading('');
      if (response?.status) {
        const path = `cohort/milestone/get?cohortId=${cohortId}&userId=${teamId}`;
        const milestoneRes = await httpRequest(path, 'GET', null, 'json', 'cohort');
        const allMilestone = { milestones: [newData, ...milestoneRes?.data] };
        setInitialMilestone(allMilestone);
        dispatch(
          openPopup({
            popupMessage: 'Milestone updated successfully',
            popupType: 'success'
          })
        );
      } else {
        dispatch(
          openPopup({
            popupMessage: response?.message || 'milestone is not updated',
            popupType: 'error'
          })
        );
        throw new Error(response?.message || 'milestone is not updated')
      }
    }).catch((err) => {
      setUpdateLoading('');
      dispatch(
        openPopup({
          popupMessage: err?.message || 'milestone is not updated',
          popupType: 'error'
        })
      );
    })
  }
  useEffect(() => {
    const copyMilestone = JSON.parse(JSON.stringify(milestoneList || []));
    setInitialMilestone({ milestones: [newData, ...copyMilestone] });
  }, [milestoneList]);

  useEffect(() => {
    if (cohortId && teamId) {
      dispatch(getMilestone({ cohortId, teamId }));
    }
    return () => {
      dispatch(getMilestoneDataSuccess([]));
    };
  }, [cohortId, teamId]);
  if (globalLoading === 'milestone') {
    return <Loader />;
  }
  return (
    <div className={`${cx.onboard}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>Milestone</h5>
        <Button
          size="small"
          variant="primary"
          className="cohort-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={() => handleAction('relationship')}
        >
          Back
        </Button>
      </div>

      <Formik
        initialValues={initialMilestone}
        onSubmit={handleSubmit}
        validationSchema={milesoneCohortValidationSchema}
        enableReinitialize
      >
        {({ values, errors, touched }) => {
          console.log(values, errors, touched, 'errorTu');
          
          return (
            <Form noValidate>
              <FieldArray name="milestones">
                {() => (
                  <>
                    {values?.milestones?.map((milestone: TMilestone, i: number) => {
                      const milestoneminStartDate = viewUserCohort?.startDate;
                      const milestoneMinEndDate = milestone?.startDate;
                      return (
                        <div className="insideCard" key={i}>
                          <Row>
                            <Col md={3}>
                              <div className={`${cx.inputFields}`}>
                                <Field name={`milestones.${i}.cohortMilestoneTitle`}>
                                  {({ field }) => (
                                    <InputField
                                      {...field}
                                      required
                                      label="Milestone"
                                      id={`milestone-${i}`}
                                      variant={componentSizeVariantsEnum.SMALL}
                                      placeholder="Milestone"
                                      type="text"
                                      isTouched={touched?.milestones?.[i]?.cohortMilestoneTitle}
                                      errorMessage={errors?.milestones?.[i]?.cohortMilestoneTitle}
                                    />
                                  )}
                                </Field>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className={`${cx.inputFields}`}>
                                <Field name={`milestones.${i}.startDate`}>
                                  {({ field }) => (
                                    <InputField
                                      {...field}
                                      required
                                      min={milestoneminStartDate || disablePastDate()}
                                      label="Start Date"
                                      id={`startDate-${i}`}
                                      variant={componentSizeVariantsEnum.SMALL}
                                      type="date"
                                      isTouched={
                                        errors?.milestones?.[i]?.startDate &&
                                        touched?.milestones?.[i]?.startDate
                                      }
                                      errorMessage={errors?.milestones?.[i]?.startDate}
                                    />
                                  )}
                                </Field>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className={`${cx.inputFields}`}>
                                <Field name={`milestones.${i}.endDate`}>
                                  {({ field }) => (
                                    <InputField
                                      {...field}
                                      required
                                      min={milestoneMinEndDate || disablePastDate()}
                                      label="End Date"
                                      id={`endDate-${i}`}
                                      variant={componentSizeVariantsEnum.SMALL}
                                      type="date"
                                      isTouched={
                                        errors?.milestones?.[i]?.endDate &&
                                        touched?.milestones?.[i]?.endDate
                                      }
                                      errorMessage={errors?.milestones?.[i]?.endDate}
                                    />
                                  )}
                                </Field>
                              </div>
                            </Col>

                            <Col md={3}></Col>

                            {/* Nested FieldArray for tasks inside each milestone */}
                            <FieldArray name={`milestones.${i}.tasks`}>
                              {({ insert, remove, push }) => (
                                <>
                                  {milestone.tasks?.map((task: TTask, t: number) => {
                                    const minStartDate = milestone.startDate;
                                    const minEndDate = task?.startDate;
                                    const maxEndDate = milestone?.endDate;
                                    return (
                                      <div className={cx.task} key={t}>
                                        <Row>
                                          <Col md={3}>
                                            <div className={`${cx.inputFields}`}>
                                              <Field name={`milestones.${i}.tasks.${t}.taskTitle`}>
                                                {({ field }) => (
                                                  <InputField
                                                    {...field}
                                                    required
                                                    label="Task"
                                                    id={`task-${i}-${t}`}
                                                    variant={componentSizeVariantsEnum.SMALL}
                                                    placeholder="Task"
                                                    type="text"
                                                    isTouched={
                                                      touched?.milestones?.[i]?.tasks?.[t]
                                                        ?.taskTitle
                                                    }
                                                    errorMessage={
                                                      errors?.milestones?.[i]?.tasks?.[t]?.taskTitle
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                          </Col>
                                          <Col md={3}>
                                            <div className={`${cx.inputFields}`}>
                                              <Field name={`milestones.${i}.tasks.${t}.startDate`}>
                                                {({ field }) => (
                                                  <InputField
                                                    {...field}
                                                    required
                                                    min={minStartDate || disablePastDate()}
                                                    max={maxEndDate}
                                                    value={task?.startDate}
                                                    disabled={!milestone.startDate}
                                                    label="Start Date"
                                                    id={`startDate-${i}-${t}`}
                                                    variant={componentSizeVariantsEnum.SMALL}
                                                    type="date"
                                                    isTouched={
                                                      touched?.milestones?.[i]?.tasks?.[t]
                                                        ?.startDate
                                                    }
                                                    errorMessage={
                                                      errors?.milestones?.[i]?.tasks?.[t]?.startDate
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                          </Col>
                                          <Col md={3}>
                                            <div className={`${cx.inputFields}`}>
                                              <Field name={`milestones.${i}.tasks.${t}.endDate`}>
                                                {({ field }) => (
                                                  <InputField
                                                    {...field}
                                                    required
                                                    min={minEndDate || disablePastDate()}
                                                    max={maxEndDate}
                                                    disabled={!milestone.endDate}
                                                    value={task?.endDate}
                                                    label="End Date"
                                                    id={`endDate-${i}-${t}`}
                                                    variant={componentSizeVariantsEnum.SMALL}
                                                    type="date"
                                                    isTouched={
                                                      touched?.milestones?.[i]?.tasks?.[t]?.endDate
                                                    }
                                                    errorMessage={
                                                      errors?.milestones?.[i]?.tasks?.[t]?.endDate
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                          </Col>
                                          <Col md={3}>
                                            {t === milestone.tasks?.length - 1 ? (
                                              <div className={cx.addTask}>
                                                {milestone.tasks?.length > 1 && (
                                                  <span
                                                    className={cx.addRemoveIcon}
                                                    onClick={() => remove(t)}
                                                  >
                                                    <MdRemove />
                                                  </span>
                                                )}
                                                <span
                                                  className={cx.addRemoveIcon}
                                                  onClick={() =>
                                                    push({
                                                      taskTitle: '',
                                                      startDate: '',
                                                      endDate: ''
                                                    })
                                                  }
                                                >
                                                  <MdAdd />
                                                </span>
                                              </div>
                                            ) : (
                                              <>
                                                <div className={cx.addTask}>
                                                  <span
                                                    className={cx.addRemoveIcon}
                                                    onClick={() => remove(t)}
                                                  >
                                                    <MdRemove />
                                                  </span>
                                                </div>
                                              </>
                                            )}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </FieldArray>
                            <div className={`${cx.actionButton}`}>
                              {i === 0 && (
                                <button className={`${cx.addMilestone}`} type="submit">
                                  {loading ? <Loader /> : 'Add'}
                                </button>
                              )}
                              {i !== 0 && (
                                <>
                                  <div
                                    className={`${cx.addMilestone} ${cx.update}`}
                                    onClick={() => handleUpdate(milestone)}
                                  >
                                    {updateLoading === milestone.id ? <Loader /> : 'Update'}
                                  </div>
                                  <span
                                    className={`${cx.addMilestone}`}
                                    onClick={() => deleteMilestone(milestone)}
                                  >
                                    {deleteLoading !== '' && deleteLoading === milestone.cohortMilestoneTitle ? (
                                      <Loader />
                                    ) : (
                                      <MdRemove />
                                    )}
                                  </span>
                                </>
                              )}
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </>
                )}
              </FieldArray>

              {/* <Row>
                                <Col md={3}>
                                    <Button variant="primary" type="submit" size="small">
                                        {loading ? <Loader /> : 'Submit'}
                                    </Button>
                                </Col>
                            </Row> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
