import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal, ProfilePicture, Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import './updateInvestment.module.scss';
import investmentTrackingValidationSchema from './InvestmentTrackingValidationSchema';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { Col, Row } from 'react-bootstrap';
import {
  changeInvestmentModalState,
  cohortInvestment,
  cohortTeamList,
  createCohortInvestment
} from 'src/store/cohort/cohort.slice';
import { useUrlParamValue } from 'src/hooks';
import { TTeam } from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
import cx from './investmentTracking.module.scss';
import { MdAdd } from 'react-icons/md';
import { EditIcon } from 'src/components/common/common.icons';
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
import { TUpdateInvestmentTracking } from './updateTracking.types';
import { formatDate } from 'src/utils';
import formatINR from 'src/utils/formatINR';

const initialState = { fund: false, additional: { state: false, value: '' } };
const UpdateInvestement = () => {
  const { myCompany }: any = useAppSelector<boolean>((store: any) => store.company);
  const investmentModal: { state: boolean; team: TTeam; investment: any } = useAppSelector(
    (store: any) => store.cohort.isInvestmentModal
  );
  const { investmentTrackingData } = useAppSelector((state: any) => state?.cohort);
  const [updateLoading, setUpdateLoading] = useState<string>('');
  const { paramValue } = useUrlParamValue();
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const incubatorId: string = paramValue({ paramName: 'companyId' }) as string;
  const [editMode, setEditMode] = useState<{
    fund: boolean;
    additional: { state: boolean; value: number | string };
  }>(initialState);
  const [errors, setError] = useState('');
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(changeInvestmentModalState({ state: false, team: null }));
  };
  const [trackingData, setTrackingData] = useState<TUpdateInvestmentTracking>({
    cohortTeamsId: investmentModal?.team?.id,
    cohortId,
    fundsAlloted: '',
    expenses: [],
    additionalsAllotment: []
  });
  function handleCancel() {
    const currentTeamTrackingData = investmentTrackingData.find(
      (trackData: any) => trackData.team.id === investmentModal?.team?.id
    );
    if (currentTeamTrackingData) {
      setTrackingData({
        ...trackingData,
        fundsAlloted: currentTeamTrackingData.fundsAlloted,
        expenses: currentTeamTrackingData.expenses,
        additionalsAllotment: currentTeamTrackingData.additionalsAllotment
      });
    }
  }
  const handleSubmit = async (type: string) => {
    setError('');
    let data = {
      cohortTeamsId: investmentModal.team.id,
      cohortId,
      fundsAlloted: trackingData.fundsAlloted,
      expenses: trackingData.expenses,
      additionalsAllotment: trackingData.additionalsAllotment
    };
    if (type === 'additionalsAllotment') {
      if (!editMode.additional.value) {
        setError('this field is required');
        return;
      }
      const newAdditionalAllotment = {
        userName: myCompany?.name || '',
        amount: editMode.additional.value,
        timestamp: new Date().toString()
      };
      data = {
        ...data,
        additionalsAllotment: [newAdditionalAllotment, ...trackingData.additionalsAllotment]
      };
    }
    setUpdateLoading(type);
    dispatch(createCohortInvestment(data)).then((response) => {
      dispatch(cohortInvestment({ cohortId }));
      dispatch(cohortTeamList({ cohortId, incubatorId }));
      dispatch(
        openPopup({
          popupMessage: response?.message,
          popupType: 'success'
        })
      );
      setEditMode(initialState);
    }).catch((err) => {
      dispatch(
        openPopup({
          popupMessage: err?.message,
          popupType: 'error'
        })
      );
    }).finally(() => {
      setUpdateLoading('');
    })
  };
  const isDeactivated = investmentModal?.team?.status === 'Deactivated';
  useEffect(() => {
    if (investmentTrackingData.length > 0) {
      const currentTeamTrackingData = investmentTrackingData.find(
        (trackData: any) => trackData.team.id === investmentModal?.team?.id
      );
      if (currentTeamTrackingData) {
        setTrackingData({
          ...trackingData,
          fundsAlloted: currentTeamTrackingData.fundsAlloted,
          expenses: currentTeamTrackingData.expenses,
          additionalsAllotment: currentTeamTrackingData.additionalsAllotment
        });
      }
    }
  }, [investmentTrackingData]);
  const initialFund = trackingData?.fundsAlloted || 0;
  const additionalFund = trackingData?.additionalsAllotment?.reduce(
    (acc, additionalFund) => acc + +additionalFund?.amount,
    0
  );
  const totalFund = +initialFund + +additionalFund
  return (
    <Modal
      isModalOpen={investmentModal.state}
      onModalClose={closeModal}
      title={`Investment Tracking : ${investmentModal.team?.cohortTeamsTitle}`}
      className="edit-cohort-modal"
    >
      <div className="edit-cohort">
        <div className={`${cx.investementTracking} ${cx.autoHeight}`}>
          <Row>
            <Col md={12}>
              <div className="insideCard transparent">
                <div className={cx.itemContainer}>
                  <Row className="mb-3">
                    <Col md={3}>
                      <div className={`${cx.itemLabel} ${cx.display}`}>
                        <label className={`${cx.label}`}>Total Fund</label>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className={`${cx.itemLabel} ${cx.display}`}>
                        <label className={`${cx.label}`}>
                          {formatINR(totalFund)}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={3}>
                      <div className={`${cx.itemLabel} ${cx.display}`}>
                        <label className={`${cx.label}`}>Initial Fund</label>
                      </div>
                    </Col>
                    <Col md={2}>
                      {editMode.fund && (
                        <div className={`${cx.inputFields}`}>
                          <InputField
                            label=""
                            style={{ width: '100%' }}
                            value={trackingData.fundsAlloted || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setTrackingData({ ...trackingData, fundsAlloted: +e.target.value })
                            }
                            id="fund alloted"
                            variant={componentSizeVariantsEnum.SMALL}
                            placeholder="0"
                            type="number"
                          />
                        </div>
                      )}
                      {!editMode.fund && (
                        <div className={`${cx.itemLabel} ${cx.display}`}>
                          <label className={`${cx.label}`}>
                            {formatINR(+trackingData.fundsAlloted) || '0'}
                          </label>
                        </div>
                      )}
                    </Col>
                    {editMode.fund && (
                      <>
                        <Col md={2}>
                          <div className={`${cx.inputFields}`}>
                            <Button
                              size="small"
                              variant="primary"
                              className="program-actions__actions__next"
                              type="button"
                              style={{ width: 'auto' }}
                              onClick={() => handleSubmit('fundsAlloted')}
                            >
                              {updateLoading === 'fundsAlloted' ? <Loader /> : 'Update'}
                            </Button>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className={`${cx.inputFields}`}>
                            <Button
                              size="small"
                              variant="primary"
                              className="program-actions__actions__next"
                              type="button"
                              style={{ width: 'auto' }}
                              onClick={() => {
                                handleCancel()
                                setEditMode({ ...editMode, fund: false })
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                    {!editMode.fund && !isDeactivated && (
                      <Col md={2}>
                        <EditIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => setEditMode({ ...editMode, fund: true })}
                        />
                      </Col>
                    )}
                  </Row>

                  <Row className="mb-3">
                    <Col md={3}>
                      <div className={`${cx.itemLabel} ${cx.display}`}>
                        <label className={`${cx.label}`}>Expense</label>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className={`${cx.itemLabel} ${cx.display}`}>
                        <label className={`${cx.label}`}>
                          {formatINR(trackingData.expenses?.length > 0 ? +trackingData.expenses?.[0]?.amount : 0)}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <div className={`${cx.itemLabel}`}>
                        <label className={`${cx.label}`}>Additional Fund</label>
                      </div>
                    </Col>
                    <Col md={9}>
                      <div className={`${cx.itemLabel} ${cx.prices}`}>
                        {trackingData.additionalsAllotment?.map(
                          (additionalFund: any, a: number) => {
                            return (
                              <label key={a} className={`${cx.label}`}>
                                <span>{formatINR(+additionalFund?.amount) || '0'}</span>{' '}
                                <span>
                                  {formatDate({ dateString: additionalFund?.timestamp }) || ''}{' '}
                                  {`(By ${additionalFund?.userName})`}
                                </span>
                              </label>
                            );
                          }
                        )}
                        {trackingData.additionalsAllotment.length === 0 && (
                          <label className={`${cx.label}`}>
                            <span>0</span>
                          </label>
                        )}
                      </div>
                    </Col>
                    <Col md={3}></Col>
                    <Col md={9}>
                      {editMode.additional.state && (
                        <Row>
                          <Col md={4}>
                            <div className={`${cx.inputFields}`}>
                              <InputField
                                label=""
                                required
                                style={{ width: '100%' }}
                                value={editMode.additional.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setEditMode({
                                    ...editMode,
                                    additional: {
                                      ...editMode.additional,
                                      value: e.currentTarget.value
                                    }
                                  })
                                }
                                id="fund alloted"
                                variant={componentSizeVariantsEnum.SMALL}
                                placeholder="0"
                                type="number"
                                errorMessage={errors}
                                isTouched={errors !== '' && !editMode.additional.value}
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className={`${cx.inputFields} ${errors ? cx.bottonMargin : ''}`}>
                              <Button
                                size="small"
                                variant="primary"
                                className="program-actions__actions__next"
                                type="button"
                                style={{ width: 'auto' }}
                                onClick={() => handleSubmit('additionalsAllotment')}
                              >
                                {updateLoading === 'additionalsAllotment' ? <Loader /> : 'Add'}
                              </Button>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className={`${cx.inputFields} ${errors ? cx.bottonMargin : ''}`}>
                              <Button
                                size="small"
                                variant="primary"
                                className="program-actions__actions__next"
                                type="button"
                                style={{ width: 'auto' }}
                                onClick={() => {
                                  handleCancel()
                                  setEditMode({
                                    ...editMode,
                                    additional: { value: 0, state: false }
                                  })
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                      {!editMode.additional.state && !isDeactivated && (
                        <Button
                          size="small"
                          variant="primary"
                          className="program-actions__actions__next"
                          type="button"
                          style={{ width: '80px' }}
                          onClick={() =>
                            setEditMode({
                              ...editMode,
                              additional: { ...editMode.additional, state: true }
                            })
                          }
                        >
                          <MdAdd className={cx.add} />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateInvestement;
