import React, { useEffect } from 'react';
import cx from '../../viewCohort/viewCohort.module.scss';
import { Button, Loader, ProfilePicture, Typography } from 'src/components/common';
import { Col, Row, Tooltip } from 'react-bootstrap';
import TrackStepper from 'src/components/common/TrackStepper/ActionStepper';
import db from './dashboard.module.scss';
import { TActionButton } from '../../viewCohort/viewCohort.types';
import { MdAdd } from 'react-icons/md';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useUrlParamValue } from 'src/hooks';
import { getDashboard, getMilestone } from 'src/store/cohort/cohort.slice';
import { TMilestone, TTask } from '../../CohortsCompoents/Milestone/milestonetypes';
import { TInvestemntTracking } from 'src/services/apiEndpoint.types.ts/cohort/Dashboard.types';
import GlobalMilestoneView from 'src/components/company/programs/CohortSidebarComponent/GlobalMilestoneView/GlobalMilestoneView';

export default function CohortDashboard({
  handleClick,
  actionButtons
}: {
  handleClick: (id: string) => void;
  actionButtons: TActionButton[];
}) {
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const {
    cohortTeam,
    teamMembers,
    mentors,
    investmentTracking,
    milestones,
    cohort,
    upcomingEvents,
    deadlines = []
  } = useAppSelector((state: any) => state.cohort.dashboardData);
  const { loading } = useAppSelector((state: any) => state.common);
  const cohortId = paramValue({ paramName: 'id' }) || '';
  const incubatorId = paramValue({ paramName: 'companyId' }) as unknown as string;
  // Dispatch the action to fetch data on component mount
  const steps: { title: string; id: string }[] = milestones?.map((milestone: TMilestone) => ({
    title: milestone.cohortMilestoneTitle,
    percentage: milestone.percentage
  }));
  const {
    fundsAlloted = 0,
    expenses = 0,
    additionalsAllotment = 0
  } = investmentTracking?.reduce(
    (totals: any, investment: TInvestemntTracking) => {
      totals.fundsAlloted += investment?.fundsAlloted || 0;
      totals.expenses += investment?.expenses?.[0]?.amount || 0;
      totals.additionalsAllotment +=
        investment?.additionalsAllotment?.reduce((acc, val) => acc + val.amount, 0) || 0;
      return totals;
    },
    { fundsAlloted: 0, expenses: 0, additionalsAllotment: 0 }
  ) || {}; // Add fallback in case investmentTracking is undefined
  const chartData =
    investmentTracking?.map((investment: TInvestemntTracking) => {
      const fundAlloted = investment?.fundsAlloted || 0;
      const expense = investment?.expenses?.[0]?.amount || 0;
      const additionalAllotment =
        investment?.additionalsAllotment?.reduce((acc, val) => acc + val.amount, 0) || 0;
      const toalFunds = fundAlloted + additionalAllotment;
      return {
        cohortTeamsTitle: investment.team?.cohortTeamsTitle || 'Unknown Team',
        fundsAlloted: toalFunds,
        expenses: expense
      };
    }) || [];
  const formatINR = (amount: number) => {
    return new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 2 // This is optional, for up to 2 decimal places if needed
    }).format(amount);
  };
  const totalFundAlloted: number = fundsAlloted + additionalsAllotment;
  const remainingFund = totalFundAlloted - expenses;
  useEffect(() => {
    if (incubatorId && cohortId) {
      dispatch(getDashboard({ incubatorId, cohortId }));
      dispatch(getMilestone({ incubatorId, cohortId }));
      // dispatch(getMilestone(({incubatorId, cohortId }));
    }
  }, []);
  const isInvestmentTracking =
    cohort?.templates?.investmentTracking || cohort?.templates?.kpiTracking;
  if (loading) {
    return <Loader />;
  }
  return (
    <div className={cx.dashboard}>
      {/* Dashboard Cards */}
      <div className={`${cx.dashboardCard}`}>
        <Row>
          <Col md={4}>
            <div className={cx.dashboardCardItem}>
              <div className={cx.dashboardItem}>
                <span className={cx.itemNumber}>{cohortTeam || '0'}</span>
                <span className={cx.itemName}>Startups/Teams</span>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className={cx.dashboardCardItem}>
              <div className={cx.dashboardItem}>
                <span className={cx.itemNumber}>{teamMembers || '0'}</span>
                <span className={cx.itemName}>Team Members</span>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className={cx.dashboardCardItem}>
              <div className={cx.dashboardItem}>
                <span className={cx.itemNumber}>{mentors || '0'}</span>
                <span className={cx.itemName}>Mentors Assigned</span>
              </div>
            </div>
          </Col>
          {isInvestmentTracking && (
            <>
              <Col md={4}>
                <div className={cx.dashboardCardItem}>
                  <div className={cx.dashboardItem}>
                    <span className={cx.itemNumber}>{formatINR(totalFundAlloted)}</span>
                    <span className={cx.itemName}>Funds Allotted</span>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className={cx.dashboardCardItem}>
                  <div className={cx.dashboardItem}>
                    <span className={cx.itemNumber}>{formatINR(expenses)}</span>
                    <span className={cx.itemName}>Expense in Total</span>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className={cx.dashboardCardItem}>
                  <div className={cx.dashboardItem}>
                    <span className={cx.itemNumber}>{formatINR(remainingFund)}</span>
                    <span className={cx.itemName}>Fund Balance</span>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>

      {/* Track Stepper */}
      {steps?.length > 0 && (
        <div className={cx.dashboardCard}>
          <Row>
            <Col md={12}>
              <div className={cx.stepper}>
                <TrackStepper steps={steps} type="cohort" />
              </div>
            </Col>
          </Row>
        </div>
      )}
      {/* Milestones */}
      <div className={cx.dashboardCard}>
        <h5 className={cx.chatHeading}>Milestones</h5>
        {milestones?.length > 0 ? (
          <GlobalMilestoneView />
        ) : (
          <h5 className={cx.emptyItem}>Create your Cohort/Program level Milestones</h5>
        )}
      </div>

      {/* Upcoming Events and Deadlines */}
      <div className={cx.dashboardCard}>
        <Row>
          <Col md={6}>
            <h5 className={cx.chatHeading}>Upcoming Events</h5>
            <div className="insideCard">
              <ul className={cx.activityList}>
                {upcomingEvents?.map((event: any) => <li key={event.id}>{event.title}</li>)}
                {(!upcomingEvents || upcomingEvents?.length === 0) && (
                  <li className={cx.emptyItem}>No Events yet</li>
                )}
              </ul>
            </div>
          </Col>
          <Col md={6}>
            <h5 className={cx.chatHeading}>Deadlines Approaching</h5>
            <div className="insideCard">
              <ul className={cx.activityList}>
                {deadlines?.map((deadline: any) => <li key={deadline.id}>{deadline.title}</li>)}
                {deadlines?.length === 0 && <li className={cx.emptyItem}>No deadline yet</li>}
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      {/* Chart */}
      {investmentTracking?.length > 0 && (
        <div className={`${cx.dashboardCard} ${cx.overflowNone}`}>
          <Row>
            <Col md={12}>
              <div className={cx.chartContainer}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="cohortTeamsTitle" angle={-15} textAnchor='end'/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="fundsAlloted" fill="#fa4616" name="Funds Allotted" />
                    <Bar dataKey="expenses" fill="#ffe5d5" name="Total Expense" />
                    {/* <Bar dataKey="additionalsAllotment" fill="#82ca9d" name="Fund Balance" /> */}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
