import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useTimer } from 'react-timer-hook';
import { Button, Modal, Typography, VerificationCode } from 'src/components/common';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { resendOtp, verifyOtp } from 'src/store/externalApplication/externalApplication.slice';
import { useUrlParamValue } from 'src/hooks';
import {
  TApplicantEmailVerificationInitialValues,
  TApplicantEmailVerificationProps
} from './applicantEmailVerification.types';
import applicationEmailVerificationValidationSchema from './applicationEmailVerification.validationSchema';
import './applicantEmailVerification.scss';

const timerDurationInSeconds = 30;
export const verifyEternalUserEmailLoaderId = ' verifyEternalUserEmailLoaderId';

const time = new Date();
time.setSeconds(time.getSeconds());

const ApplicantEmailVerification = ({
  isModalOpen,
  handleCloseModal,
  applicantDetails,
  handleToggleEmailVerificationModal,
  handleNext
}: TApplicantEmailVerificationProps) => {
  // Hooks
  const { paramValue } = useUrlParamValue();
  const { loadingId } = useAppSelector((store) => store.common);
  const dispatch = useAppDispatch();
  const programId: string = paramValue({ paramName: 'id' }) as string;
  // State
  const [otp, setOTP] = useState<string[]>(['', '', '', '', '', '']);
  const [disableResendButton, setDisableResendButton] = useState<boolean>(false);
  const [startResendSignupOtpTimer, setStartResendSignupOtpTimer] = useState<boolean>(false);

  const applicantEmailVerificationInitialValues: TApplicantEmailVerificationInitialValues = {
    otp
  };

  const resendOTPSubmit = async () => {
    // request new OTP email verification
    const payload: any = {
      email: applicantDetails?.email,
      name: applicantDetails?.name
    };
    dispatch(resendOtp(payload)).then((response?: number) => {
      if (response === 200) {
        setStartResendSignupOtpTimer(true);
      }
    });
  };

  // Timer
  const { seconds, restart, pause, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      setStartResendSignupOtpTimer(false);
      setDisableResendButton(false);
    }
  });

  const handleEmailVerification = (values: TApplicantEmailVerificationInitialValues) => {
    const payload: any = {
      email: applicantDetails?.email,
      programId: programId,
      otp: values?.otp?.join('')
    };

    dispatch(verifyOtp(payload)).then((response?: number) => {
      if (response === 200) {
        handleToggleEmailVerificationModal();
        handleNext();
      }
    });
  };

  useEffect(() => {
    if (startResendSignupOtpTimer) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + timerDurationInSeconds);
      restart(time);
      setDisableResendButton(true);
    }
  }, [startResendSignupOtpTimer]);

  useEffect(() => {
    /*
     ** Stop the timer on initial load
     */
    pause();
  }, []);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      className="applicant-email-verification"
      title="Email Verification"
    >
      {' '}
      <Formik
        initialValues={applicantEmailVerificationInitialValues}
        validationSchema={applicationEmailVerificationValidationSchema}
        onSubmit={handleEmailVerification}
        enableReinitialize
      >
        {({ errors }) => {
          return (
            <Form className="applicant-email-verification__form">
              <Typography as="p" variant="caption" fontWeight="regular">
                A six digit verification code has been sent to your entered new email address.
              </Typography>
              <div className="applicant-email-verification__form__verification-code">
                <VerificationCode
                  label="Enter verification code"
                  id="otp"
                  fieldsCount={6}
                  className="applicant-email-verification__form__verification-code__input"
                  value={otp}
                  setVerificationCode={setOTP}
                />{' '}
                {errors?.otp && (
                  <ErrorMessage message={'Please enter the code sent to your email address'} />
                )}{' '}
                <div className="applicant-email-verification__form__verification-code__resend-code">
                  <Typography
                    as="span"
                    variant="caption"
                    fontWeight="semiBold"
                    className={classNames(
                      'applicant-email-verification__form__verification-code__resend-code__link',
                      {
                        'applicant-email-verification__form__verification-code__resend-code__link--disabled':
                          disableResendButton
                      }
                    )}
                    onClick={resendOTPSubmit}
                  >
                    Re-send verification code
                  </Typography>

                  {isRunning && (
                    <Typography as="span" variant="caption" fontWeight="semiBold">
                      00:{seconds}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="applicant-email-verification__form__buttons">
                <Button size="small" variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="primary"
                  type="submit"
                  loading={loadingId === verifyEternalUserEmailLoaderId}
                >
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ApplicantEmailVerification;
