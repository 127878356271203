import React, { useEffect, useMemo } from 'react';
import cx from './viewReportSurvey.module.scss';
import {
  Button,
  Dropdown,
  InfiniteScroll,
  Loader,
  SubMenuModal,
  Tabs,
  TDropdown,
  TDropdownOptions,
  TTabItem
} from 'src/components/common';
import { TViewProgramTabs } from 'src/pages/viewProgram/viewProgram.types';
import {
  useCopyToClipboard,
  useToggleOpenDropdown,
  useToggleOpenModal,
  useUrlParamValue
} from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import CommonDetail from '../CommonDetail/CommonDetail';
import SubmittedApplications from '../SubmittedApplications/SubmittedApplications';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  getSurveyResponse,
  getViewSurvey,
  viewResponseStateChange,
  viewSurveyResponseSuccess,
  viewSurveySuccess
} from 'src/store/cohort/cohort.slice';
import { ShareIcon } from 'src/components/common/common.icons';
import { formatDate } from 'src/utils';
import { ApplicationSectionView } from '../../viewApplication';
interface TViewReportSurveyProps {
  handleBack: () => void;
}
export default function ViewReportSurvey({ handleBack }: TViewReportSurveyProps) {
  const { paramValue } = useUrlParamValue();
  const toggleOpenDropdown = useToggleOpenDropdown();
  const { copyToClipboard } = useCopyToClipboard();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { viewSurvey, surveyResponseList, isViewResponse }: any = useAppSelector(
    (state) => state.cohort
  );
  const { loading }: { loading: boolean; modalId: string } = useAppSelector(
    (state) => state.common
  );

  // query parmas
  const currentTab: TViewProgramTabs = paramValue({ paramName: 'key' }) as TViewProgramTabs;
  const companyId: string = paramValue({ paramName: 'companyId' }) as string;
  const id: string = paramValue({ paramName: 'resourceId' }) as string;
  const cohortId: string = paramValue({ paramName: 'id' }) as string;

  // constant
  const shareSurveyId: string = `shareProgram-${id}`;
  const applications = surveyResponseList?.map((item: any) => {
    const sections = item?.response?.sections?.[0]?.sectionItems?.map((item) => {
      return { label: item?.label, value: item?.response, type: item?.type, files: item?.files };
    });
    return {
      user: { name: item?.name, profilePic: null },
      sectionData: sections,
      id: item?.id,
      name: `${item?.name}`,
      submittedDate: `${item?.updatedAt || ''}`,
      sticker: `${viewSurvey?.type || ''}`
    };
  });
  const details = viewSurvey?.id
    ? [
        {
          label: `${viewSurvey?.type} Name`,
          value: viewSurvey?.title
        }
      ]
    : [];
  if (viewSurvey?.objective) {
    details.push({
      label: 'Objective',
      value: viewSurvey?.objective
    });
  }
  const tabItems: TTabItem[] = useMemo(() => {
    return [
      {
        key: 'details',
        text: `${viewSurvey?.type || 'Report'} Details`,
        disabled: false,
        component: <CommonDetail detailFields={details} key={id} />
      },
      {
        key: 'reports',
        text: `${viewSurvey?.type || 'Report'} Recieved`,
        disabled: false,
        component: (
          <SubmittedApplications
            key={id}
            title={`${viewSurvey?.type || 'Report'} Recieved`}
            submittedApplication={applications}
          />
        )
      }
    ];
  }, [viewSurvey, surveyResponseList]);

  // handler
  const handleChangeTab = (value: string) => {
    const path = `/view-cohort?id=${cohortId}&companyId=${companyId}&key=${value}&resourceId=${id}&tab=View-Report-Survey`;
    navigate(path, { replace: true });
  };
  const shareProgramDropdownOptions: TDropdownOptions[] = [
    {
      label: `Copy ${viewSurvey?.type} form link`,
      onClick: () => {
        const textObj = {
          text: `${window.location.host}/survey-report?id=${id}&type=report-survey`
        };
        copyToClipboard(textObj);
      }
    }
  ];
  const shareSurveyReportDropdownProps: TDropdown = {
    id: shareSurveyId,
    size: 'large',
    alignment: 'left',
    items: shareProgramDropdownOptions
  };
  const handleOpenShareDropdown = () => {
    toggleOpenDropdown({ dropdownId: shareSurveyId });
  };

  // effects
  useEffect(() => {
    if (id && companyId) {
      dispatch(getViewSurvey({ cohortId, incubatorId: companyId, id }));
      dispatch(getSurveyResponse({ incubatorId: companyId, serveyId: id }));
    }

    return () => {
      dispatch(viewResponseStateChange({ state: false, data: [], id: '' }));
    };
  }, [id]);

  return (
    <div className={`${cx.viewReportSurvey}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>{viewSurvey?.type || ''}</h5>
        <div className="d-flex gap-3">
          {viewSurvey?.id && (
            <div className="program-actions__actions">
              <div
                className="program-actions__actions__share"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenShareDropdown();
                }}
              >
                <div className="program-actions__actions__share__icon">
                  <ShareIcon />
                </div>
                <SubMenuModal>
                  <Dropdown {...shareSurveyReportDropdownProps} />
                </SubMenuModal>
              </div>
            </div>
          )}
          <Button
            size="small"
            variant="primary"
            className="program-actions__actions__next"
            style={{ width: 'auto' }}
            type="button"
            onClick={() => {
              if (isViewResponse?.state) {
                dispatch(viewResponseStateChange({ state: false, data: [], id: '' }));
              } else {
                handleBack();
              }
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div>
        {isViewResponse?.state ? (
          <ApplicationSectionView
            key={isViewResponse?.id}
            sectionTitle={''}
            data={isViewResponse?.data || []}
          />
        ) : (
          <Tabs activeItem={currentTab} onTabChange={handleChangeTab} items={tabItems} />
        )}
      </div>
    </div>
  );
}
