import React, { useRef, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { componentSizeVariantsEnum } from 'src/constants';
import { InputField } from 'src/components/common/formFields';
import { Button, ProfilePicture, Typography, Loader } from 'src/components/common';
import 'bootstrap/dist/css/bootstrap.min.css';
import cx from './cohortRelationship.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { TMilestone, TTask } from '../../Cohorts/Milestone/milestonetypes';
import { MdAdd } from 'react-icons/md';
import { UploadIcon } from 'src/components/common/common.icons';
import {
  changeSubmittedReportModalState,
  changeTeamSubmittedReportModalState,
  openPopup
} from 'src/store/common/common.slice';
import { TActionButton } from 'src/components/company/programs/viewCohort/viewCohort.types';
import TrackStepper from 'src/components/common/TrackStepper/ActionStepper';
import SubmittedTeamReportSummeryModal from './SubmittedTeamReportSummeryModal';
import GlobalMilestone from 'src/components/company/programs/CohortSidebarComponent/GlobalMilestone/GlobalMilestone';
import { useUrlParamValue, useToggleOpenModal } from 'src/hooks';
import {
  getCohortTeamSuccess,
  removeTeamMember,
  viewcohortTeam,
  getMilestone,
  cohortInvestment,
  viewmycohortTeam,
  createCohortInvestment,
  updateCohortTeam
} from 'src/store/cohort/cohort.slice';
import { httpRequest } from 'src/services/commonApis';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { AppDispatch } from 'src/store/store';
import {
  TExpenses,
  TUpdateInvestmentTracking
} from 'src/components/company/programs/CohortSidebarComponent/InvestmentTracking/updateTracking.types';
import { TInvestemntTracking } from 'src/services/apiEndpoint.types.ts/cohort/Dashboard.types';
import { timeStamp } from 'console';
import { textEllipsis } from 'src/utils';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';

interface TOnMilestoneProps {
  handleAction: (screen: string) => void;
}
export default function CohortRelationship({ handleAction: actionHandler }: TOnMilestoneProps) {
  const dispatch = useDispatch<AppDispatch>();
  const isModalOpen: boolean = useAppSelector<boolean>(
    (store: any) => store.common.isTeamSubmittedReportModal
  );
  const { paramValue } = useUrlParamValue();
  const { viewCohortTeam, viewUserCohort, globalLoading }: any = useSelector(
    (state: any) => state?.cohort
  );
  const { profile } = useAppSelector((store) => store.profile);
  const { loading }: any = useSelector((state: any) => state?.common);
  const teamId: string = paramValue({ paramName: 'teamId' }) as string;
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('')
  const milestoneData = useAppSelector((state: any) => state.cohort.Milestone);
  const [expenseData, setExpenseData] = useState<TExpenses>({
    amount: '',
    userName: profile?.name,
    timestamp: new Date().toString()
  });
  const [about, setAbout] = useState<string>('')

  const steps: { title: string; percentage?: number }[] = milestoneData?.map(
    (milestone: TMilestone) => ({
      title: milestone.cohortMilestoneTitle,
      percentage: milestone.percentage
    })
  );

  const actionButtons: TActionButton[] = [
    { text: 'Milestone', id: 'Milestone', onClick: handleAction },
    { text: 'Resource', id: 'Resource', onClick: handleAction },
    { text: 'Submit Report', id: 'Submit Report', onClick: handleAction }
  ];
  const isInvestmentTracking = viewUserCohort?.templates?.investmentTracking;

  function handleAction(type: string) {
    if (type === 'Submit Report') {
      dispatch(changeTeamSubmittedReportModalState(true));
    } else {
      actionHandler(type);
    }
  }
  function handleExpense(e: React.ChangeEvent<HTMLInputElement>) {
    setExpenseData({ ...expenseData, amount: +e.target.value });
  }
  const handleSubmit = () => {
    setUpdateLoading(true);
    const data = {
      cohortTeamsId: teamId,
      cohortId,
      fundsAlloted: viewCohortTeam?.investmentTracking?.fundsAlloted || 0,
      expenses: viewCohortTeam?.investmentTracking?.expenses
        ? [
          { ...expenseData, timestamp: new Date().toString() },
          ...viewCohortTeam?.investmentTracking?.expenses
        ]
        : [expenseData],
      additionalsAllotment: viewCohortTeam?.investmentTracking?.additionalsAllotment || []
    };
    dispatch(createCohortInvestment(data)).then((response) => {
      if (response.status) {
        dispatch(cohortInvestment({ cohortId }));
        dispatch(viewmycohortTeam({ teamId }));
        dispatch(
          openPopup({
            popupMessage: response?.message,
            popupType: 'success'
          })
        );
      }
    }).catch((err) => {
      dispatch(
        openPopup({
          popupMessage: err?.message,
          popupType: 'error'
        })
      );
    }).finally(() => {
      setUpdateLoading(false);
    })
  };

  function handleTeam() {
    if (about?.length > 500) {
      setError('maximum word limit reach');
      return
    } else {
      setError('')
    }
    const payload = {
      ...viewCohortTeam,
      teamId: viewCohortTeam?.id,
      about,
      teamMemberIds: viewCohortTeam?.teamMemberMappings?.map((team) => ({ role: team.teamMember?.role, id: team.teamMemberId }))
    }
    dispatch(updateCohortTeam(payload))
  }

  useEffect(() => {
    if (teamId && cohortId) {
      const payload = { teamId };
      dispatch(viewmycohortTeam(payload));
    }
    if (teamId && cohortId) {
      const payload = { cohortId, teamId };
      dispatch(getMilestone(payload));
    }
  }, []);
  useEffect(() => {
    if (cohortId) {
      dispatch(cohortInvestment({ cohortId }));
    }
  }, [cohortId]);
  useEffect(() => {
    if (viewCohortTeam?.about) {
      setAbout(viewCohortTeam?.about)
    }
    if (viewCohortTeam?.investmentTracking) {
      setExpenseData({
        ...expenseData,
        amount: viewCohortTeam?.investmentTracking?.expenses?.[0]?.amount || ''
      });
    }
  }, [viewCohortTeam]);

  if (globalLoading === 'team') {
    return <Loader />;
  }
  // const teamInvestment = investmentTrackingData?.find((investement: TInvestemntTracking) => investement.team.id === viewCohortTeam?.id);
  // useEffect(() => {
  //   if (teamInvestment) {
  //     setExpenseTracking(teamInvestment.expenses)
  //   }
  // }, [teamInvestment])
  console.log(about.length, 'aboutLength');
  
  return (
    <>
      {isModalOpen && <SubmittedTeamReportSummeryModal />}
      <div className={`${cx.relationship}`}>
        <div className={`${cx.titleHeader}`}>
          <h5 className={`${cx.title}`}>{viewCohortTeam?.cohortTeamsTitle || ''}</h5>
        </div>
        <div className="insideCard">
          <Row>
            <Col md={12} style={{ marginBottom: '8px' }}>
              <div className={cx.details}>
                <h5 className={`${cx.actionHeading}`}>About</h5>
                <TextArea value={about} onChange={(e) => setAbout(e.target.value)} />
                {about?.length > 500 && <ErrorMessage message={"maximum word limit reach"} />}
                {viewCohortTeam && <Button
                  size="small"
                  variant="primary"
                  className={`program-acti,ons__actions__next ${cx.actionBtn}`}
                  type="button"
                  style={{ width: 'auto', marginTop: '8px' }}
                  onClick={handleTeam}
                >
                  <span>{loading ? <Loader /> : 'Update'}</span>
                </Button>}
              </div>
            </Col>
          </Row>
        </div>
        <div className="insideCard">
          <Row>
            <Col md={12} style={{ marginBottom: '8px' }}>
              <Row>
                <Col md={12}>
                  <div className={cx.actionButtons}>
                    {actionButtons?.map((button: TActionButton, i: number) => {
                      return (
                        <Button
                          size="small"
                          variant="primary"
                          className={`program-acti,ons__actions__next ${cx.actionBtn}`}
                          type="button"
                          key={button.id}
                          onClick={() => button.onClick(button.text)}
                        >
                          <span className={cx.addIcon}>{i === 2 && <MdAdd />}</span>
                          <span>{button.text} </span>
                        </Button>
                      );
                    })}
                  </div>
                </Col>
                {/* <Col md={3}>
                                <div className={`${cx.percentage}`}>
                                    <span>Percentage completion</span>
                                    <span className={`${cx.highlight}`}>70%</span>
                                </div>
                            </Col> */}
                <Col md={12}>
                  {isInvestmentTracking && (
                    <Row>
                      <Col md={2}></Col>
                      <Col md={4}>
                        <div className={`${cx.fundAction}`}>
                          <h5 className={`${cx.actionHeading}`}>Fund Alloted :</h5>
                          <span className={cx.actionValue}>
                            {viewCohortTeam?.investmentTracking?.fundsAlloted || '0'}
                          </span>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className={`${cx.fundAction}`}>
                          <h5 className={`${cx.actionHeading}`}>Expense</h5>
                          <InputField
                            required
                            label=""
                            value={expenseData?.amount}
                            onChange={handleExpense}
                            style={{ width: '95px', height: '35px', textAlign: 'center' }}
                            id="expense"
                            variant={componentSizeVariantsEnum.SMALL}
                            placeholder="0"
                            type="number"
                          />
                          <Button
                            size="small"
                            variant="primary"
                            className={`program-acti,ons__actions__next ${cx.actionBtn}`}
                            type="button"
                            style={{ height: '35px', width: '70px', fontSize: '12px' }}
                            onClick={handleSubmit}
                          >
                            {updateLoading ? <Loader /> : <span>Update</span>}
                          </Button>
                        </div>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={12} style={{ marginBottom: '10px' }}>
                      <div className={cx.stepper}>
                        <TrackStepper steps={steps} type="cohort" />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <div className={cx.details}>
                <Row>
                  <Col md={12}>
                    <Row>
                      {/* team list  */}
                      <Col md={6}>
                        <div className="insideCard">
                          <Row>
                            <Col md={12}>
                              <h5 className={`${cx.chatHeading}`}>Teams</h5>
                            </Col>
                            {viewCohortTeam?.teamMemberMappings?.map((team: any) => {
                              const nameWithEllipsis = textEllipsis({
                                charLimit: 70,
                                text: team?.teamMember?.name
                              });
                              return (
                                <Col md={6} key={team.teamMemberId}>
                                  <div className={cx.teamCard}>
                                    <ProfilePicture
                                      url={team?.teamMember?.profilePic}
                                      fallback={team?.teamMember?.name?.[0]?.toLocaleUpperCase()}
                                    />
                                    <div>
                                      <Typography
                                        as="p"
                                        variant="body-2"
                                        fontWeight="semiBold"
                                        title={team?.teamMember?.name}
                                      >
                                        {nameWithEllipsis?.text}
                                      </Typography>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                            {viewCohortTeam?.teamMemberMappings?.length === 0 && (
                              <div className="emptyContainerBoxes">
                                <p>No team added yet</p>
                              </div>
                            )}
                          </Row>
                        </div>
                      </Col>
                      {/* mentor list  */}
                      <Col md={6}>
                        <div className="insideCard">
                          <Row>
                            <Col md={12}>
                              <h5 className={`${cx.chatHeading}`}>Mentors</h5>
                            </Col>
                            {viewCohortTeam?.mentorMappings?.map((mentor: any) => {
                              const nameWithEllipsis = textEllipsis({
                                charLimit: 70,
                                text: mentor?.mentor?.name
                              });
                              return (
                                <Col md={6} key={mentor?.mentorId}>
                                  <div className={`${cx.teamCard}`}>
                                    <ProfilePicture
                                      url={mentor?.mentor?.profilePic}
                                      fallback={mentor?.mentor?.name?.[0]?.toLocaleUpperCase()}
                                    />
                                    <div>
                                      <Typography
                                        as="p"
                                        variant="body-2"
                                        fontWeight="semiBold"
                                        title={mentor?.mentor?.name}
                                      >
                                        {nameWithEllipsis.text}
                                      </Typography>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                            {viewCohortTeam?.mentorMappings?.length === 0 && (
                              <div className="emptyContainerBoxes">
                                <p>No Mentor assign yet</p>
                              </div>
                            )}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <GlobalMilestone type="team" />
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
