import React, { useEffect, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import { Loader, Modal, ProfilePicture, Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import cx from './updateInvestment.module.scss';
import investmentTrackingValidationSchema from './InvestmentTrackingValidationSchema';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { Col, Row } from 'react-bootstrap';
import { TMilestone, TTask } from '../../CohortsCompoents/Milestone/milestonetypes';
import * as Yup from 'yup';
import { changeUpdateTaskModalState, updateTask } from 'src/store/cohort/cohort.slice';
import { useUrlParamValue } from 'src/hooks';
import { TTeam } from 'src/services/apiEndpoint.types.ts/cohort/mentor.types';
import { MdAdd } from 'react-icons/md';
import { EditIcon } from 'src/components/common/common.icons';
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
import { TUpdateInvestmentTracking } from './updateTracking.types';
import { formatDate } from 'src/utils';
import { disablePastDate } from 'src/utils/DisablePastDate/disablePasteDate';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';

const initialState = { fund: false, additional: { state: false, value: '' } };
const UpdateTaskModal = () => {
  const loading: boolean = useAppSelector<boolean>((store: any) => store.common.loading);
  const { myCompany }: any = useAppSelector<boolean>((store: any) => store.company);
  const isUpdateTaskModal: { state: boolean; task: TTask; milestoneId: string, milestone?: TMilestone } = useAppSelector(
    (store: any) => store.cohort.isUpdateTaskModal
  );
  const { paramValue } = useUrlParamValue();
  const incubatorId = paramValue({ paramName: 'companyId' }) as unknown as string;
  const teamId = paramValue({ paramName: 'teamId' }) as unknown as string;
  const { investmentTrackingData } = useAppSelector((state: any) => state?.cohort);
  const [updateLoading, setUpdateLoading] = useState<string>('');
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const [editMode, setEditMode] = useState<{
    fund: boolean;
    additional: { state: boolean; value: number | string };
  }>(initialState);
  const [errors, setError] = useState('');
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(changeUpdateTaskModalState({ state: false, task: null, milestone: null, milestoneId: '' }));
  };

  const taskValidationSchema = Yup.object().shape({
    completion: Yup.number()
      .typeError('must be a number') // Ensure it's a number
      .required('required') // Required validation
      .min(0, 'must be at least 0') // Minimum value
      .max(100, 'must be at most 100') // Maximum value
    // comments: Yup.string().optional()
  });
  console.log(isUpdateTaskModal?.milestone, 'isUpdateTaskModal');

  const handleUpdate = (milestoneId: string, taskId: string, values: any) => {
    console.log(values, 'vvviiji');
    const updatePayload = {
      cohortId,
      teamId,
      incubatorId,
      milestoneId,
      tasks: [
        {
          id: taskId,
          percentage: values.completion,
          ...(values.comments ? { comments: values.comments } : {}),
          startDate: values.startDate,
          endDate: values.endDate
        }
      ]
    };
    dispatch(updateTask(updatePayload));
    console.log('Updating task: ', updatePayload); // For debugging
  };

  return (
    <Modal
      isModalOpen={isUpdateTaskModal.state}
      onModalClose={closeModal}
      title={`Task Tracking: ${isUpdateTaskModal.task?.taskTitle}`}
      className="edit-cohort-modal"
    >
      <div className="edit-cohort">
        <div className={`${cx.investmentTracking} ${cx.autoHeight}`}>
          <p>Expiration Date: {isUpdateTaskModal.task?.endDate}</p>

          <Formik
            initialValues={{
              completion: isUpdateTaskModal.task.percentage || '',
              comments: isUpdateTaskModal.task.comments || '',
              startDate: isUpdateTaskModal.task.startDate || '',
              endDate: isUpdateTaskModal.task.endDate || ''
            }}
            validationSchema={taskValidationSchema}
            onSubmit={(values) =>
              handleUpdate(isUpdateTaskModal.milestoneId, isUpdateTaskModal.task.id, values)
            }
            enableReinitialize
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              const milestone = isUpdateTaskModal?.milestone
              const minStartDate = milestone.startDate
              const minEndDate = values?.startDate;
              const maxEndDate = milestone?.endDate
              return (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      {/* Completion Field with increased spacing */}
                      <div className={`${cx.formGroup} ${cx.spacing}`}>
                        <Field name="completion">
                          {({ field }: any) => (
                            <InputField
                              label="Completion (%)"
                              id="task-completion"
                              value={field.value}
                              {...Field}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                // Ensure that the value is set as a number
                                field.onChange({
                                  target: {
                                    name: field.name,
                                    value: value === '' ? '' : value // Convert to number
                                  }
                                });
                              }}
                              placeholder="% completion"
                              type="text"
                              className={`${cx.wideInputField}`} // Adjusted the width
                              variant={componentSizeVariantsEnum.SMALL}
                            />
                          )}
                        </Field>
                        {touched.completion && errors.completion && (
                          <ErrorMessage message={errors.completion} />
                          // <div className={cx.error} >{errors.completion}</div>
                        )}
                      </div>

                      {/* Enlarged Comments Field with adjusted spacing */}
                      <div className={`${cx.dateBox} `}>
                        <div className={`${cx.formGroup} ${cx.spacing} ${cx.DateInputField}`}>
                          <Field name="startDate">
                            {({ field }: any) => (
                              <InputField
                                label="Start Date"
                                id="startDate"
                                min={minStartDate || disablePastDate()}
                                max={maxEndDate}
                                value={field.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = e.target.value;
                                  // Ensure that the value is set as a number
                                  field.onChange({
                                    target: {
                                      name: field.name,
                                      value: value === '' ? '' : value // Convert to number
                                    }
                                  });
                                }}
                                placeholder="start Date"
                                type="date"
                                variant={componentSizeVariantsEnum.SMALL}
                              />
                            )}
                          </Field>
                          {touched.comments && errors.comments && (
                            <ErrorMessage message={errors.comments} />
                            // <div className={cx.error}>{errors.comments}</div>
                          )}
                        </div>

                        <div className={`${cx.formGroup} ${cx.spacing} ${cx.DateInputField}`}>
                          <Field name="endDate">
                            {({ field }: any) => (
                              <InputField
                                label="End Date"
                                id="endDate"
                                min={minEndDate || disablePastDate()}
                                max={maxEndDate}
                                value={field.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = e.target.value;
                                  // Ensure that the value is set as a number
                                  field.onChange({
                                    target: {
                                      name: field.name,
                                      value: value === '' ? '' : value // Convert to number
                                    }
                                  });
                                }}
                                placeholder="End Date"
                                type="date"
                                variant={componentSizeVariantsEnum.SMALL}
                              />
                            )}
                          </Field>
                          {touched.comments && errors.comments && (
                            <ErrorMessage message={errors.comments} />
                            // <div className={cx.error}>{errors.comments}</div>
                          )}
                        </div>
                      </div>
                      <div className={`${cx.formGroup} ${cx.spacing}`}>
                        <Field name="comments">
                          {({ field }: any) => (
                            <InputField
                              label="Comments"
                              id="task-comments"
                              value={field.value}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                // Ensure that the value is set as a number
                                field.onChange({
                                  target: {
                                    name: field.name,
                                    value: value === '' ? '' : value // Convert to number
                                  }
                                });
                              }}
                              placeholder="Enter comments"
                              type="textarea"
                              variant={componentSizeVariantsEnum.SMALL}
                            />
                          )}
                        </Field>
                        {touched.comments && errors.comments && (
                          <ErrorMessage message={errors.comments} />
                          // <div className={cx.error}>{errors.comments}</div>
                        )}
                      </div>
                      {/* Submit Button */}
                      <div className={`${cx.formGroup} ${cx.spacing} ${cx.update_button}`}>
                        <Button variant="secondary" size="small" type="submit" className={cx.button}>
                          Update Task
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateTaskModal;
