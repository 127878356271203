import React, { useEffect } from 'react'
import cx from './event.module.scss';
import { Button, Loader } from 'src/components/common';
import { Col, Row } from 'react-bootstrap';
import CreateCard from '../CreateCard/CreateCard';
import DetailCard from '../DetailCard/DetailCard';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getEvents } from 'src/store/cohort/cohort.slice';
import { useUrlParamValue } from 'src/hooks';
interface TEventProps {
  handleBack: () => void;
  handleClick?: (id: string, resource?: null, resourceId?: string) => void
}
export default function Event({ handleBack, handleClick }: TEventProps) {
  const dispatch = useAppDispatch()
  const { paramValue } = useUrlParamValue();
  const { events }: any = useAppSelector((store: any) => store.cohort);
  const { loading }: { loading: boolean } = useAppSelector((store: any) => store.common);
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const companyId: string = paramValue({ paramName: 'companyId' }) as string;
  useEffect(() => {
    if (cohortId) {
      dispatch(getEvents({ cohortId, incubatorId: companyId }))
    }
  }, [cohortId])
  if (loading) {
    return <Loader />
  }
  return (
    <div className={`${cx.survey}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>Events</h5>
        <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <Row>
        <Col md={3}>
          <CreateCard title='Create Events' onClick={() => handleClick('create-event')} />
        </Col>
        {events.map((event) => {
          const { title, startDate, endDate, id } = event
          return (
            <Col md={3} key={event.id}>
              <DetailCard
                title={title}
                status="events"
                startDate={startDate}
                endDate={endDate}
                onClick={() => handleClick(`View-Event`, null, id)}
              />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
