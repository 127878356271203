import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  isPopupOpen: boolean;
  popupMessage: string;
  popupDescription: string;
  popupType: 'success' | 'error' | '';
  navigateTo: string;
  loading: boolean; // loading state
  itemListLoader: boolean; // loader for list of items
  showPageLoader: boolean; // whether to show the page loader
  dropdownId: string; // Each submenu modal is assigned an unique id, a submenu will open if this state is equal to the respective modal id
  dropdownIdSecond?: string; // Each submenu modal is assigned an unique id, a submenu will open if this state is equal to the respective modal id
  showNavBar: boolean;
  modalId: string; // Each  modal is assigned an unique id, modal will open if this state is equal to the respective modal id
  confirmModalId: string; // Each confirm modal is assigned an unique id, modal will open if this state is equal to the respective modal id
  loadingId: string;
  draftState?: any;
  existingUser?: any;
  isCohortOpen?: { state: boolean, id?: string };
  isSubmittedReportModal?: boolean;
  isTeamSubmittedReportModal?: boolean;
};

const initialState: TInitialState = {
  /*
   ** Popup
   */
  isPopupOpen: false,
  popupMessage: '',
  popupDescription: '',
  popupType: '',
  navigateTo: '',
  loading: false,
  itemListLoader: false,
  /*
   ** Page loader
   */
  showPageLoader: true,
  /*
   ** Sub menu modal
   */
  dropdownId: '',
  dropdownIdSecond: '',
  /*
   ** Nav bar
   */
  showNavBar: false,
  /*
   ** modal id
   */
  modalId: '',
  /*
   ** confirm modal id
   */
  confirmModalId: '',
  /*
   ** loading id
   */
  loadingId: '',
  draftState: {
    isOpen: false,
    data: null
  },
  existingUser: {
    isOpen: false,
    data: null
  },
  isCohortOpen: { state: false, id: '' },
  isSubmittedReportModal: false
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    /*
     ** Popup
     */
    openPopup: (state, action: PayloadAction<any>) => {
      const { popupMessage, popupDescription, popupType, navigateTo } = action.payload;

      state.popupMessage = popupMessage;
      state.popupDescription = popupDescription;
      state.popupType = popupType;
      state.isPopupOpen = true;
      state.navigateTo = navigateTo || '';
    },
    closePopup: (state) => {
      if (state.popupType === 'success') {
        state.modalId = '';
      }
      state.popupMessage = '';
      state.popupType = '';
      state.isPopupOpen = false;
      state.navigateTo = '';
    },

    openDraftModal: (state, action) => {
      state.draftState = action?.payload
    },
    changeCreateCohortModalState: (state, action) => {
      state.isCohortOpen = action?.payload
    },
    changeSubmittedReportModalState: (state, action) => {
      state.isSubmittedReportModal = action?.payload
    },
    changeTeamSubmittedReportModalState: (state, action) => {
      state.isTeamSubmittedReportModal = action?.payload
    },
    openExistingUserModal: (state, action) => {
      console.log(action, 'actionDataaaa');

      state.existingUser = action?.payload
    },
    /*
     ** Page loader
     */
    showPageLoader: (state) => {
      state.showPageLoader = true;
    },
    hidePageLoader: (state) => {
      state.showPageLoader = false;
    },
    /*
     ** Item list loader
     */
    startItemListLoader: (state) => {
      state.itemListLoader = true;
    },
    stopItemListLoader: (state) => {
      state.itemListLoader = false;
    },
    /*
     ** Loading state
     */
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    /*
     ** Assign loader id
     */
    assignLoaderId: (state, action: PayloadAction<string>) => {
      state.loadingId = action?.payload;
    },
    resetLoaderId: (state) => {
      state.loadingId = '';
    },
    /*
     ** Sub menu modal
     */
    assignSubMenuModalId: (state, action: PayloadAction<any>) => {
      state.dropdownId = action.payload;
    },
    assignSecondSubMenuModalId: (state, action: PayloadAction<any>) => {
      state.dropdownIdSecond = action.payload;
    },
    resetDropdownId: (state) => {
      state.dropdownId = '';
      state.dropdownIdSecond = '';
    },
    /*
     ** Toggle Nav bar
     */
    toggleNavBar: (state) => {
      state.showNavBar = !state.showNavBar;
    },
    /*
     ** Modal
     */
    assignModalId: (state, action: PayloadAction<any>) => {
      state.modalId = action.payload;
    },
    resetModalId: (state) => {
      state.modalId = '';
    },
    /*
     ** Confirm Modal
     */
    assignConfirmModalId: (state, action: PayloadAction<any>) => {
      state.confirmModalId = action.payload;
    },
    resetConfirmModalId: (state) => {
      state.confirmModalId = '';
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  openPopup,
  closePopup,
  showPageLoader,
  hidePageLoader,
  startLoading,
  stopLoading,
  assignSubMenuModalId,
  resetDropdownId,
  assignSecondSubMenuModalId,
  toggleNavBar,
  assignModalId,
  resetModalId,
  assignConfirmModalId,
  resetConfirmModalId,
  startItemListLoader,
  stopItemListLoader,
  assignLoaderId,
  resetLoaderId,
  openDraftModal,
  openExistingUserModal,
  changeCreateCohortModalState,
  changeSubmittedReportModalState,
  changeTeamSubmittedReportModalState
} = commonSlice.actions;

export default commonSlice.reducer;
