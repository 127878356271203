import React from 'react'
import { Button, Loader, Typography } from 'src/components/common'
import { exportToExcel } from 'src/utils/convertToExcel/convertToExcel';
import 'bootstrap/dist/css/bootstrap.min.css';
import SubmittedCard from '../SubmittedCard/SubmittedCard';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from 'src/store/hooks';

interface TSubmittedApplicationsProps {
    title: string,
    submittedApplication?: any[]
}
function SubmittedApplications({ title, submittedApplication }: TSubmittedApplicationsProps) {
    const { globalLoading } = useAppSelector(state => state.cohort);

    if (globalLoading === 'response') {
        return <Loader />
    }
    return (
        <div className="view-program-submitted-applications d-flex mt-3">
            <Row>
                <Col md={8}>
                    <Typography as="p" variant="body-2" fontWeight="semiBold">
                        {title}
                    </Typography>
                </Col>
                <Col md={4} className='d-flex justify-content-end'>
                    <div className='d-flex gap-2'>
                        {/* <button  onClick={() => exportToExcel(applicants)}>Export to Excel</button> */}
                        {/* <div onClick={() => exportToExcel('')} style={{ maxWidth: '250px', width: '100%' }}>
                            <Button
                                variant="primary"
                                size="small"
                                type="submit"
                            >
                                Export Applications to Excel
                            </Button>
                        </div> */}
                    </div>
                </Col>
            </Row>
            <div className="view-program-submitted-applications__applicants">
                {submittedApplication.map((application, a: number) => {
                    return (
                        <SubmittedCard key={a} {...application} />
                    )
                })}
            </div>
        </div>
    )
}

export default SubmittedApplications;
