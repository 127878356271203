import * as Yup from 'yup';

const createSureveyReportsValidationSchema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    objective: Yup.string().required('Objective is required'),
    applicationForm: Yup.object().optional(),
    title: Yup.string().required('Title is required')
})

export default createSureveyReportsValidationSchema;
