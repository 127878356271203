import React from 'react'
import { marketplaceIcon, test } from 'src/assets/images';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import cx from './productcard.module.scss';

export default function ProductCard() {
    return (
        <div className='item'>
            <div className={`${cx.outer_main_box}`}>
                <div className={`${cx.marketplace_image}`}>
                    <img src={marketplaceIcon} alt="marketplace_icon" />
                </div>
                <div className={`${cx.product_heading}`}>
                    <h4>Creative design</h4>
                    <div className={`${cx.product_content}`}>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
