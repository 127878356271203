/* eslint-disable multiline-ternary */
import React, { useEffect } from 'react';
import { Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TGetProgramDocumentRequest, TGetProgramFunction } from 'src/services/apiEndpoint.types.ts';
import { formatDate } from 'src/utils';
import { getProgramDocumentExternalUser } from 'src/store/externalProgram/externalProgram.slice';
import './applyProgramProgramDetails.scss';

import FileCard from '../../fileCard/FileCard';
interface ProgramDetailsProps extends TGetProgramFunction {}
const ApplyProgramProgramDetails: React.FC<ProgramDetailsProps> = ({ handleGetProgram }) => {
  // Hooks
  const viewProgramData: any = useAppSelector((store:any) => store.externalProgramSlice);
  const viewProgram = viewProgramData?.viewProgram
  const dispatch = useAppDispatch();

  const getDocument = ({ key }: { key: string }) => {
    const payload: TGetProgramDocumentRequest = { s3Key: key };

    dispatch(getProgramDocumentExternalUser(payload));
  };

  const programDetailsFields: { label: string; value: string }[] = [
    // { label: 'Program Name', value: viewProgram?.title },
    // { label: 'Start date', value: formatDate({ dateString: viewProgram?.startDate }) },
    { label: 'End date', value: formatDate({ dateString: viewProgram?.endDate }) }
  ];

  useEffect(() => {    
    if (viewProgram.id === '0' || viewProgram.id === '0' || !viewProgram.id) {
      handleGetProgram()
    }
  }, [viewProgram])
  return (
    <div className="view-program-program-details">
      {programDetailsFields.map(({ label, value }, index) => (
        <div key={index} className="view-program-program-details__field">
          {label && (
            <Typography
              className="view-program-program-details__field__label"
              as="p"
              variant="body-2"
              fontWeight="semiBold"
            >
              {label}
            </Typography>
          )}
          <Typography
            className="view-program-program-details__field__value"
            as="p"
            variant="caption"
            fontWeight="regular"
          >
            {value}
          </Typography>
        </div>
      ))}
      {viewProgram?.programDetails.map(({ label, values, type, files }:any, index:any) => (
        <div key={index} className="view-program-program-details__field">
          <Typography
            className="view-program-program-details__field__label"
            as="p"
            variant="body-2"
            fontWeight="semiBold"
          >
            {label}
          </Typography>
          {type === 'fileUpload' && files && files?.length > 0 && (
            <div className="view-program-program-details__field__files">
              {files.map((file:any) => {
                return (
                  <FileCard
                    fileName={file?.fileName}
                    fileSize={file?.fileSize}
                    type='preview'
                    key={file?.key}
                    onClick={() => {
                      getDocument({ key: file?.key });
                    } } removeFile={undefined}/>
                );
              })}
            </div>
          )}
          {type !== 'fileUpload' && (
            <Typography
              className="view-program-program-details__field__value"
              as="p"
              variant="caption"
              fontWeight="regular"
            >
              {type === 'dateSelection' ? (
                formatDate({ dateString: values })
              ) : (
                <div className="view-program-program-details__field__value_editor"
                    dangerouslySetInnerHTML={{ __html: values }}
                ></div>
              )}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

export default ApplyProgramProgramDetails;
