import { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal, Tooltip, Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import { changeCreateCohortModalState, changeSubmittedReportModalState, changeTeamSubmittedReportModalState, openPopup } from 'src/store/common/common.slice';
import { useGetPlaces, useUrlParamValue } from 'src/hooks';

import './cohortSubmitReport.scss';
import cohortSubmitReportValidationSchema from './cohortSubmitReportValidationSchema';
import { useNavigate } from 'react-router-dom';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { DownloadIcon, FileIcon2, UploadIcon } from 'src/components/common/common.icons';
import { colors } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import handleExtension from 'src/utils/handleDocumentNameExtension/handleDocumentNameExtension';
import { TGetCompanyPresignedUrlActionRequest, TUploadCompanyProfilePictureResponse } from 'src/services/apiEndpoint.types.ts';
import { httpRequest } from 'src/services/commonApis';
import { APIResponse } from 'src/services/base.api.service';
import { CompanyAPIService } from 'src/services/company.service';
import { delteReport, getReports } from 'src/store/cohort/cohort.slice';
import { formatDate } from 'src/utils';

const SubmittedTeamReportSummeryModal = () => {
    const isModalOpen: boolean = useAppSelector<boolean>((store: any) => store.common.isTeamSubmittedReportModal);
    const { globalLoading, reportResources }: { globalLoading: string, reportResources: any } = useAppSelector((store: any) => store.cohort);
    const [loading, setLoading] = useState<boolean>(false)
    const { paramValue } = useUrlParamValue();
    const teamId: string = paramValue({ paramName: 'teamId' }) as string;
    const cohortId: string = paramValue({ paramName: 'id' }) as string;
    const navigate = useNavigate()
    // Hooks
    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(changeTeamSubmittedReportModalState(false));
    };
    const uploadImageRef = useRef<HTMLInputElement>(null);

    const openImageFileManager = async () => {
        if (uploadImageRef.current) {
            uploadImageRef.current.click();
        }
    };
    const handleUpload = (e) => {
        const file = e.target.files[0];
        setLoading(true)
        if (file) {
            const fileName = handleExtension(file?.name?.replaceAll('-', ''))
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                try {
                    const data = reader.result;
                    const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
                        id: teamId,
                        data,
                        showPopup: false
                    };
                    const response = await httpRequest(`cohort/uploads?cohortId=${cohortId}&teamId=${uploadProfilePicturePayload.id}&resourceName=${fileName}&docCategory=reports`, 'GET', null, 'json', 'cohort');
                    if (response?.data?.signedUrl) {
                        const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
                            await new CompanyAPIService().uploadCompanyProfilePicture({
                                presignedUrl: response?.data?.signedUrl,
                                data,
                                includeAuthorizationHeaders: false
                            });
                        if (res.status === 200) {
                            dispatch(getReports({ teamId, cohortId }))
                            setLoading(false)
                        } else {
                            dispatch(
                                openPopup({
                                    popupMessage: res?.message || 'something went wrong',
                                    popupType: 'error'
                                })
                            );
                        }
                    } else {
                        setLoading(false)
                        dispatch(
                            openPopup({
                                popupMessage: 'something went wrong',
                                popupType: 'error'
                            })
                        );
                    }
                } catch (error) {
                    setLoading(false)
                    dispatch(
                        openPopup({
                            popupMessage: error?.message || 'something went wrong',
                            popupType: 'error'
                        })
                    );
                }
            }
        }
    }
    const handleDownload = (url: string) => {
        const link: HTMLAnchorElement | any = document.createElement('a');
        if (link && url) {
            link.href = url;
            link.download = url.split('/').pop(); // Use the file name from the URL
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    const handleDelete = (key: string) => {
        if (key) {
            dispatch(delteReport({ key })).then((res) => {
                if (res.status) {
                    dispatch(
                        openPopup({
                            popupMessage: res?.message,
                            popupType: 'success'
                        })
                    );
                    dispatch(getReports({ teamId, cohortId }))
                } else {
                    dispatch(
                        openPopup({
                            popupMessage: res?.message,
                            popupType: 'error'
                        })
                    );
                }
                console.log(res, 'response');
            })
        }
    }
    useEffect(() => {
        if (teamId && cohortId) {
            dispatch(getReports({ teamId, cohortId }))
        }
    }, [teamId, cohortId])

    return (
        <Modal
            isModalOpen={isModalOpen}
            onModalClose={closeModal}
            title="Submitted Report"
            className="submitted-report-modal"
        >
            <div className="submitted-report-body">
                {globalLoading === 'reports'
                    ? <Loader />
                    : <>
                        <div className='submitted-report-submit-box'>
                            <div className='inputFields'>
                                <InputField
                                    label="Upload resource"
                                    id='banner-label'
                                    hidden
                                    variant={componentSizeVariantsEnum.SMALL}
                                    placeholder="Program Banner"
                                    type="file"
                                />
                                {!loading && <div
                                    className="dynamic-field__upload-file"
                                >
                                    <span className="dynamic-field__upload-file__drag-drop" onClick={openImageFileManager}>
                                        <UploadIcon />
                                        <input
                                            type="file"
                                            hidden
                                            value={''}
                                            multiple
                                            ref={uploadImageRef}
                                            accept=".jpg,.jpeg,.png,"
                                            className="select-post-image"
                                            onChange={(e) => {
                                                handleUpload(e)
                                            }}
                                        />
                                    </span>
                                    <Typography
                                        as="span"
                                        variant="body-2"
                                        fontWeight="medium"
                                        className="dynamic-field__upload-file__browse"
                                        onClick={openImageFileManager}
                                    >
                                        Browse on your device
                                    </Typography>

                                </div>}
                                {loading && <Loader />}
                            </div>
                        </div>
                        <ul className={'submitted-report-list'}>
                            {reportResources?.map((resource: any, i: number) => {
                                const dataArr = resource?.key;
                                const fileNameWithTimeStamp = dataArr.split('/')[5] || '';
                                const fileStampArr = fileNameWithTimeStamp?.split("-");

                                const fileName = fileStampArr?.[0] || ''
                                const timeStamp = fileStampArr?.[1]?.split('.')?.[0];
                                let date = '';
                                if (timeStamp) {
                                    date = new Date(+timeStamp)?.toString()
                                }

                                return (
                                    <li className={'submitted-report-list-item'} key={i}>
                                        <Row>
                                            <Col md={5}>
                                                <div className="submitted-report-item">
                                                    <div className="program-file-card">
                                                        <div className="program-file-card__file">{<FileIcon2 />}</div>
                                                        <div className="program-file-card__text">
                                                            <Tooltip text={'fileName'}>
                                                                <Typography
                                                                    as="p"
                                                                    variant="caption"
                                                                    fontWeight="semiBold"
                                                                    style={{ color: colors.neutral[1000] }}
                                                                >
                                                                    {fileName}
                                                                </Typography>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="program-file-card__actions">
                                                            <DownloadIcon onClick={() => handleDownload(resource.url)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={10} xs={10}>
                                                        <div className='submitted-report-item-timestamp'>
                                                            <span className='date-time'>{formatDate({ dateString: date || new Date().toString() })}</span>
                                                        </div>
                                                    </Col>
                                                    <Col md={2} xs={2}>
                                                        <div className='submitted-report-item-removebox'>
                                                            <button
                                                                type="button"
                                                                className="remove-button"
                                                                onClick={(e: any) => {
                                                                    e?.preventDefault()
                                                                    e?.stopPropagation()
                                                                    handleDelete(resource?.key)
                                                                }}
                                                                aria-label="Remove file"
                                                            >
                                                                {globalLoading === resource?.key ? <Loader /> : 'X'}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </li>
                                )
                            })}

                        </ul>
                    </>}
            </div >
        </Modal >
    );
};

export default SubmittedTeamReportSummeryModal;
