import React, { useEffect } from 'react';

import cx from './globalMilestone.module.scss';

import { InputField } from 'src/components/common/formFields';
import { Loader } from 'src/components/common';
import { Col, Row } from 'react-bootstrap';
import { componentSizeVariantsEnum } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useUrlParamValue } from 'src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/components/button/Button';
import {
  getMilestone,
  updateTask,
  changeUpdateTaskModalState
} from 'src/store/cohort/cohort.slice';
import { Formik, Field } from 'formik';
import { TMilestone, TTask } from '../../CohortsCompoents/Milestone/milestonetypes';
import * as Yup from 'yup';
import UpdateTaskModal from './updateTask';

export default function GlobalMilestone({ type }: any) {
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const milestoneData = useAppSelector((state: any) => state.cohort.Milestone);
  const { isUpdateTaskModal, globalLoading } = useSelector((state: any) => state?.cohort);
  const { loading } = useAppSelector((state: any) => state.common);
  const cohortId = paramValue({ paramName: 'id' }) || '';
  const incubatorId = paramValue({ paramName: 'companyId' }) as unknown as string;
  const teamId = paramValue({ paramName: 'teamId' }) as unknown as string;
  function handleTracking(task: TTask, milestoneId: string, milestone: TMilestone) {
    dispatch(changeUpdateTaskModalState({ state: true, task, milestoneId, milestone }));
  }
  console.log(isUpdateTaskModal, 'isUpdateTaskModal');
  useEffect(() => {
    console.log(type, milestoneData, '!milestoneData');
    if (type === 'team' && (!milestoneData || milestoneData.length < 1 || milestoneData === null)) {
      // dispatch(getMilestone({ teamId, cohortId }));
    } else if (incubatorId && cohortId && type === 'incubator') {
      dispatch(getMilestone({ incubatorId, cohortId }));
    }
  }, []);

  if (globalLoading === 'milestone') {
    return <Loader />;
  }

  // Dispatch the update for the specific tas
  return (
    <>
      {isUpdateTaskModal.state && <UpdateTaskModal />}

      <div className={cx.details}>
        <Row>
          <Col md={12}>
            <div className={cx.insideCard}>
              {type === 'team' && <h5 className={`${cx.heading}`}>Team&lsquo;s Milestone</h5>}

              {milestoneData?.length > 0 && (
                <ul className={cx.milestonelist}>
                  {milestoneData?.map((milestone: any, milestoneIndex: number) => (
                    <li key={milestone.id} className={cx.milestoneItem}>
                      {/* Milestone Box */}
                      <div className={cx.milestoneBox}>
                        <div className={cx.milestoneHeader}>
                          <span className={cx.mileStoneHeading}>
                            {milestone.cohortMilestoneTitle}
                          </span>
                          <div className={cx.milestonePercentage}>
                            <span className={cx.label}>Completion:</span>{' '}
                            <span className={cx.value}>
                              {Math.floor(milestone.percentage || 0)}%
                            </span>
                          </div>
                        </div>

                        {/* Milestone Dates */}
                        <div className={cx.dateContainer}>
                          <div className={cx.dateItem}>
                            <span className={cx.label}>Start Date:</span>{' '}
                            <span className={cx.value}>{milestone.startDate || 'N/A'}</span>
                          </div>
                          <div className={cx.dateItem}>
                            <span className={cx.label}>End Date:</span>{' '}
                            <span className={cx.value}>{milestone.endDate || 'N/A'}</span>
                          </div>
                        </div>

                        <hr className={cx.divider} />

                        {/* Render Tasks if Available */}
                        {milestone.tasks && milestone.tasks.length > 0 && (
                          <ul className={cx.taskList}>
                            {milestone.tasks.map((task: any, taskIndex: number) => (
                              <li className={cx.taskItem} key={task.id}>
                                {/* Task Box */}
                                <div className={cx.taskBox}>
                                  <div className={cx.taskHeader}>
                                    <span className={cx.taskHeading}>{task.taskTitle}</span>
                                    <div className={cx.taskPercentage}>
                                      <span className={cx.label}>Completion:</span>{' '}
                                      <span className={cx.value}>
                                        {Math.floor(task.percentage || 0)}%
                                      </span>
                                    </div>
                                  </div>

                                  {/* Task Dates */}
                                  <div className={`${cx.dateContainer} ${cx.taskPercentage}`}>
                                    <div className={cx.dateItem}>
                                      <span className={cx.label}>Start Date:</span>{' '}
                                      <span className={cx.value}>{task.startDate || 'N/A'}</span>
                                    </div>
                                    <div className={cx.dateItem}>
                                      <span className={cx.label}>End Date:</span>{' '}
                                      <span className={cx.value}>{task.endDate || 'N/A'}</span>
                                    </div>
                                  </div>

                                  {/* Task Comments and Update Button in one row */}
                                  <div className={cx.taskFooter}>
                                    <div className={`${cx.taskComments} ${cx.lightText}`}>
                                      <span className={cx.label}>Comment:</span>{' '}
                                      {task.comments && (
                                        <span className={cx.valueComment}>{task.comments}</span>
                                      )}
                                    </div>

                                    <div className={cx.updateButtonContainer}>
                                      <Button
                                        variant="secondary"
                                        size="small"
                                        type="submit"
                                        className={cx.button}
                                        onClick={() =>
                                          handleTracking(task, milestone.id, milestone)
                                        }
                                      >
                                        Update Task
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </li>
                  ))}
                  <li>
                    <hr />
                  </li>
                </ul>
              )}
              {milestoneData?.length === 0 && !loading && (
                <div className={cx.emptyItem}>Create your Cohort/Program level Milestones</div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
