import React from 'react'
import { Button, Card, Typography, VerticalSeparator } from 'src/components/common';
import './detailCard.scss'
import { formatDate, textEllipsis } from 'src/utils';
interface TDetailCardProps { title: string, status?: string, startDate?: string, endDate?: string, description?: string, onClick?: () => void }
function removeTags(str: any) {
    if ((str === null) || (str === ' ') || (str === undefined)) {
        return false;
    } else {
        str = str.toString();
        str = str.replace(/&nbsp;/g, '')
        return str.replace(/(<([^>]+)>)/ig, '')
    }
}
export default function DetailCard({ title, status, startDate, endDate, description, onClick }: TDetailCardProps) {
    const descriptionWithEllipsis: any = description ? textEllipsis({ charLimit: 50, text: removeTags(description) }) : ''
    const titleWithEllipsis: any = title ? textEllipsis({ charLimit: 30, text: title }) : ''
    return (
        <Card className="detail-card">
            <div className="program-card__content">
                <div className="program-card__content__details">
                    <div className="program-card__content__details__heading">
                        <Typography
                            as="span"
                            variant="body-1"
                            fontWeight="semiBold"
                            className="program-card__content__details__heading__cohortTitle"
                            title={title}
                        >
                            {titleWithEllipsis?.text}
                        </Typography>{' '}
                        {status && <VerticalSeparator />}
                        {status && <Typography
                            as="span"
                            variant="caption"
                            fontWeight="regular"
                            className="program-card__content__details__heading__status"
                        >
                            {status}
                        </Typography>}
                    </div>
                    {description && <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="program-card__content__details__start"
                    >
                        {descriptionWithEllipsis.text}
                    </Typography>}
                    {startDate && <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="program-card__content__details__start"
                    >
                        Start date: {formatDate({ dateString: startDate })}
                    </Typography>}
                    {endDate && <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="program-card__content__details__start"
                    >
                        End date: {formatDate({ dateString: endDate })}
                    </Typography>}
                </div>
            </div>

            <div className="program-card__footer">
                <Button
                    className="program-card__footer__action"
                    size="extraSmall"
                    variant="secondary"
                    onClick={() => onClick && onClick()}
                >
                    View Details
                </Button>
            </div>
        </Card>
    )
}
