import { Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import Button from 'src/components/button/Button';

import './programMemberListItem.scss';
import { TProgramMemberListItemProps } from './programMemberListItem.types';

const ProgramMemberListItem = ({
  profilePic,
  name = '',
  role = '',
  email = '',
  type = '',
  handleAddUser,
  handleRemoveUser
}: TProgramMemberListItemProps) => {
  return (
    <div className="program-member-list-item">
      <div className="program-member-list-item__user-info">
        <ProfilePicture
          url={profilePic}
          fallback={name[0]}
          className="program-member-list-item__user-info__profile-pic"
        />
        <div className="program-member-list-item__user-info__user">
          <div className="program-member-list-item__user-info__user__row">
            <Typography
              as="span"
              variant="body-2"
              fontWeight="semiBold"
              className="program-member-list-item__user-info__user__row__name"
            >
              {name}
            </Typography>
            <hr></hr>
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              className="program-member-list-item__user-info__user__row__role"
            >
              {role}
            </Typography>
          </div>
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            className="program-member-list-item__user-info__user__row__email"
          >
            {email}
          </Typography>
        </div>
      </div>
      <Button
        variant="tertiary"
        size="extraSmall"
        className="program-member-list-item__action"
        onClick={() => type === 'suggestion' ? handleAddUser() : handleRemoveUser()}
      >
        {type === 'suggestion' ? 'Add' : 'Remove'}
      </Button>
    </div>
  );
};

export default ProgramMemberListItem;
