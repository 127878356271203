import React, { useState, useRef, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import cx from './hoverTab.module.scss';
import { TTabMenuItem } from '../tabs/Tabs';

export default function HoverTabItem({ text, index, item }: { text: any, index: number, item: TTabMenuItem[] }) {
    const [show, setShow] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef<HTMLDivElement | null>(null);

    const handleMouseEnter = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setDropdownPosition({ top: rect.bottom, left: rect.left });
        }
        setShow(true);
    };

    const handleMouseLeave = () => setShow(false);

    return (
        <li style={{ position: 'relative' }}>
            <div ref={buttonRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Dropdown.Toggle id="dropdown-basic">
                    {text}
                </Dropdown.Toggle>
            </div>

            {show && ReactDOM.createPortal(
                <div
                    className={cx.dropdownMenu}
                    style={{ position: 'absolute', top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px` }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Dropdown.Menu show>
                        {item.map((menuItem: TTabMenuItem, i: number) => {
                            return (
                                <Dropdown.Item href="#/action-1" key={i}>{menuItem.text}</Dropdown.Item>

                            )
                        })}
                    </Dropdown.Menu>
                </div>,
                document.body // Renders the dropdown at the body level
            )}
        </li>
    );
}
