import React, { useState } from 'react';
import cx from './serviceSection.module.scss';
import { Col, Row } from 'react-bootstrap';
import { test } from 'src/assets/images';

export default function ServiceSection() {
    const [active, setActive] = useState<number>(0);
    const serviceArr = [
        { id: 0, text: 'What is Lorem Ipsum?' },
        { id: 1, text: 'Lorem Ipsum is simply dummy text of the printing and typesetting.' },
        { id: 2, text: 'Lorem Ipsum is simply dummy text of the printing and typesetting.' },
        { id: 3, text: 'Lorem Ipsum is simply dummy text of the printing and typesetting.' },
        { id: 4, text: 'Lorem Ipsum is simply dummy text of the printing and typesetting.' },
        { id: 5, text: 'Lorem Ipsum is simply dummy text of the printing and typesetting.' }
    ]
    return (
        <section className={`${cx.serviceSection}`}>
            <div className="insideCard">
                <Row>
                    <Col md={8}>
                        <div className={`${cx.listBox}`}>
                            <ol className={`${cx.serviceList}`}>
                                {serviceArr.map((service: { id: number, text: string }) => {
                                    return (
                                        <li className={`${active === service.id ? cx.active : ''}`} key={service.id} onClick={() => setActive(service.id)}>{service.text}</li>
                                    )
                                })}
                            </ol>
                        </div>
                    </Col>
                    <Col md={4}>
                        <Row>
                            <Col md={6}>
                                <div className={`${cx.image}`}>
                                    <img src={test} alt="test" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${cx.image}`}>
                                    <img src={test} alt="test" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${cx.image}`}>
                                    <img src={test} alt="test" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${cx.image}`}>
                                    <img src={test} alt="test" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </section>
    )
}
