import { Accordion, Col, Dropdown } from 'react-bootstrap';
import { dotMenu, fileIcon } from 'src/assets/images';
import cx from '../resources.module.scss'
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Loader } from 'src/components/common';

export default function UploadedResourceBox({ resourceData }: any) {
  const handleDownload = (url: string) => {
    const link: HTMLAnchorElement | any = document.createElement('a');
    if (link && url) {
      link.href = url;
      link.download = url.split('/').pop(); // Use the file name from the URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const title = resourceData?.url?.split('resources/')[1];
  const extension = title?.split('.')?.[1]?.toLocaleLowerCase()
  const isImage = extension === 'img' || extension === 'png' || extension === 'jpg' || extension === 'svg' || extension === 'webp'
  const url = isImage ? resourceData?.url : fileIcon
  
  return (
    <Col md={3}>
      <div className={`${cx.uploadedBox} ${!isImage ? cx.document : ''}`}>
          <img src={url} className={cx.icon} alt="upload-resource" />
        {!isImage && <span title={resourceData?.url?.split('resourses/')?.[1]}>{title}</span>}
        <div className={cx.dotDropdown}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic-6">
              <img src={dotMenu} alt="dot" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={() => handleDownload(resourceData.url)}>
                <p className={cx.dotDropInside}>
                  <span>Download</span>
                </p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Col >
  )
}
