import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from "react-owl-carousel";
import cx from './topCompanies.module.scss'
import { optionsSlider1 } from "src/constants/OwlCarouselConstant";
import { test } from 'src/assets/images';
import CompanyCard from 'src/components/MarketPlace/CompanyCard/CompanyCard';

function TopCompanies() {
    return (
        <>
            <div className={`${cx.topCompanies}`}>
                <OwlCarousel className="owl-theme sliderRow" {...optionsSlider1}>
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                    <CompanyCard />
                </OwlCarousel>
            </div>
        </>
    )
}

export default TopCompanies
